var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        staticStyle: { "padding-left": "10px" },
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm.env != "production"
        ? _c(
            "div",
            {
              staticStyle: {
                padding: "12px 0px 0px 30px",
                float: "left",
                "font-size": "20px",
                color: "red"
              }
            },
            [
              _c("span", {}, [
                _vm._v(
                  " [ " + _vm._s(_vm._f("uppercaseFirst")(_vm.env)) + " Mode ] "
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item",
                    staticStyle: {
                      "font-size": "14px",
                      "padding-right": "30px"
                    }
                  },
                  [
                    _vm.build_timestamp < _vm.last_version_timestamp
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "chocolate",
                              padding: "0 5px",
                              "font-size": "16px"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning",
                              staticStyle: { "padding-right": "5px" }
                            }),
                            _vm._v("New version found, press Ctrl+R to refresh")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        attrs: {
                          "data-current_build_tsp": _vm.$buildTimeStamp,
                          "data-latest_build_tsp": _vm.last_version_timestamp
                        }
                      },
                      [
                        _vm._v(
                          " Last Build Time: " + _vm._s(_vm.$buildTime) + " "
                        )
                      ]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c("el-dropdown-item", [
                    _vm._v(" Welcome, " + _vm._s(_vm.nickname) + " ")
                  ]),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("Log Out")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }