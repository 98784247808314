import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var adminRoutes = {
  path: '/administrator',
  component: Layout,
  redirect: '/administrator/users',
  name: 'Administrator',
  alwaysShow: true,
  meta: {
    title: 'Administrator',
    icon: 'el-icon-setting',
    roles: ['Admin'] // permissions: ['view menu administrator'],

  },
  children: [
  /** User managements */
  {
    path: 'users/edit/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/UserProfile'));
      });
    },
    name: 'UserProfile',
    meta: {
      title: 'userProfile',
      noCache: true,
      permissions: ['manage user']
    },
    hidden: true
  }, {
    path: 'users',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/List'));
      });
    },
    name: 'UserList',
    meta: {
      title: 'users',
      noCache: true,
      icon: 'user',
      permissions: ['manage user']
    }
  },
  /** Role and permission */
  {
    path: 'roles',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/role-permission/List'));
      });
    },
    name: 'RoleList',
    meta: {
      title: 'rolePermission',
      icon: 'lock'
    }
  } // {
  //   path: 'articles/create',
  //   component: () => import('@/views/articles/Create'),
  //   name: 'CreateArticle',
  //   meta: { title: 'createArticle', icon: 'edit', permissions: ['manage article'] },
  //   hidden: true,
  // },
  // {
  //   path: 'articles/edit/:id(\\d+)',
  //   component: () => import('@/views/articles/Edit'),
  //   name: 'EditArticle',
  //   meta: { title: 'editArticle', noCache: true, permissions: ['manage article'] },
  //   hidden: true,
  // },
  // {
  //   path: 'articles',
  //   component: () => import('@/views/articles/List'),
  //   name: 'ArticleList',
  //   meta: { title: 'articleList', icon: 'list', permissions: ['manage article'] },
  // },
  ]
};
export default adminRoutes;