var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canDelete &&
    (_vm.roles.includes("General Manager") || _vm.roles.includes("Admin"))
    ? _c(
        "el-button",
        {
          staticStyle: { "margin-right": "35px" },
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            size: "medium",
            plain: ""
          },
          on: {
            click: function($event) {
              return _vm.deleteLot()
            }
          }
        },
        [_vm._v("Delete")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }