var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.name
    ? _c("el-card", [
        _c("div", { staticClass: "user-profile" }, [
          _c(
            "div",
            { staticClass: "user-avatar box-center" },
            [
              _c("pan-thumb", {
                attrs: {
                  image: _vm.user.avatar,
                  height: "100px",
                  width: "100px",
                  hoverable: false
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "box-center" }, [
            _c("div", { staticClass: "user-name text-center" }, [
              _vm._v(" " + _vm._s(_vm.user.name) + " ")
            ]),
            _c("div", { staticClass: "user-role text-center text-muted" }, [
              _vm._v(" " + _vm._s(_vm.getRole()) + " ")
            ])
          ]),
          _c(
            "div",
            { staticClass: "box-social" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.social,
                    "show-header": false,
                    "empty-text": "Empty..."
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Name" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Count", align: "left", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toThousandFilter")(scope.row.count)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3668017922
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "user-follow" },
            [
              _c(
                "el-button",
                { staticStyle: { width: "100%" }, attrs: { type: "primary" } },
                [_vm._v(" Follow ")]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }