var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.responseMsg.title
                ? _c("el-alert", {
                    attrs: {
                      title: _vm.responseMsg.title,
                      type: _vm.responseMsg.type,
                      description: "fasd",
                      "show-icon": ""
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pricingFormLoading,
              expression: "pricingFormLoading"
            }
          ],
          ref: "form",
          staticStyle: { margin: "20px", width: "90%", "min-width": "600px" },
          attrs: {
            rules: _vm.rules,
            "show-message": false,
            model: _vm.pricingInfo,
            "label-width": "150px",
            "element-loading-text": "Loading data..."
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "IW Title:", prop: "title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.pricingInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "title", $$v)
                          },
                          expression: "pricingInfo.title"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "IW #:", prop: "id" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.pricingInfo.id,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "id", $$v)
                          },
                          expression: "pricingInfo.id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Customer:", prop: "customer_id" } },
                    [
                      _c("yip-contact-remote", {
                        attrs: {
                          placeholder: "Customer",
                          contact_type: "customer",
                          input_width: "180",
                          show_detail_icon: "true"
                        },
                        model: {
                          value: _vm.pricingInfo.customer_id,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "customer_id", $$v)
                          },
                          expression: "pricingInfo.customer_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Shipper:", prop: "shipper_id" } },
                    [
                      _c("yip-contact-remote", {
                        attrs: {
                          placeholder: "Shipper",
                          contact_type: "shipper",
                          input_width: "180",
                          show_detail_icon: "true"
                        },
                        model: {
                          value: _vm.pricingInfo.shipper_id,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "shipper_id", $$v)
                          },
                          expression: "pricingInfo.shipper_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "CNEE:", prop: "cnee_id" } },
                    [
                      _c("yip-contact-remote", {
                        attrs: {
                          placeholder: "CNEE",
                          contact_type: "cnee",
                          input_width: "180",
                          show_detail_icon: "true"
                        },
                        model: {
                          value: _vm.pricingInfo.cnee_id,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "cnee_id", $$v)
                          },
                          expression: "pricingInfo.cnee_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Payer:", prop: "payer_id" } },
                    [
                      _c("yip-contact-remote", {
                        attrs: {
                          placeholder: "Payer",
                          contact_type: "customer",
                          input_width: "180",
                          show_detail_icon: "true"
                        },
                        model: {
                          value: _vm.pricingInfo.payer_id,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "payer_id", $$v)
                          },
                          expression: "pricingInfo.payer_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Remarks:", prop: "remarks" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 5 }
                        },
                        model: {
                          value: _vm.pricingInfo.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.pricingInfo, "remarks", $$v)
                          },
                          expression: "pricingInfo.remarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h3", [_vm._v("Charge list")])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "250px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Charge Unit:", prop: "title" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.charge_details.charge_unit,
                            callback: function($$v) {
                              _vm.$set(_vm.charge_details, "charge_unit", $$v)
                            },
                            expression: "charge_details.charge_unit"
                          }
                        },
                        _vm._l(_vm.charge_unit_options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.charge_details.charge_unit != "none"
                ? [
                    _vm.charge_details.charge_unit == "pallet"
                      ? _c(
                          "el-col",
                          { staticStyle: { width: "270px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Pallet Rate:",
                                  prop: "pallet_rate"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: _vm.charge_details.pallet_rate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.charge_details,
                                          "pallet_rate",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "charge_details.pallet_rate"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("$")
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { staticStyle: { width: "260px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Plus FSC:", prop: "plus_fsc" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { max: "50", min: "0" },
                                model: {
                                  value: _vm.charge_details.plus_fsc,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.charge_details,
                                      "plus_fsc",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "charge_details.plus_fsc"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("%")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-left": "10px", width: "280px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Minimum Charge:",
                              prop: "minimum_charge"
                            }
                          },
                          [
                            _c(
                              "el-input",
                              {
                                model: {
                                  value: _vm.charge_details.minimum_charge,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.charge_details,
                                      "minimum_charge",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "charge_details.minimum_charge"
                                }
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("$")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm.charge_details.charge_unit == "lbs"
            ? _c(
                "div",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#e6a23c", "padding-left": "60px" }
                    },
                    [
                      _c("i", { staticClass: "el-icon-info" }),
                      _vm._v(
                        " Charge list must start with 0 LBS and end up with 20000 LBS, numbers must be in sequence with no gap."
                      )
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-left": "60px",
                        width: "660px"
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "serviceTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                "highlight-current-row": "",
                                data: _vm.chargeStageData,
                                "empty-text": "No Items",
                                "row-key": _vm.getRowKeys,
                                stripe: "",
                                fit: ""
                              },
                              on: {
                                "cell-click": _vm.handleChargeCellClick,
                                "current-change": _vm.handleCurrentChange
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "unit_price",
                                  label: "Unit Price",
                                  width: "150"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: scope.row.unit_price,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unit_price",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.unit_price"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "prepend" },
                                                [_vm._v("$")]
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2199190254
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "unit_start_at",
                                  label: "Start At",
                                  width: "180"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: scope.row.unit_start_at,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unit_start_at",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.unit_start_at"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.charge_details
                                                        .charge_unit
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1702538300
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "unit_end_at",
                                  label: "End At",
                                  width: "180"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: scope.row.unit_end_at,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unit_end_at",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.unit_end_at"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.charge_details
                                                        .charge_unit
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  312310387
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "OP" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("el-button", {
                                            attrs: {
                                              size: "mini",
                                              icon: "el-icon-delete",
                                              type: "danger",
                                              circle: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteServiceRow(
                                                  scope.$index
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2245969467
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-left": "60px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "left" },
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.charge_list_new_line($event)
                            }
                          }
                        },
                        [_vm._v("Add more")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h3", [_vm._v("Cost List")])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "10px", "padding-left": "60px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "serviceTable",
                      staticStyle: { "max-width": "930px" },
                      attrs: {
                        border: "",
                        "highlight-current-row": "",
                        data: _vm.expenseListData,
                        "empty-text": "No Cost List",
                        "row-key": _vm.getRowKeys,
                        stripe: "",
                        fit: ""
                      },
                      on: { "cell-click": _vm.handleExpenseCellClick }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "account_id",
                          label: "Expense Account",
                          width: "180"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "", filterable: "" },
                                    model: {
                                      value: scope.row.account_id,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "account_id", $$v)
                                      },
                                      expression: "scope.row.account_id"
                                    }
                                  },
                                  _vm._l(_vm.costServices.tlm, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "vendor_id",
                          label: "Vendor",
                          width: "180"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "keep-alive",
                                  [
                                    _c("yip-contact-remote", {
                                      attrs: {
                                        placeholder: "Vendor",
                                        contact_type: "vendor",
                                        input_width: "155"
                                      },
                                      model: {
                                        value: scope.row.vendor_id,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "vendor_id", $$v)
                                        },
                                        expression: "scope.row.vendor_id"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "flat_rate",
                          label: "Flat Rate",
                          width: "160"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: scope.row.flat_rate,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "flat_rate", $$v)
                                      },
                                      expression: "scope.row.flat_rate"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("$")
                                    ])
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "lbs_rate",
                          label: "LBS Rate",
                          width: "160"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: scope.row.lbs_rate,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "lbs_rate", $$v)
                                      },
                                      expression: "scope.row.lbs_rate"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("$")
                                    ])
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minimum_charge",
                          label: "Minimum Charge",
                          width: "160"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: scope.row.minimum_charge,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "minimum_charge",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.minimum_charge"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("$")
                                    ])
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "OP" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-delete",
                                    type: "danger",
                                    circle: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteCostRow(scope.$index)
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "10px", "padding-left": "60px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "left" },
                  attrs: { type: "text", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.expense_list_new_line($event)
                    }
                  }
                },
                [_vm._v("Add more")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { staticStyle: { float: "right", "padding-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: { click: _vm.saveHandle }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.submitLoading ? "Submiting ..." : "Save")
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }