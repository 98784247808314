var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _c("div", { staticClass: " clearfix" }, [
      _c("div", { staticClass: "info-container" }, [
        _c("span", { staticClass: "display_name" }, [_vm._v(_vm._s(_vm.name))]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "20px",
              "padding-top": "20px",
              display: "inline-block"
            }
          },
          [_vm._v("Here is " + _vm._s(_vm.role) + "'s Dashboard")]
        )
      ])
    ]),
    _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }