var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card user-bio" }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About me")])]
    ),
    _c("div", { staticClass: "user-education user-bio-section" }, [
      _c(
        "div",
        { staticClass: "user-bio-section-header" },
        [
          _c("svg-icon", { attrs: { "icon-class": "education" } }),
          _c("span", [_vm._v("Education")])
        ],
        1
      ),
      _c("div", { staticClass: "user-bio-section-body" }, [
        _c("div", { staticClass: "text-muted" }, [
          _vm._v(
            " B.S. in Computer Science from the University of Technology at Ho Chi Minh city "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "user-skills user-bio-section" }, [
      _c(
        "div",
        { staticClass: "user-bio-section-header" },
        [
          _c("svg-icon", { attrs: { "icon-class": "skill" } }),
          _c("span", [_vm._v("Skills")])
        ],
        1
      ),
      _c("div", { staticClass: "user-bio-section-body" }, [
        _c(
          "div",
          { staticClass: "progress-item" },
          [
            _c("span", [_vm._v("Laravel")]),
            _c("el-progress", { attrs: { percentage: 70 } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "progress-item" },
          [
            _c("span", [_vm._v("Vue")]),
            _c("el-progress", { attrs: { percentage: 18 } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "progress-item" },
          [
            _c("span", [_vm._v("JavaScript")]),
            _c("el-progress", { attrs: { percentage: 12 } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "progress-item" },
          [
            _c("span", [_vm._v("HTML & CSS")]),
            _c("el-progress", { attrs: { percentage: 100, status: "success" } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }