//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import CostHub from "@/components/yipComponent/CostHub"; // import fileUploader from '@/api/fileupload';

export default {
  components: {
    CostHub: CostHub
  },
  props: {
    // user: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: '',
    //       email: '',
    //       avatar: '',
    //       roles: [],
    //     };
    //   },
    // },
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      show_title: true,
      forceLoadData: true,
      cost_list_params: {
        superlot_id: 0,
        superhouse_id: 0,
        department: this.$route.meta.department_config.prefix
      },
      cols_show: ["id", "lot_info", "bill_no", "for_house_no", "expense_date", "due_date", "payment_reference_no", "creator"] // forceLoadData:false,
      // cost_list_params:{}

    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {// console.log('**** this.forceLoadData in TabInvoices.vue ****')
    // console.log(this.lotInfo)
    // console.log(this.forceLoadData)
  },
  methods: {}
};