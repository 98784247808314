//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "StageStatusSelect",
  props: {
    stage_status: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "medium",
      required: false
    }
  },
  data: function data() {
    return {
      status_list: ""
    };
  },
  mounted: function mounted() {
    this.status_list = this.stage_status;
  },
  computed: {
    stage_status_list: function stage_status_list() {
      return this.$store.getters.StageStatusList;
    }
  },
  methods: {
    handleUpdateStatus: function handleUpdateStatus(val) {
      this.$emit("updateStatus", val);
    }
  }
};