var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tag",
    { attrs: { size: "mini", type: _vm._f("stageStatusFilter")(_vm.status) } },
    [_vm._v(" " + _vm._s(_vm.status) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }