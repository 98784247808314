var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mode == "view"
        ? _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { size: "medium", "label-width": "150px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-bottom": "15px"
                          },
                          attrs: { type: "success", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.editLot()
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "MAWB#: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.master_no_display
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Status: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.closed_stage) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Departure from: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.airport_departure
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Destination: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.airport_destination
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "By First Carrier: "
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.carrier_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Flight: ", prop: "flight" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.flight) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Departure Date: ",
                                    prop: "departure_date"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.departure_date) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Shipper: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.shipper_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "CNEE: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.cnee_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Agent: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.agent_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Agent IATA Code: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.agent_iata_code) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Accounting Info: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.accounting_info) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Currency: " } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.currency) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Accounting Info: " } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.ae_lot_info.accounting_info_details) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    label: "Insurance: "
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.insurance) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "180px",
                                    label: "ChgsCode-WT/VAL"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.charge_code_wt_val
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "180px",
                                    label: "ChgsCode-Other"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.charge_code_other
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "280px",
                                    label: "Declared Value for Carriage: "
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info
                                          .declared_value_for_carriage
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "220px",
                                    label: "Declared Value for Customs: "
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info
                                          .declared_value_for_customs
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Handling Information: " } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.ae_lot_info.handling_information) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { padding: "0px 10px 10px 80px" },
                      attrs: { span: 23 }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { border: "", data: [{ pcs: "" }] }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_pcs",
                              label: "PCS",
                              width: "80"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ae_lot_info.packing_pcs) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2841890024
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_weight_unit",
                              label: "Weight Unit",
                              width: "120"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ae_lot_info.packing_weight_unit
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1494993073
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_gross_weight",
                              label: "Gross Weight",
                              width: "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ae_lot_info.packing_gross_weight
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2737688813
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_chargeable_weight",
                              label: "Chargeable Weight",
                              width: "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ae_lot_info
                                              .packing_chargeable_weight
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3299817575
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_rate",
                              label: "Rate",
                              width: "90"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ae_lot_info.packing_rate) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2884797770
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_total",
                              label: "Total",
                              width: "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.packing_total) + " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4121666649
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packing_nature_of_goods",
                              label: "Nature of goods"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ae_lot_info
                                              .packing_nature_of_goods
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3128099400
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Remarks:", prop: "packing_remarks" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.ae_lot_info.packing_remarks) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Other Charges:",
                            prop: "other_charges"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.ae_lot_info.other_charges) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "llabel-width": "250px",
                                    label: "Total Other Charge Due Carrier:"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info
                                          .other_charge_total_due_carrier
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "250px",
                                    label: "Total Other Charge Due Agent:"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info
                                          .other_charge_total_due_agent
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "1120px",
            "close-on-press-escape": false,
            wrapperClosable: false,
            visible: _vm.lotNewEditdrawer,
            "with-header": false,
            "destroy-on-close": true
          }
        },
        [
          _c("lot-new-edit", {
            key: _vm.drawerRandomKey,
            attrs: { objectId: _vm.lotInfo.id },
            on: {
              closeDrawer: _vm.closeDrawer,
              updateLotInfo: _vm.fetchObjectData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }