var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0px 0px" } },
    [
      _c(
        "div",
        {
          class: _vm.showFilter ? _vm.frameHeader : _vm.frameHeaderWithoutFilter
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.showFilter
                    ? [
                        _vm._l(_vm.quickFilterConfig, function(qf) {
                          return [
                            qf.el_type == "radio"
                              ? _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "filter-bt-group",
                                    attrs: { size: "medium" },
                                    on: {
                                      change: function($event) {
                                        return _vm.fetchCostList(true)
                                      }
                                    },
                                    model: {
                                      value: qf.set_value,
                                      callback: function($$v) {
                                        _vm.$set(qf, "set_value", $$v)
                                      },
                                      expression: "qf.set_value"
                                    }
                                  },
                                  [
                                    _vm._l(qf.option_list, function(opt) {
                                      return [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: opt.value } },
                                          [_vm._v(_vm._s(opt.name))]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : qf.el_type == "input"
                              ? _c("el-input", {
                                  staticStyle: { "padding-left": "15px" },
                                  style: { width: qf.width + "px" },
                                  attrs: {
                                    size: "medium",
                                    placeholder: qf.show_name
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.fetchCostList(true)
                                    }
                                  },
                                  model: {
                                    value: qf.set_value,
                                    callback: function($$v) {
                                      _vm.$set(qf, "set_value", $$v)
                                    },
                                    expression: "qf.set_value"
                                  }
                                })
                              : _vm._e(),
                            qf.el_type == "select"
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "130px",
                                      "padding-left": "10px"
                                    },
                                    attrs: {
                                      placeholder: qf.placeholder,
                                      clearable: "",
                                      filterable: "",
                                      size: "medium"
                                    },
                                    model: {
                                      value: qf.set_value,
                                      callback: function($$v) {
                                        _vm.$set(qf, "set_value", $$v)
                                      },
                                      expression: "qf.set_value"
                                    }
                                  },
                                  _vm._l(qf.option_list, function(opt) {
                                    return _c("el-option", {
                                      key: opt.value,
                                      attrs: {
                                        label: opt.name,
                                        value: opt.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e()
                          ]
                        }),
                        _c("yip-contact-remote", {
                          attrs: {
                            placeholder: "Payee",
                            contact_type: "vendor",
                            padding_left: "10"
                          },
                          model: {
                            value: _vm.filter_payee_id,
                            callback: function($$v) {
                              _vm.filter_payee_id = $$v
                            },
                            expression: "filter_payee_id"
                          }
                        }),
                        _vm.filterVisiable
                          ? _c(
                              "el-popover",
                              {
                                staticClass: "filter-popover",
                                staticStyle: { margin: "0px 10px" },
                                attrs: {
                                  placement: "bottom-start",
                                  width: "420",
                                  trigger: "click"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "medium",
                                      type: "primary",
                                      plain: ""
                                    },
                                    slot: "reference"
                                  },
                                  [
                                    _vm._v("Filter "),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right"
                                    })
                                  ]
                                ),
                                _c(
                                  "el-form",
                                  {
                                    ref: "FilterForm",
                                    attrs: {
                                      model: _vm.form,
                                      "label-width": "105px"
                                    }
                                  },
                                  [
                                    _vm._l(_vm.filterConfig, function(ftc) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            key: ftc.option_name,
                                            attrs: {
                                              label: ftc.show_name,
                                              prop: ftc.option_name
                                            }
                                          },
                                          [
                                            ftc.el_type == "date_range"
                                              ? [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      type: "daterange",
                                                      "range-separator": "-",
                                                      "start-placeholder": "",
                                                      "end-placeholder": "",
                                                      format: "MM/dd/yyyy",
                                                      "value-format":
                                                        "yyyy-MM-dd"
                                                    },
                                                    model: {
                                                      value: ftc.set_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          ftc,
                                                          "set_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ftc.set_value"
                                                    }
                                                  })
                                                ]
                                              : ftc.el_type == "input"
                                              ? [
                                                  _c("el-input", {
                                                    model: {
                                                      value: ftc.set_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          ftc,
                                                          "set_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ftc.set_value"
                                                    }
                                                  })
                                                ]
                                              : ftc.el_type == "radio"
                                              ? [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticClass:
                                                        "filter-bt-group",
                                                      attrs: { size: "medium" },
                                                      model: {
                                                        value: ftc.set_value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            ftc,
                                                            "set_value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ftc.set_value"
                                                      }
                                                    },
                                                    [
                                                      _vm._l(
                                                        ftc.option_list,
                                                        function(opts) {
                                                          return [
                                                            _c(
                                                              "el-radio-button",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    opts.value
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    opts.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    }),
                                    _c(
                                      "el-form-item",
                                      { staticStyle: { float: "right" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "medium" },
                                            on: {
                                              click: function($event) {
                                                return _vm.resetFilterForm()
                                              }
                                            }
                                          },
                                          [_vm._v("Reset")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "medium",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchCostList(true)
                                              }
                                            }
                                          },
                                          [_vm._v("Submit")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c("el-button", {
                    class: _vm.showFilter ? "" : _vm.refreshBtnWithoutFilter,
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      size: "medium",
                      type: "primary",
                      icon: "el-icon-refresh",
                      plain: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.fetchCostList(true)
                      }
                    }
                  }),
                  _vm.showFilter
                    ? [
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "padding-left": "10px" },
                            on: {
                              change: function($event) {
                                return _vm.handleEditBillNo()
                              }
                            },
                            model: {
                              value: _vm.isEditBillNo,
                              callback: function($$v) {
                                _vm.isEditBillNo = $$v
                              },
                              expression: "isEditBillNo"
                            }
                          },
                          [_vm._v("Edit Bill#")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "padding-left": "3px" },
                            model: {
                              value: _vm.isShowService,
                              callback: function($$v) {
                                _vm.isShowService = $$v
                              },
                              expression: "isShowService"
                            }
                          },
                          [_vm._v("Show Cost Items")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "padding-left": "3px" },
                            on: {
                              change: function($event) {
                                return _vm.handleShowAll()
                              }
                            }
                          },
                          [_vm._v("Show All")]
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    { staticClass: "frame-title" },
                    [
                      _vm.superLotId > 0 && _vm.showNewButton
                        ? [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  disabled:
                                    _vm.lotInfo.closed_stage != "ACTIVE",
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  size: "medium",
                                  plain: ""
                                },
                                on: { click: _vm.handleNewCost }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.superHouseId > 0
                                      ? "New Cost"
                                      : "New Master Cost"
                                  )
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.showTitle
                        ? _c(
                            "div",
                            {
                              staticClass: "yip-header",
                              staticStyle: { "margin-right": "5px" }
                            },
                            [_vm._v(" Cost List ")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.costList,
                    border: "",
                    fit: "",
                    "empty-text": "Empty..."
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "", label: "ID", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [_vm._v(" " + _vm._s(slotProps.row.id) + " ")]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("lot_info")
                    ? _c("el-table-column", {
                        attrs: { width: "100px", align: "", label: "Lot#" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.lot_info.lot_prefix
                                      ) +
                                      "-" +
                                      _vm._s(slotProps.row.lot_info.id) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1839932648
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("lot_info")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          align: "",
                          label: _vm.department_config.inv_master_name
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.lot_info.master_no) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2048174807
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("for_house_no")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          align: "",
                          label: _vm.department_config.inv_house_name
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.house_info.house_no
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          250935900
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { width: "150px", align: "", label: "Payee" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _vm._v(
                              " " + _vm._s(slotProps.row.payee.short_name) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "88px", align: "", label: "Amount" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _vm._v(
                              " $" + _vm._s(slotProps.row.total_amount) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("bill_no")
                    ? _c("el-table-column", {
                        attrs: {
                          align: "",
                          label: "Bill #",
                          width: _vm.colBillNoWidth + "px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm.isEditBillNo
                                    ? [
                                        _c("el-input", {
                                          staticStyle: { width: "110px" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: slotProps.row.bill_no_input,
                                            callback: function($$v) {
                                              _vm.$set(
                                                slotProps.row,
                                                "bill_no_input",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "slotProps.row.bill_no_input"
                                          }
                                        }),
                                        slotProps.row.bill_no_input !=
                                        slotProps.row.bill_no
                                          ? _c("el-button", {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value:
                                                    slotProps.row
                                                      .bill_no_updating,
                                                  expression:
                                                    "slotProps.row.bill_no_updating"
                                                }
                                              ],
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-check",
                                                size: "mini",
                                                plain: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleUpdateBillNo(
                                                    slotProps.$index
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.roles.includes("Accountant")
                                          ? [
                                              (slotProps.row.bill_no_input &&
                                                !slotProps.row
                                                  .bill_no_synced_at) ||
                                              slotProps.row.bill_no_synced_at <
                                                slotProps.row.bill_no_updated_at
                                                ? _c("el-button", {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          slotProps.row
                                                            .bill_no_syncing,
                                                        expression:
                                                          "slotProps.row.bill_no_syncing"
                                                      }
                                                    ],
                                                    attrs: {
                                                      type: "warning",
                                                      icon: "el-icon-sort",
                                                      size: "mini",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleSyncBillNo(
                                                          slotProps.$index
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          : _vm._e()
                                      ]
                                    : [
                                        (slotProps.row.bill_no_input &&
                                          !slotProps.row.bill_no_synced_at) ||
                                        slotProps.row.bill_no_synced_at <
                                          slotProps.row.bill_no_updated_at
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "indianred"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      slotProps.row.bill_no
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    slotProps.row.bill_no
                                                  ) +
                                                  " "
                                              )
                                            ]
                                      ]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          516711439
                        )
                      })
                    : _vm._e(),
                  _vm.isShowService
                    ? _c("el-table-column", {
                        attrs: { align: "", label: "Cost Items" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "ul",
                                    { staticStyle: { margin: "0px" } },
                                    [
                                      _vm._l(
                                        slotProps.row.expenseitems,
                                        function(service) {
                                          return [
                                            _c("li", [
                                              _vm._v(
                                                _vm._s(service.name) +
                                                  " (" +
                                                  _vm._s(service.expense_desc) +
                                                  ")"
                                              )
                                            ])
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3752715353
                        )
                      })
                    : _vm._e(),
                  _vm.isShowAllCol
                    ? [
                        _vm.cols_show.includes("expense_date")
                          ? _c("el-table-column", {
                              attrs: {
                                width: "100px",
                                align: "",
                                label: "Cost Date"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(slotProps) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                slotProps.row.expense_date
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1554025256
                              )
                            })
                          : _vm._e(),
                        _vm.cols_show.includes("due_date")
                          ? _c("el-table-column", {
                              attrs: {
                                width: "100px",
                                align: "",
                                label: "Due Date"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(slotProps) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                slotProps.row.due_date
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3188115020
                              )
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "status-col",
                      label: "Status",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "small",
                                  type: _vm._f("statusFilter")(
                                    slotProps.row.status
                                  )
                                }
                              },
                              [_vm._v(" " + _vm._s(slotProps.row.status) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("payment_reference_no") &&
                  _vm.isShowAllCol
                    ? _c("el-table-column", {
                        attrs: {
                          width: "120px",
                          align: "",
                          label: "Payment Ref#"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.qbo_billpayment_id > 0
                                    ? _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href:
                                              "https://app.qbo.intuit.com/app/billpayment?txnId=" +
                                              slotProps.row.qbo_billpayment_id,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                slotProps.row
                                                  .payment_reference_no
                                              ) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-link"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4053750288
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("creator")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          align: "",
                          label: "Created By"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.creator.nickname) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2296376839
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { align: "", label: "Actions", width: "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  icon: "el-icon-edit",
                                  type: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEditCost(
                                      slotProps.$index,
                                      slotProps.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("Edit")]
                            ),
                            slotProps.row.qbo_id > 0
                              ? _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      href:
                                        "https://app.qbo.intuit.com/app/bill?txnId=" +
                                        slotProps.row.qbo_id,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(" QBO "),
                                    _c("i", { staticClass: "el-icon-link" })
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "frame-footer" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-pagination",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0"
                        }
                      ],
                      attrs: {
                        background: "",
                        layout: "slot,sizes, prev, pager, next",
                        total: _vm.total,
                        "page-sizes": [10, 15, 20, 30, 40, 50],
                        "current-page": _vm.listRequestParams.page,
                        "page-size": _vm.listRequestParams.limit
                      },
                      on: {
                        "size-change": function($event) {
                          return _vm.fetchCostList()
                        },
                        "update:currentPage": function($event) {
                          return _vm.$set(_vm.listRequestParams, "page", $event)
                        },
                        "update:current-page": function($event) {
                          return _vm.$set(_vm.listRequestParams, "page", $event)
                        },
                        "current-change": function($event) {
                          return _vm.fetchCostList()
                        },
                        "update:pageSize": function($event) {
                          return _vm.$set(
                            _vm.listRequestParams,
                            "limit",
                            $event
                          )
                        },
                        "update:page-size": function($event) {
                          return _vm.$set(
                            _vm.listRequestParams,
                            "limit",
                            $event
                          )
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v(" Total Records: " + _vm._s(_vm.total))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            fullscreen: true,
            title:
              _vm.dialogCostTitle +
              " - [" +
              _vm.dialogCostTitleMaster +
              _vm.dialogCostTitleHouse +
              "]",
            visible: _vm.dialogCostVisible,
            "append-to-body": "",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "before-close": _vm.handleCloseDialogForce
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCostVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  style:
                    "width:" +
                    _vm.costLeftWidth +
                    ";min-height: calc(100vh - 120px);padding-right:10px;border-right:1px solid #dfe6ec;"
                },
                [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.costFormLoading,
                              expression: "costFormLoading"
                            }
                          ],
                          ref: "house_form",
                          attrs: {
                            model: _vm.currentCostInfo,
                            "label-width": "120px",
                            "element-loading-text":
                              "Loading Cost data from cloud..."
                          }
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Payee",
                                        prop: "payee_id"
                                      }
                                    },
                                    [
                                      _c("yip-contact-remote", {
                                        attrs: {
                                          placeholder: "Payee",
                                          contact_type: "vendor",
                                          input_width: "180",
                                          show_detail_icon: "true"
                                        },
                                        on: {
                                          applyContact: _vm.handleChangePayee
                                        },
                                        model: {
                                          value: _vm.currentCostInfo.payee_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "payee_id",
                                              $$v
                                            )
                                          },
                                          expression: "currentCostInfo.payee_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Bill #" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.currentCostInfo.bill_no,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "bill_no",
                                              $$v
                                            )
                                          },
                                          expression: "currentCostInfo.bill_no"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.currentCostId > 0
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Status" } },
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: _vm._f("statusFilter")(
                                                  _vm.currentCostInfo.status
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentCostInfo.status
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.currentCostId > 0
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 21 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Billing Address" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              disabled: ""
                                            },
                                            model: {
                                              value:
                                                _vm.currentCostInfo.payee_name +
                                                "\n" +
                                                _vm.currentCostInfo
                                                  .payee_billing_address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.currentCostInfo
                                                    .payee_name +
                                                    "\n" +
                                                    _vm.currentCostInfo,
                                                  "payee_billing_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                      currentCostInfo.payee_name +\n                      '\\n' +\n                      currentCostInfo.payee_billing_address\n                    "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Terms" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "60px" },
                                        on: { change: _vm.handleChangeTermDay },
                                        model: {
                                          value:
                                            _vm.currentCostInfo
                                              .payee_terms_days,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "payee_terms_days",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentCostInfo.payee_terms_days"
                                        }
                                      }),
                                      _vm._v(" Days ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Cost Date" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "",
                                          clearable: false,
                                          format: "MM/dd/yyyy",
                                          "value-format": "yyyy-MM-dd"
                                        },
                                        on: {
                                          change: _vm.handleChangeCostDate
                                        },
                                        model: {
                                          value:
                                            _vm.currentCostInfo.expense_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "expense_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentCostInfo.expense_date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Due Date" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "",
                                          clearable: false,
                                          format: "MM/dd/yyyy",
                                          "value-format": "yyyy-MM-dd",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.currentCostInfo.due_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "due_date",
                                              $$v
                                            )
                                          },
                                          expression: "currentCostInfo.due_date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Service List" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data:
                                              _vm.currentCostInfo.expenseitems,
                                            border: "",
                                            fit: "",
                                            "show-summary": "",
                                            "sum-text": "Summary",
                                            "summary-method":
                                              _vm.getCostSummaries,
                                            "empty-text": "Empty..."
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "account_id",
                                              label: "Service",
                                              width: "350"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "320px"
                                                        },
                                                        attrs: {
                                                          placeholder: "",
                                                          filterable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            slotProps.row
                                                              .account_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              slotProps.row,
                                                              "account_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "slotProps.row.account_id"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.current_cost_services,
                                                        function(item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.id,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.id
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "amount",
                                              label: "Amount",
                                              width: "120"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          slotProps.row.amount,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            slotProps.row,
                                                            "amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "slotProps.row.amount"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "expense_desc",
                                              label: "Description"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          slotProps.row
                                                            .expense_desc,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            slotProps.row,
                                                            "expense_desc",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "slotProps.row.expense_desc"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              label: "",
                                              width: "32"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-link", {
                                                      attrs: {
                                                        icon: "el-icon-delete",
                                                        type: "danger"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleRemoveService(
                                                            slotProps.$index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: _vm.handleAddService
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-plus el-icon--right"
                                              }),
                                              _vm._v(" Add Service")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Remarks" } },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "textarea" },
                                        model: {
                                          value: _vm.currentCostInfo.memo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentCostInfo,
                                              "memo",
                                              $$v
                                            )
                                          },
                                          expression: "currentCostInfo.memo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "grid-content bg-purple" },
                                  [
                                    _vm.currentCostId > 0
                                      ? [
                                          _vm.costCanDelete
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.deleteLoading,
                                                        expression:
                                                          "deleteLoading"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      float: "left"
                                                    },
                                                    attrs: {
                                                      type: "danger",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleCostDelete
                                                    }
                                                  },
                                                  [_vm._v("Delete")]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      float: "left"
                                                    },
                                                    attrs: {
                                                      type: "danger",
                                                      plain: "",
                                                      disabled: ""
                                                    }
                                                  },
                                                  [_vm._v("Delete")]
                                                )
                                              ]
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticStyle: { float: "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      { on: { click: _vm.handleCloseDialog } },
                                      [_vm._v("Discard")]
                                    ),
                                    _vm.submitting
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                disabled: ""
                                              }
                                            },
                                            [_vm._v("Submitting...")]
                                          )
                                        ]
                                      : [
                                          _vm.costCanUpdate
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value: _vm.submitting,
                                                        expression: "submitting"
                                                      }
                                                    ],
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.handleCostSubmit
                                                    }
                                                  },
                                                  [_vm._v("Submit")]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      plain: "",
                                                      disabled: ""
                                                    }
                                                  },
                                                  [_vm._v("Submit")]
                                                )
                                              ]
                                        ]
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-col",
                {
                  style:
                    "width:calc(100% - " +
                    _vm.costLeftWidth +
                    ");padding-left:5px;"
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("h3", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v("Cost Assistant")
                        ])
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "0 10px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "el-collapse",
                            {
                              attrs: { accordion: "" },
                              model: {
                                value: _vm.collapseActiveName,
                                callback: function($$v) {
                                  _vm.collapseActiveName = $$v
                                },
                                expression: "collapseActiveName"
                              }
                            },
                            [
                              _c(
                                "el-collapse-item",
                                {
                                  attrs: { title: "Lot/House Info", name: "1" }
                                },
                                [
                                  _c("div", [
                                    _vm._v("Lot/House info show here")
                                  ])
                                ]
                              ),
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "Audit Log", name: "3" } },
                                [_c("div", [_vm._v("Audit log here")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }