import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
// import Vue from 'vue'
// import Vuex from 'vuex'
// import getters from './getters'
// import app from './modules/app'
// import permission from './modules/permission'
// import settings from './modules/settings'
// import user from './modules/user'
// Vue.use(Vuex)
// const store = new Vuex.Store({
//   modules: {
//     app,
//     permission,
//     settings,
//     user
//   },
//   getters
// })
// export default store
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
Vue.use(Vuex); // https://webpack.js.org/guides/dependency-management/#requirecontext

var modulesFiles = require.context('./modules', false, /\.js$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1').replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  }); // camelCase(modulePath.replace(/^\.\/(.*)\.\w+$/, '$1'));

  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  modules: modules,
  getters: getters
});
export default store;