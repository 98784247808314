var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm.responseMsg.title
                ? _c("el-alert", {
                    attrs: {
                      title: _vm.responseMsg.title,
                      type: _vm.responseMsg.type,
                      description: "fasd",
                      "show-icon": ""
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.contactFormLoading,
              expression: "contactFormLoading"
            }
          ],
          ref: "addressbook_new-form",
          attrs: {
            model: _vm.contactInfo,
            rules: _vm.addressbook_rules,
            size: "",
            "label-width": "150px",
            "element-loading-text": "Loading"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 25 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "Company:",
                                    prop: "company"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "100%" },
                                    attrs: {
                                      placeholder: "Company:",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.contactInfo.company,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "company",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.company"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "Address:",
                                    prop: "address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "100%" },
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Address:",
                                      autosize: { minRows: 4, maxRows: 4 }
                                    },
                                    model: {
                                      value: _vm.contactInfo.address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "City:",
                                    prop: "city"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "City:" },
                                    model: {
                                      value: _vm.contactInfo.city,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "city", $$v)
                                      },
                                      expression: "contactInfo.city"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "40px",
                                    label: "State:",
                                    prop: "state"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "State:" },
                                    model: {
                                      value: _vm.contactInfo.state,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "state", $$v)
                                      },
                                      expression: "contactInfo.state"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "Zipcode:",
                                    prop: "zipcode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Zipcode:" },
                                    model: {
                                      value: _vm.contactInfo.zipcode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "zipcode",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.zipcode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "Country:",
                                    prop: "country"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Country:" },
                                    model: {
                                      value: _vm.contactInfo.country,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "country",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.country"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "80px",
                                    label: "Role:",
                                    prop: "roles"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Customer",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_customer,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_customer",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_customer"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "CNEE",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_cnee,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_cnee",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_cnee"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Vendor",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_vendor,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_vendor",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_vendor"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Agent",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_agent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_agent",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_agent"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Carrier",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_carrier,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_carrier",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_carrier"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Shipper",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_shipper,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_shipper",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_shipper"
                                    }
                                  }),
                                  _c("el-checkbox", {
                                    attrs: {
                                      label: "Terminal",
                                      "true-label": "1",
                                      "false-label": "0"
                                    },
                                    model: {
                                      value: _vm.contactInfo.as_terminal,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "as_terminal",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.as_terminal"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "80px",
                                    label: "1099:",
                                    prop: "form_1099"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "medium" },
                                      model: {
                                        value: _vm.contactInfo.form_1099,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.contactInfo,
                                            "form_1099",
                                            $$v
                                          )
                                        },
                                        expression: "contactInfo.form_1099"
                                      }
                                    },
                                    _vm._l(_vm.form_1099Options, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: index,
                                          attrs: {
                                            label: item.value,
                                            disabled: item.disabled
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.contactInfo.as_agent == "1" ||
                          _vm.contactInfo.as_carrier == "1"
                            ? _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "100px",
                                        label: "IATA Code:",
                                        prop: "iata_code"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        style: { width: "100%" },
                                        attrs: { placeholder: "IATA Code:" },
                                        model: {
                                          value: _vm.contactInfo.iata_code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactInfo,
                                              "iata_code",
                                              $$v
                                            )
                                          },
                                          expression: "contactInfo.iata_code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.contactInfo.as_carrier == "1"
                            ? _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "100px",
                                        label: "IATA Prefix:",
                                        prop: "iata_prefix_code"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        style: { width: "100%" },
                                        attrs: { placeholder: "IATA Prefix:" },
                                        model: {
                                          value:
                                            _vm.contactInfo.iata_prefix_code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactInfo,
                                              "iata_prefix_code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contactInfo.iata_prefix_code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.contactInfo.as_terminal == "1"
                            ? _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "100px",
                                        label: "Firm Code:",
                                        prop: "firm_code"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        style: { width: "100%" },
                                        attrs: { placeholder: "Firm Code:" },
                                        model: {
                                          value: _vm.contactInfo.firm_code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contactInfo,
                                              "firm_code",
                                              $$v
                                            )
                                          },
                                          expression: "contactInfo.firm_code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Group:",
                                    "label-width": "100px"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        placeholder: "Group name",
                                        multiple: "",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.contactInfo.group_ids,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.contactInfo,
                                            "group_ids",
                                            $$v
                                          )
                                        },
                                        expression: "contactInfo.group_ids"
                                      }
                                    },
                                    _vm._l(_vm.groupList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "Short Name:",
                                    prop: "short_name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Short Name:" },
                                    model: {
                                      value: _vm.contactInfo.short_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "short_name",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.short_name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "Contact Person:",
                                    prop: "contact_person"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Contact Person:" },
                                    model: {
                                      value: _vm.contactInfo.contact_person,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "contact_person",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.contact_person"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "Phone #:",
                                    prop: "phone"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Phone #:" },
                                    model: {
                                      value: _vm.contactInfo.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "phone", $$v)
                                      },
                                      expression: "contactInfo.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "Fax #:",
                                    prop: "fax"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Fax #:" },
                                    model: {
                                      value: _vm.contactInfo.fax,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "fax", $$v)
                                      },
                                      expression: "contactInfo.fax"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "Email :",
                                    prop: "email"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "Email " },
                                    model: {
                                      value: _vm.contactInfo.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "email", $$v)
                                      },
                                      expression: "contactInfo.email"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "IRS #:",
                                    prop: "irs_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "90%" },
                                    attrs: { placeholder: "IRS #" },
                                    model: {
                                      value: _vm.contactInfo.irs_no,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "irs_no", $$v)
                                      },
                                      expression: "contactInfo.irs_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.contactInfo.id
                        ? [
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  margin: "20px 0px 0px 30px",
                                  padding: "5px"
                                }
                              },
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { padding: "10px" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "700",
                                            "font-size": "15px",
                                            color: "#606266",
                                            "line-height": "40px"
                                          }
                                        },
                                        [_vm._v("Contact List")]
                                      ),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px"
                                          },
                                          attrs: {
                                            icon: "el-icon-plus",
                                            type: "success"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handlePeopleAdd()
                                            }
                                          }
                                        },
                                        [_vm._v("Add Contact")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  margin: "0px 0px 20px 30px",
                                  padding: "5px"
                                }
                              },
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "contact-content contact-purple-light"
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "90%" },
                                          attrs: {
                                            data: _vm.peoples,
                                            border: "",
                                            "empty-text":
                                              "No contacts found here"
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Title",
                                              prop: "title",
                                              width: "120"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "First Name",
                                              prop: "first_name",
                                              width: "150"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Last Name",
                                              prop: "last_name",
                                              width: "150"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Email",
                                              prop: "email"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Phone",
                                              prop: "phone"
                                            }
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Op",
                                              prop: "last_name",
                                              width: "150"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handlePeopleEdit(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Edit")]
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handlePeopleDelete(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Delete")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2296770132
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 13 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "WebSite:",
                                    prop: "website"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "100%" },
                                    attrs: { placeholder: "WebSite" },
                                    model: {
                                      value: _vm.contactInfo.website,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactInfo,
                                          "website",
                                          $$v
                                        )
                                      },
                                      expression: "contactInfo.website"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 13 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "Notes:",
                                    prop: "notes"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    style: { width: "100%" },
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Notes",
                                      autosize: { minRows: 4, maxRows: 4 }
                                    },
                                    model: {
                                      value: _vm.contactInfo.notes,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contactInfo, "notes", $$v)
                                      },
                                      expression: "contactInfo.notes"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { float: "right", "margin-right": "40px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.saveContactHandle }
                },
                [_vm._v(_vm._s(_vm.submitLoading ? "Submiting ..." : "Save"))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.peopleDialogTitle,
            visible: _vm.contactFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.contactFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.peopleFormLoading,
                  expression: "peopleFormLoading"
                }
              ],
              attrs: { model: _vm.new_contact }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "5px" },
                  attrs: { "label-position": "top" }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { span: 9 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Title" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "title", $$v)
                                  },
                                  expression: "new_contact.title"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Salutation" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Salutation" },
                                  model: {
                                    value: _vm.new_contact.salutation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.new_contact,
                                        "salutation",
                                        $$v
                                      )
                                    },
                                    expression: "new_contact.salutation"
                                  }
                                },
                                _vm._l(_vm.salutation_options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { span: 9 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "First Name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.first_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "first_name", $$v)
                                  },
                                  expression: "new_contact.first_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Last Name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.last_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "last_name", $$v)
                                  },
                                  expression: "new_contact.last_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Phone" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "phone", $$v)
                                  },
                                  expression: "new_contact.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Fax" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.fax,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "fax", $$v)
                                  },
                                  expression: "new_contact.fax"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.new_contact.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.new_contact, "email", $$v)
                                  },
                                  expression: "new_contact.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            "margin-top": "20px"
                          },
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": "1",
                                    "false-label": "0"
                                  },
                                  model: {
                                    value: _vm.new_contact.receive_notification,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.new_contact,
                                        "receive_notification",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "new_contact.receive_notification"
                                  }
                                },
                                [_vm._v("Receive Notification")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.contactFormVisible = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.savePeopleHandle }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }