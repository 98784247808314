var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mode == "view"
        ? _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { size: "medium", "label-width": "150px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-bottom": "15px"
                          },
                          attrs: { type: "success", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.editLot()
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "OBL#: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.master_no) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Booking #: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.booking_no) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Status: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.closed_stage) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Port of Loading: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.seaport_departure
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Port of Discharge: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.seaport_destination
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Departure Date:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.departure_date) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Shipper: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.shipper_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "CNEE: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.cnee_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Forwarding Agent:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.agent_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "NOTIFY PARTY: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.notify_party_name
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "For Delivery Apply To:",
                                    "label-width": "180px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.delivery_apply_name
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "PRE-CARRIAGE BY:",
                                    prop: "pre_carriage_by"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.pre_carriage_by) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Export Carrier:",
                                    prop: "carrier_id",
                                    "label-width": "180px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.carrier_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Venssel/Voyage:",
                                    prop: "venssel_voyage"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.venssel_voyage) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Place of Receipt:",
                                    prop: "place_of_receipt"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.place_of_receipt) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Place of Delivery:",
                                    prop: "place_of_delivery"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.place_of_delivery
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Transshipment Port:",
                                    prop: "transshipment_port"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.transshipment_port
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Loading Pier/Terminal:",
                                    prop: "loading_pier_terminal",
                                    "label-width": "170px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.loading_pier_terminal
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Origin of goods:",
                                    prop: "origin_of_goods"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.origin_of_goods) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Number of Originals:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.number_of_originals
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "20px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [_vm._v(" Particulars Furnished By Shipper:")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.packing_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: "",
                                    "show-summary": "",
                                    "sum-text": "Total"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "container_no",
                                      label: "Container#",
                                      width: "165"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-more" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.container_no
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  scope.row.seal_no
                                                    ? [
                                                        _vm._v(
                                                          " Seal #: " +
                                                            _vm._s(
                                                              scope.row.seal_no
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br")
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row
                                                          .container_marks
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br")
                                                ],
                                                2
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1333401254
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packages",
                                      label: "No. of Pkgs",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.packages) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      644971284
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goods_description",
                                      label: "Description",
                                      width: "290"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-more" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .goods_description
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1875985004
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "gross_weight",
                                      label: "Gross Weight",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.gross_weight
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          scope.row.gross_weight
                                                        )
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "3px"
                                                        }
                                                      },
                                                      [_vm._v("KG")]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      790538924
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "measurement",
                                      label: "Measurement",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.measurement
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          scope.row.measurement
                                                        )
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "3px"
                                                        }
                                                      },
                                                      [_vm._v("CBM")]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2932572652
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "20px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [_vm._v(" Charges:")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.charge_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: "",
                                    "show-summary": "",
                                    "sum-text": "Total"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "charge_item",
                                      label: "FREIGHT & CHARGES ",
                                      width: "280"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.charge_item
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1611408141
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "basis",
                                      label: "Basis",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.basis) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2449727063
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "rate",
                                      label: "Rate",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.rate
                                                ? [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(scope.row.rate) +
                                                        " "
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4060250752
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prepaid",
                                      label: "Prepaid",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.prepaid
                                                ? [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          scope.row.prepaid
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4091027840
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "collect",
                                      label: "Collect",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.collect
                                                ? [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          scope.row.collect
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      437281952
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Remarks:", prop: "remarks" } },
                        [_vm._v(" " + _vm._s(_vm.ae_lot_info.remarks) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "1020px",
            "close-on-press-escape": false,
            wrapperClosable: false,
            visible: _vm.lotNewEditdrawer,
            "with-header": false,
            "destroy-on-close": true
          }
        },
        [
          _c("lot-new-edit", {
            key: _vm.drawerRandomKey,
            attrs: { objectId: _vm.lotInfo.id },
            on: {
              closeDrawer: _vm.closeDrawer,
              updateLotInfo: _vm.fetchObjectData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }