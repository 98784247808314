import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var addressBookRoutes = {
  path: '/contact',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Address Book',
  alwaysShow: true,
  meta: {
    title: 'Address Book',
    icon: 'el-icon-notebook-2' // permissions: ['view menu '], //  address_book

  },
  children: [
  /** User managements */
  {
    path: 'index',
    name: 'Contact List',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/address-book/ContactList'));
      });
    },
    meta: {
      title: 'Contact List',
      icon: ''
    }
  }, {
    path: 'contact_group',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/address-book/ContactGroupList'));
      });
    },
    name: 'Contact Group',
    meta: {
      title: 'Contact Group',
      noCache: true,
      icon: ''
    }
  } // {
  //   path: 'index2',
  //   name: 'Contact List2',
  //   component: () => import('@/views/address-book/ContactList'),
  //   meta: { title: 'Contact List2', icon: 'list' }
  // },     
  ]
};
export default addressBookRoutes;