//
//
//
//
//
//
//
//
//
//
//
import NotesHub from '@/components/yipComponent/NotesHub'; // import Pagination from '@/components/Pagination'; // Secondary package based on 
// import Resource from '@/api/resource';
// import fileUploader from '@/api/fileupload';
// const articleResource = new Resource('articles');

export default {
  components: {
    NotesHub: NotesHub
  },
  props: {
    // user: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: '',
    //       email: '',
    //       avatar: '',
    //       roles: [],
    //     };
    //   },
    // },
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    viewObjectId: {
      type: String,
      default: 0
    },
    editObjectId: {
      type: String,
      default: '0'
    },
    forceLoadData: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      houseId: 0,
      note_list_params: {
        'superlot_id': 0,
        'superhouse_id': 0,
        'department': this.$route.meta.department_config.prefix
      },
      cols_show: ['id', 'creator'] // noteshub_request_params: {'superlot_id':lotInfo.superlot_id,'superhouse_id':this.superHouseId,'invoice_id':0,'expense_id':this.currentCostId,'department':'TLM'},
      // forceLoadData:false,
      // inv_list_params:{}

    };
  },
  watch: {
    'lotInfo.superlot_id': function lotInfoSuperlot_id(val) {
      console.log('-----lotInfo.superlot_id----');
      console.log(val); // this.inv_list_params.superlot_id=val;

      this.$set(this.note_list_params, 'superlot_id', val);
    },
    forceLoadData: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(newV, oldV) {
        console.log('***---watch forceLoadData changed in TabNotes---', newV, oldV);

        if (newV == true) {
          console.log('---getting Notes list...---'); // this.fetchTabData()
        }
      }
    }
  },
  mounted: function mounted() {
    console.log('**** this.forceLoadData in TabNotes.vue ****'); // console.log(this.lotInfo)

    console.log(this.forceLoadData);
  },
  methods: {
    backToList: function backToList() {
      console.log('----this.viewObjectId---', this.viewObjectId);
      this.$emit('update:editObjectId', 0);
      this.$emit('update:viewObjectId', this.viewObjectId);
    }
  }
};