var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "30px",
            padding: "14px 15px",
            "border-bottom": "1px solid #dfe6ec"
          }
        },
        [
          _c("el-page-header", {
            attrs: {
              title: "Close",
              content: _vm.mode + " " + _vm.department_config.name + " OBL"
            },
            on: { back: _vm.handleCloseDrawer }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.lot_info_loading,
              expression: "lot_info_loading"
            }
          ],
          attrs: { "element-loading-text": "Loading data..." }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 27 } },
            [
              _c(
                "el-form",
                {
                  ref: "ae-lot-form",
                  attrs: {
                    model: _vm.ae_lot_info,
                    rules: _vm.ae_lot_rules,
                    size: "medium",
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "OBL#:", prop: "master_no" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "OBL#:",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.master_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "master_no",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.master_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Booking #:",
                                    prop: "booking_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Booking #",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.booking_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "booking_no",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.booking_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ae_lot_info.id > 0
                            ? _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Status",
                                        prop: "closed_stage"
                                      }
                                    },
                                    [
                                      _c("stage-status-select", {
                                        attrs: {
                                          stage_status:
                                            _vm.ae_lot_info.closed_stage
                                        },
                                        on: {
                                          updateStatus: _vm.updateStageStatus
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Shipper",
                                    prop: "shipper_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "Shipper",
                                      contact_type: "shipper",
                                      input_width: "160",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.shipper_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "shipper_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.shipper_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "CNEE", prop: "cnee_id" } },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "CNEE",
                                      contact_type: "cnee",
                                      input_width: "160",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.cnee_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "cnee_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.cnee_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Departure Date:",
                                    prop: "departure_date"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      format: "MM/dd/yyyy",
                                      "value-format": "MM/dd/yyyy",
                                      placeholder: "Departure Date:",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.departure_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "departure_date",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.departure_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "NOTIFY PARTY",
                                    prop: "notify_party_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "NOTIFY PARTY",
                                      contact_type: "cnee",
                                      input_width: "160",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.notify_party_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "notify_party_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.notify_party_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "For Delivery Apply To",
                                    prop: "delivery_apply_id",
                                    "label-width": "180px"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "",
                                      contact_type: "agent",
                                      input_width: "135",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.delivery_apply_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "delivery_apply_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.delivery_apply_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Forwarding Agent",
                                    prop: "agent_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "Agent",
                                      contact_type: "agent",
                                      input_width: "135",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.agent_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "agent_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.agent_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "PRE-CARRIAGE BY:",
                                    prop: "pre_carriage_by"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.pre_carriage_by,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "pre_carriage_by",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.pre_carriage_by"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Place of Receipt:",
                                    prop: "place_of_receipt"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.place_of_receipt,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "place_of_receipt",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.place_of_receipt"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Export Carrier",
                                    prop: "carrier_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "Carrier",
                                      contact_type: "carrier",
                                      input_width: "135",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.carrier_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "carrier_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.carrier_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Venssel/Voyage:",
                                    prop: "venssel_voyage"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.venssel_voyage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "venssel_voyage",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.venssel_voyage"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Port of Loading:",
                                    prop: "seaport_departure"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select seaport",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      on: { change: _vm.changePortLoading },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.seaport_departure,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "seaport_departure",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.seaport_departure"
                                      }
                                    },
                                    _vm._l(_vm.seaport_code, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.name
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "ETA Date:",
                                    prop: "eta_date"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      format: "MM/dd/yyyy",
                                      "value-format": "MM/dd/yyyy",
                                      placeholder: "ETA Date:",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.eta_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "eta_date",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.eta_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Port of Discharge:",
                                    prop: "seaport_destination"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Select seaport",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      on: { change: _vm.changePortDischarge },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.seaport_destination,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "seaport_destination",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.seaport_destination"
                                      }
                                    },
                                    _vm._l(_vm.seaport_code, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.name
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Place of Delivery:",
                                    prop: "place_of_delivery"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.place_of_delivery,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "place_of_delivery",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.place_of_delivery"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Freight Charges:",
                                    prop: "freight_charges",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.ae_lot_info.freight_charges,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "freight_charges",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.freight_charges"
                                      }
                                    },
                                    _vm._l(_vm.freight_chargesOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Origin of goods:",
                                    prop: "origin_of_goods"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.origin_of_goods,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "origin_of_goods",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.origin_of_goods"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Loading Pier/Terminal:",
                                    prop: "loading_pier_terminal",
                                    "label-width": "170px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.loading_pier_terminal,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "loading_pier_terminal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.loading_pier_terminal"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Transshipment Port:",
                                    prop: "transshipment_port"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.transshipment_port,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "transshipment_port",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.transshipment_port"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Number of Originals:",
                                    prop: "number_of_originals",
                                    "label-width": "180px"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.number_of_originals,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "number_of_originals",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.number_of_originals"
                                      }
                                    },
                                    _vm._l(
                                      _vm.number_of_originalsOptions,
                                      function(item, index) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [
                              _vm._v(" Particulars Furnished By Shipper "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        icon: "el-icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.packing_list_new_line(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Add more container")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "0px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.packing_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: "",
                                    "show-summary": "",
                                    "sum-text": "Total"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "container_no",
                                      label: "Container#",
                                      width: "165"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text-more" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.container_no
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                scope.row.seal_no
                                                  ? [
                                                      _vm._v(
                                                        " Seal #: " +
                                                          _vm._s(
                                                            scope.row.seal_no
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("br")
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.container_marks
                                                    ) +
                                                    " "
                                                ),
                                                _c("br")
                                              ],
                                              2
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packages",
                                      label: "No. of Pkgs",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.packages) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goods_description",
                                      label: "Description",
                                      width: "290"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text-more" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.goods_description
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "gross_weight",
                                      label: "Gross Weight",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.gross_weight
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.gross_weight
                                                      )
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "3px"
                                                      }
                                                    },
                                                    [_vm._v("KG")]
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "measurement",
                                      label: "Measurement",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            scope.row.measurement
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.measurement
                                                      )
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "3px"
                                                      }
                                                    },
                                                    [_vm._v("CBM")]
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "OP" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-edit",
                                                type: "success",
                                                circle: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.editPackingListRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            }),
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-delete",
                                                type: "danger",
                                                circle: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deletePackingListRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [
                              _vm._v(" Charges "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        icon: "el-icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.charge_list_new_line(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Add more charge to be shown on HBL"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.charge_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: "",
                                    "show-summary": "",
                                    "sum-text": "Total"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "charge_item",
                                      label: "FREIGHT & CHARGES ",
                                      width: "280"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.charge_item,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "charge_item",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.charge_item"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "basis",
                                      label: "Basis",
                                      width: "140"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.basis,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "basis",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.basis"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "rate",
                                      label: "Rate",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.rate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "rate",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.rate"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prepaid",
                                      label: "Prepaid",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.prepaid,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "prepaid",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.prepaid"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "collect",
                                      label: "Collect",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.collect,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "collect",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.collect"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "OP" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-delete",
                                                type: "danger",
                                                circle: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteChargeListRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "padding-top": "20px" },
                          attrs: { label: "Remarks:", prop: "remarks" }
                        },
                        [
                          _c("el-input", {
                            style: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 3 }
                            },
                            model: {
                              value: _vm.ae_lot_info.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.ae_lot_info, "remarks", $$v)
                              },
                              expression: "ae_lot_info.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "large" }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !_vm.lotCanEdit,
                                type: "primary"
                              },
                              on: { click: _vm.submitForm }
                            },
                            [_vm._v("Submit")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "packingForm",
          attrs: {
            title: _vm.packingDialogStatus + " Container Detail",
            visible: _vm.dialogPackingFormVisible,
            "append-to-body": "",
            "custom-class": "dialogStyle"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPackingFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.packingRowForm, "label-width": "150px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Container #:", prop: "container_no" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.container_no,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_no",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Seal #:", prop: "seal_no" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.seal_no,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "seal_no", $$v)
                              },
                              expression: "packingRowForm.seal_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Size/Type:",
                            prop: "container_size_type"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.packingRowForm.container_size_type,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "container_size_type",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.container_size_type"
                              }
                            },
                            _vm._l(_vm.container_size_typeOptions, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Marks & Nos. :",
                            prop: "container_marks"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.packingRowForm.container_marks,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_marks",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_marks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Packages:", prop: "packages" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            model: {
                              value: _vm.packingRowForm.packages,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "packages", $$v)
                              },
                              expression: "packingRowForm.packages"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Gross Weight:",
                            prop: "gross_weight"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "160px" },
                              model: {
                                value: _vm.packingRowForm.gross_weight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "gross_weight",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.gross_weight"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("KG")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Measurement:", prop: "measurement" }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "160px" },
                              model: {
                                value: _vm.packingRowForm.measurement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "measurement",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.measurement"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("CBM")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-row"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Description of Packages Goods:",
                    prop: "goods_description"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "430px" },
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.packingRowForm.goods_description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "goods_description",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.goods_description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogPackingFormVisible = false
                    }
                  }
                },
                [_vm._v("discard")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handlePackingRowConfirm()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }