var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frame-page" }, [
    _c(
      "div",
      { staticClass: "frame-header" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("yip-table-filter", {
                  attrs: { filterConfig: _vm.tableFilterConfig },
                  on: {
                    "update:filterConfig": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    "update:filter-config": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    updateTableFilterParams: _vm.getTableFilterParams,
                    submitForm: _vm.getHouseList
                  }
                }),
                _c(
                  "span",
                  { staticClass: "frame-title" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          disabled: _vm.lotInfo.closed_stage != "ACTIVE",
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "medium",
                          plain: ""
                        },
                        on: { click: _vm.handleNewHouse }
                      },
                      [
                        _vm._v(
                          "New " + _vm._s(_vm.department_config.inv_master_name)
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list_body" },
      [
        _c(
          "el-scrollbar",
          { staticClass: "frame-body-scrollbar" },
          [
            _c(
              "el-card",
              { staticClass: "box-card user-bio" },
              [
                _c(
                  "div",
                  { staticClass: "user-activity" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.houseList,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                          "empty-text": "Empty..."
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { align: "", label: "ID", width: "80" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "HAWB/HBL"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.house_no) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "100px", align: "", label: "IW#" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.pricing_id > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(slotProps.row.pricing_id) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "150px",
                            align: "",
                            label: "Customer"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.customer_name) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "130px",
                            align: "",
                            label: "Invoice Amount"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.invoice_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "110px",
                            align: "",
                            label: "Cost Amount"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.cost_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100px",
                            align: "",
                            label: "Created By"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.creator) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "Created At"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          slotProps.row.created_at
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { align: "", label: "Actions" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "16px" },
                                      attrs: {
                                        plain: "",
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditHouse(
                                            slotProps.$index,
                                            slotProps.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Edit ")]
                                  ),
                                  slotProps.row.cost_amount == 0 &&
                                  slotProps.row.invoice_amount == 0
                                    ? _c("el-button", {
                                        staticStyle: { "margin-left": "16px" },
                                        attrs: {
                                          plain: "",
                                          type: "danger",
                                          icon: "el-icon-delete",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleDeleteHouse(
                                              slotProps.$index,
                                              slotProps.row
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "frame-footer" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-pagination",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.total > 0,
                                        expression: "total > 0"
                                      }
                                    ],
                                    attrs: {
                                      background: "",
                                      layout: "slot,sizes, prev, pager, next",
                                      total: _vm.total,
                                      "page-sizes": [10, 15, 20, 30, 40, 50],
                                      "current-page":
                                        _vm.listRequestParams.page,
                                      "page-size": _vm.listRequestParams.limit
                                    },
                                    on: {
                                      "size-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:currentPage": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "update:current-page": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "current-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:pageSize": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      },
                                      "update:page-size": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          " Total Records: " + _vm._s(_vm.total)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      size: "960px",
                      "close-on-press-escape": false,
                      wrapperClosable: false,
                      visible: _vm.drawer,
                      "with-header": false,
                      "destroy-on-close": true
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.drawer = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "14px 15px",
                          "border-bottom": "1px solid #dfe6ec"
                        }
                      },
                      [
                        _c("el-page-header", {
                          attrs: {
                            title: "Back",
                            content: _vm.houseDrawerTitle
                          },
                          on: { back: _vm.handleCloseDrawer }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tabs",
                          {
                            staticClass: "yip-tabs",
                            attrs: { type: "card" },
                            model: {
                              value: _vm.HouseEditActiveTab,
                              callback: function($$v) {
                                _vm.HouseEditActiveTab = $$v
                              },
                              expression: "HouseEditActiveTab"
                            }
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "House Info",
                                  name: "house_info"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.current_house_loading,
                                        expression: "current_house_loading"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.submitting,
                                            expression: "submitting"
                                          }
                                        ],
                                        ref: "house_form",
                                        attrs: {
                                          model: _vm.currentHouseInfo,
                                          rules: _vm.house_form_rules,
                                          "label-width": "120px"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 9 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "HAWB/HBL: ",
                                                      prop: "house_no"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .house_no,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "house_no",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.house_no"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 7 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "IW#: ",
                                                      prop: "pricing_id"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .pricing_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "pricing_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.pricing_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 9 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Customer",
                                                      prop: "customer_id"
                                                    }
                                                  },
                                                  [
                                                    _c("yip-contact-remote", {
                                                      attrs: {
                                                        placeholder: "Customer",
                                                        contact_type:
                                                          "customer",
                                                        input_width: "170",
                                                        show_detail_icon:
                                                          "true",
                                                        disabled: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .customer_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "customer_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.customer_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 7 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Customer Ref#: ",
                                                      prop:
                                                        "customer_reference_no"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .customer_reference_no,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "customer_reference_no",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.customer_reference_no"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Entry #: ",
                                                      prop: "entry_no"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .entry_no,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "entry_no",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.entry_no"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "padding-top": "20px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "margin-left": "30px",
                                                  "font-weight": "bold",
                                                  "font-size": "14px"
                                                },
                                                attrs: { span: 12 }
                                              },
                                              [_vm._v("Shipment Detail:")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "padding-top": "10px",
                                              "padding-left": "30px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    staticStyle: {
                                                      "max-width": "930px"
                                                    },
                                                    attrs: {
                                                      border: "",
                                                      "highlight-current-row":
                                                        "",
                                                      data: _vm.packingListData,
                                                      "empty-text":
                                                        "No Cost List",
                                                      "row-key": _vm.getRowKeys,
                                                      stripe: "",
                                                      fit: ""
                                                    },
                                                    on: {
                                                      "cell-click":
                                                        _vm.handlePackingListCellClick
                                                    }
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "container_no",
                                                        label: "Container#",
                                                        width: "145"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _vm.lotInfo
                                                                .master_type ==
                                                              "Ocean"
                                                                ? _c(
                                                                    "el-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .container_no,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "container_no",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "scope.row.container_no"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "seal_no",
                                                        label: "Seal#",
                                                        width: "145"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _vm.lotInfo
                                                                .master_type ==
                                                              "Ocean"
                                                                ? _c(
                                                                    "el-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .seal_no,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "seal_no",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "scope.row.seal_no"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "packages",
                                                        label: "Packages",
                                                        width: "90"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .packages,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "packages",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "scope.row.packages"
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "gross_weight",
                                                        label: "Gross Weight",
                                                        width: "140"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width: "90px"
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .gross_weight,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "gross_weight",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "scope.row.gross_weight"
                                                                }
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "3px"
                                                                  }
                                                                },
                                                                [_vm._v("KG")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _vm.lotInfo.master_type ==
                                                    "Ocean"
                                                      ? [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "measurement",
                                                                label:
                                                                  "Measurement",
                                                                width: "140"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      scope
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticStyle: {
                                                                              width:
                                                                                "83px"
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.updateChargeableWeight(
                                                                                  scope.$index
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                scope
                                                                                  .row
                                                                                  .measurement,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  scope.row,
                                                                                  "measurement",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "scope.row.measurement"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle: {
                                                                              "margin-left":
                                                                                "3px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "CBM"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                3002671508
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "chargeable_weight",
                                                                label:
                                                                  "Chargeable Weight",
                                                                width: "150"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      scope
                                                                    ) {
                                                                      return scope
                                                                        .row
                                                                        .chargeable_weight
                                                                        ? [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .chargeable_weight
                                                                                ) +
                                                                                " KG "
                                                                            )
                                                                          ]
                                                                        : undefined
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "chargeable_weight",
                                                                label:
                                                                  "Chargeable Weight",
                                                                width: "150"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      scope
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticStyle: {
                                                                              width:
                                                                                "90px"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                scope
                                                                                  .row
                                                                                  .chargeable_weight,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  scope.row,
                                                                                  "chargeable_weight",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "scope.row.chargeable_weight"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle: {
                                                                              "margin-left":
                                                                                "3px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "KG"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                    _c("el-table-column", {
                                                      attrs: { label: "OP" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-button", {
                                                                attrs: {
                                                                  size: "mini",
                                                                  icon:
                                                                    "el-icon-delete",
                                                                  type:
                                                                    "danger",
                                                                  circle: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deletePackingListRow(
                                                                      scope.$index
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    })
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          {},
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  padding: "10px 0px 10px 30px",
                                                  float: "right"
                                                },
                                                attrs: {
                                                  type: "text",
                                                  icon: "el-icon-plus"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.packing_list_new_line(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Add more")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 20 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Remarks: "
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "textarea"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .remarks,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "remarks",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.remarks"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      float: "right"
                                                    }
                                                  },
                                                  [
                                                    _vm.submitting
                                                      ? [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                disabled: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Submitting..."
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "loading",
                                                                  rawName:
                                                                    "v-loading",
                                                                  value:
                                                                    _vm.submitting,
                                                                  expression:
                                                                    "submitting"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "primary",
                                                                disabled:
                                                                  _vm.lotInfo
                                                                    .closed_stage !=
                                                                  "ACTIVE"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.onSubmit
                                                              }
                                                            },
                                                            [_vm._v("Submit")]
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm.currentHouseId > 0
                              ? [
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Invoices",
                                            name: "house_invoice"
                                          }
                                        },
                                        [
                                          _c("invoice-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.invoicehub_request_params,
                                              cols_show:
                                                _vm.invoicehub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.invoiceShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Costs",
                                            name: "house_cost"
                                          }
                                        },
                                        [
                                          _c("cost-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.costhub_request_params,
                                              cols_show: _vm.costhub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.costShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }