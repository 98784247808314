var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.layoutMode == "drawer"
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "30px",
              padding: "14px 15px",
              "border-bottom": "1px solid #dfe6ec"
            }
          },
          [
            _c("el-page-header", {
              attrs: { title: "Cancel", content: "New TLM Lot" },
              on: { back: _vm.handleCloseDrawer }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "view"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { float: "right" },
                            attrs: { type: "success", plain: "" },
                            on: {
                              click: function($event) {
                                _vm.mode = "edit"
                              }
                            }
                          },
                          [_vm._v("Edit")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "MAWB #: ", prop: "master_no" } },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.master_no) + " ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Status: ", prop: "closed_stage" }
                          },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.closed_stage) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "150px",
                              label: "Departure Date: ",
                              prop: "departure_date"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.lotInfo.departure_date) + " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "150px",
                              label: "ETA Date: ",
                              prop: "eta_date"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.eta_date) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "From: ",
                              prop: "ship_from"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.ship_from) + " ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "To: ",
                              prop: "ship_to"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.ship_to) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "Container #1: ",
                              prop: "container_no_first"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.lotInfo.container_no_first) + " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "SEAL #1: ",
                              prop: "seal_no_first"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.lotInfo.seal_no_first) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "Container #2: ",
                              prop: "container_no_second"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.lotInfo.container_no_second) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "SEAL #2: ",
                              prop: "seal_no_second"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.lotInfo.seal_no_second) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Remarks: ", prop: "remarks" } },
                          [_vm._v(" " + _vm._s(_vm.lotInfo.remarks) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm.mode == "new" || _vm.mode == "edit"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.lot_info_loading,
                expression: "lot_info_loading"
              }
            ],
            attrs: { "element-loading-text": "Loading data..." }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.submitting,
                    expression: "submitting"
                  }
                ],
                ref: "elForm",
                attrs: {
                  model: _vm.lotInfo,
                  rules: _vm.lot_rules,
                  size: "medium",
                  "label-width": "100px",
                  "element-loading-text": "Submitting..."
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _vm.mode == "edit"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { float: "right" },
                                attrs: { type: "success", plain: "" },
                                on: {
                                  click: function($event) {
                                    _vm.mode = "view"
                                  }
                                }
                              },
                              [_vm._v("View")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "MAWB #", prop: "master_no" } },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "MAWB #" },
                              model: {
                                value: _vm.lotInfo.master_no,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "master_no", $$v)
                                },
                                expression: "lotInfo.master_no"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.lotInfo.id > 0
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Status", prop: "closed_stage" }
                              },
                              [
                                _c("stage-status-select", {
                                  attrs: {
                                    stage_status: _vm.lotInfo.closed_stage
                                  },
                                  on: { updateStatus: _vm.updateStageStatus }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "150px",
                              label: "Departure Date:",
                              prop: "departure_date"
                            }
                          },
                          [
                            _c("el-date-picker", {
                              style: { width: "100%" },
                              attrs: {
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                placeholder: "Departure Date:"
                              },
                              model: {
                                value: _vm.lotInfo.departure_date,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "departure_date", $$v)
                                },
                                expression: "lotInfo.departure_date"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "150px",
                              label: "ETA Date:",
                              prop: "eta_date"
                            }
                          },
                          [
                            _c("el-date-picker", {
                              style: { width: "100%" },
                              attrs: {
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                placeholder: "ETA Date:"
                              },
                              model: {
                                value: _vm.lotInfo.eta_date,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "eta_date", $$v)
                                },
                                expression: "lotInfo.eta_date"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "From",
                              prop: "ship_from"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "From" },
                              model: {
                                value: _vm.lotInfo.ship_from,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "ship_from", $$v)
                                },
                                expression: "lotInfo.ship_from"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "To",
                              prop: "ship_to"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "To" },
                              model: {
                                value: _vm.lotInfo.ship_to,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "ship_to", $$v)
                                },
                                expression: "lotInfo.ship_to"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "Container #1:",
                              prop: "container_no_first"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "Container #1:" },
                              model: {
                                value: _vm.lotInfo.container_no_first,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lotInfo,
                                    "container_no_first",
                                    $$v
                                  )
                                },
                                expression: "lotInfo.container_no_first"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "SEAL #1",
                              prop: "seal_no_first"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "SEAL #1" },
                              model: {
                                value: _vm.lotInfo.seal_no_first,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "seal_no_first", $$v)
                                },
                                expression: "lotInfo.seal_no_first"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "Container #2",
                              prop: "container_no_second"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "Container #2" },
                              model: {
                                value: _vm.lotInfo.container_no_second,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lotInfo,
                                    "container_no_second",
                                    $$v
                                  )
                                },
                                expression: "lotInfo.container_no_second"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "120px",
                              label: "SEAL #2",
                              prop: "seal_no_second"
                            }
                          },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: { placeholder: "SEAL #2" },
                              model: {
                                value: _vm.lotInfo.seal_no_second,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "seal_no_second", $$v)
                                },
                                expression: "lotInfo.seal_no_second"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Remarks", prop: "remarks" } },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                placeholder: "Remarks",
                                autosize: { minRows: 2, maxRows: 5 }
                              },
                              model: {
                                value: _vm.lotInfo.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.lotInfo, "remarks", $$v)
                                },
                                expression: "lotInfo.remarks"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "large" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.lotCanEdit
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v("Submit ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }