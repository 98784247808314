import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
import adminRoutes from './modules/admin';
import tlmRoutes from './modules/dep_tlm.js';
import airExportMAERoutes from './modules/dep_air_export_mae';
import seaExportRoutes from './modules/dep_sea_export';
import customsBroker from './modules/dep_broker.js';
import seaImportRoutes from './modules/dep_sea_import';
import airImportRoutes from './modules/dep_air_import';
import miscRoutes from './modules/dep_misc';
import deliveryRoutes from './modules/dep_delivery';
import addressBookRoutes from './modules/address_book';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    }
  }]
} // {
//   path: '/example',
//   component: Layout,
//   redirect: '/example/table',
//   name: 'Example',
//   meta: { title: 'Example', icon: 'el-icon-s-help' },
//   children: [
//     {
//       path: 'table',
//       name: 'Table',
//       component: () => import('@/views/table/index'),
//       meta: { title: 'Table', icon: 'table' }
//     },
//     {
//       path: 'tree',
//       name: 'Tree',
//       component: () => import('@/views/tree/index'),
//       meta: { title: 'Tree', icon: 'tree' }
//     }
//   ]
// },
// {
//   path: '/form',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       name: 'Form',
//       component: () => import('@/views/form/index'),
//       meta: { title: 'Form', icon: 'form' }
//     }
//   ]
// },
// {
//   path: '/object',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       name: 'Object View',
//       component: () => import('@/views/object/ObjectListViewer'),
//       meta: { title: 'Object View', icon: 'list' }
//     }
//   ]
// },
// {
//   path: '/tlm',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       name: 'TLM',
//       component: () => import('@/views/tlm/ObjectListViewer'),
//       meta: { title: 'TLM', icon: 'list' }
//     }
//   ]
// }  
];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [tlmRoutes, customsBroker, airImportRoutes, airExportMAERoutes, seaImportRoutes, seaExportRoutes, miscRoutes, deliveryRoutes, addressBookRoutes, adminRoutes, // {
//   path: '/nested',
//   component: Layout,
//   redirect: '/nested/menu1',
//   name: 'Nested',
//   meta: {
//     title: 'Nested',
//     icon: 'nested'
//   },
//   children: [
//     {
//       path: 'menu1',
//       component: () => import('@/views/nested/menu1/index'), // Parent router-view
//       name: 'Menu1',
//       meta: { title: 'Menu1' },
//       children: [
//         {
//           path: 'menu1-1',
//           component: () => import('@/views/nested/menu1/menu1-1'),
//           name: 'Menu1-1',
//           meta: { title: 'Menu1-1' }
//         },
//         {
//           path: 'menu1-2',
//           component: () => import('@/views/nested/menu1/menu1-2'),
//           name: 'Menu1-2',
//           meta: { title: 'Menu1-2' },
//           children: [
//             {
//               path: 'menu1-2-1',
//               component: () => import('@/views/nested/menu1/menu1-2/menu1-2-1'),
//               name: 'Menu1-2-1',
//               meta: { title: 'Menu1-2-1' }
//             },
//             {
//               path: 'menu1-2-2',
//               component: () => import('@/views/nested/menu1/menu1-2/menu1-2-2'),
//               name: 'Menu1-2-2',
//               meta: { title: 'Menu1-2-2' }
//             }
//           ]
//         },
//         {
//           path: 'menu1-3',
//           component: () => import('@/views/nested/menu1/menu1-3'),
//           name: 'Menu1-3',
//           meta: { title: 'Menu1-3' }
//         }
//       ]
//     },
//     {
//       path: 'menu2',
//       component: () => import('@/views/nested/menu2/index'),
//       meta: { title: 'menu2' }
//     }
//   ]
// },
// {
//   path: 'external-link',
//   component: Layout,
//   children: [
//     {
//       path: 'http://www.cifgroup.com',
//       meta: { title: 'CIFGroup', icon: 'link' }
//     }
//   ]
// },
{
  path: 'invoice_cost_items_link',
  component: Layout,
  children: [{
    path: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQzPfOX-9U9YPHx81lUT16HMwKlUnR9Ht_vHsniJ9PSGxFXIrCjLiAQS4P1DqLKzl_1GQSWBfZJgFti/pubhtml',
    meta: {
      title: 'Invoice/Cost items',
      icon: 'link'
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;