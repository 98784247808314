var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card user-bio", staticStyle: { padding: "20px" } },
    [
      _vm.lotInfo.id > 0
        ? _c("lot-view", {
            attrs: { lotInfoParent: _vm.lotInfo, modeParent: "view" },
            on: { commitLotObject: _vm.commitLotObject }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }