var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.layoutMode == "drawer"
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "30px",
              padding: "14px 15px",
              "border-bottom": "1px solid #dfe6ec"
            }
          },
          [
            _c("el-page-header", {
              attrs: {
                title: "Cancel",
                content: "Create " + _vm.department_config.name + " Lot"
              },
              on: { back: _vm.handleCloseDrawer }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "view"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              { attrs: { size: "medium", "label-width": "150px" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { type: "success", plain: "" },
                        on: {
                          click: function($event) {
                            _vm.mode = "edit"
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Master #: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.master_no) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.roles.includes("Admin") ||
                        _vm.roles.includes("Accountant") ||
                        _vm.roles.includes("General Manager")
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Category: " } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.ae_lot_info.category) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Status: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.closed_stage) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Customer Ref #:" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.ae_lot_info.customer_reference_no
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Customer Ref # -2 :" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.ae_lot_info
                                        .customer_reference_no_second
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Customer: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.customer_name) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Business Date: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.business_date) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c("el-form-item", { attrs: { label: "PCS: " } }, [
                              _vm._v(" " + _vm._s(_vm.ae_lot_info.pcs) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Weight: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.weight) +
                                    " " +
                                    _vm._s(_vm.ae_lot_info.weight_unit) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Remarks:", prop: "remarks" } },
                      [_vm._v(" " + _vm._s(_vm.ae_lot_info.remarks) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm.mode == "new" || _vm.mode == "edit"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.lot_info_loading,
                expression: "lot_info_loading"
              }
            ],
            attrs: { "element-loading-text": "Loading data..." }
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 27 } },
              [
                _c(
                  "el-form",
                  {
                    ref: "ae-lot-form",
                    attrs: {
                      model: _vm.ae_lot_info,
                      rules: _vm.ae_lot_rules,
                      size: "medium",
                      "label-width": "150px"
                    }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _vm.mode == "edit"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-bottom": "15px"
                                },
                                attrs: { type: "success", plain: "" },
                                on: {
                                  click: function($event) {
                                    _vm.mode = "view"
                                  }
                                }
                              },
                              [_vm._v("View")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Master#:",
                                      prop: "master_no"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "Master ",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.master_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "master_no",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.master_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.roles.includes("Admin") ||
                            _vm.roles.includes("Accountant") ||
                            _vm.roles.includes("General Manager")
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Category:",
                                          prop: "category"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "150px" },
                                            attrs: { placeholder: "" },
                                            model: {
                                              value: _vm.ae_lot_info.category,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ae_lot_info,
                                                  "category",
                                                  $$v
                                                )
                                              },
                                              expression: "ae_lot_info.category"
                                            }
                                          },
                                          _vm._l(_vm.categoryOptions, function(
                                            item,
                                            index
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.ae_lot_info.id > 0
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Status",
                                          prop: "closed_stage"
                                        }
                                      },
                                      [
                                        _c("stage-status-select", {
                                          attrs: {
                                            stage_status:
                                              _vm.ae_lot_info.closed_stage
                                          },
                                          on: {
                                            updateStatus: _vm.updateStageStatus
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Customer Ref #:",
                                      prop: "customer_reference_no"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "Customer Ref #",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.customer_reference_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "customer_reference_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.customer_reference_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Customer Ref #-2 :",
                                      prop: "customer_reference_no_second"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "Customer Ref # - 2",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.ae_lot_info
                                            .customer_reference_no_second,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "customer_reference_no_second",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.customer_reference_no_second"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Customer",
                                      prop: "customer_id"
                                    }
                                  },
                                  [
                                    _c("yip-contact-remote", {
                                      attrs: {
                                        placeholder: "Customer",
                                        contact_type: "customer",
                                        input_width: "170",
                                        show_detail_icon: "true"
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.customer_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "customer_id",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.customer_id"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Business Date:",
                                      prop: "business_date"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "Business Date:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.business_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "business_date",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.business_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "PCS:", prop: "pcs" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        placeholder: "PCS",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.pcs,
                                        callback: function($$v) {
                                          _vm.$set(_vm.ae_lot_info, "pcs", $$v)
                                        },
                                        expression: "ae_lot_info.pcs"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "Weight:", prop: "weight" }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        placeholder: "Weight",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.weight"
                                      }
                                    }),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: { placeholder: "" },
                                        model: {
                                          value: _vm.ae_lot_info.weight_unit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "weight_unit",
                                              $$v
                                            )
                                          },
                                          expression: "ae_lot_info.weight_unit"
                                        }
                                      },
                                      _vm._l(_vm.weight_unitOptions, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Remarks:", prop: "remarks" } },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 3 }
                              },
                              model: {
                                value: _vm.ae_lot_info.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.ae_lot_info, "remarks", $$v)
                                },
                                expression: "ae_lot_info.remarks"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "large" }
                          },
                          [
                            _c("el-button", { on: { click: _vm.resetForm } }, [
                              _vm._v("Reset")
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.lotCanEdit,
                                  type: "primary"
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }