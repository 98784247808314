import _objectSpread from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import PanThumb from '@/components/PanThumb'; // import GithubCorner from '@/components/GithubCorner'

export default {
  name: 'DashboardEditor',
  components: {
    PanThumb: PanThumb
  },
  data: function data() {
    return {
      emptyGif: 'https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3'
    };
  },
  computed: _objectSpread({}, mapGetters(['name', 'avatar', 'roles', 'role']))
};