var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: {
        width: "100%",
        "padding-top": "15px",
        "margin-left": "10px"
      },
      attrs: { data: _vm.newShipmentList }
    },
    [
      _c("el-table-column", {
        attrs: { label: "Lot", "min-width": "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("orderNoFilter")(scope.row.awb_no)) + " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Ref #", width: "125" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.ref_no) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "KGs", width: "60", align: "left" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.qty) + " ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Date", "min-width": "130" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v(" " + _vm._s(scope.row.last_date) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }