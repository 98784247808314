var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", {
        on: { handleSetLineChartData: _vm.handleSetLineChartData }
      }),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, lg: 12 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 32 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { span: 24 }
                            },
                            [_c("h3", [_vm._v("Chart-1")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "chart-wrapper" },
                        [_c("pie-chart")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { span: 24 }
                            },
                            [_c("h3", [_vm._v("Chart-2")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "chart-wrapper" },
                        [_c("bar-chart")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, lg: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { span: 24 }
                    },
                    [_c("h3", [_vm._v("Pending")])]
                  )
                ],
                1
              ),
              _c("transaction-table")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }