var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "item-edit-container" },
      [
        _c(
          "el-row",
          { staticClass: "invoice-tools" },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "tools-title" }, [
                _vm._v("Manifest for TLM-" + _vm._s(_vm.lotInfo.id))
              ])
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { float: "right" }
                },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo",
                      attrs: { mode: "horizontal" }
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "3" },
                          on: {
                            click: function($event) {
                              return _vm.print_manifest()
                            }
                          }
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "el-icon-printer" }),
                            _c("span", [_vm._v(" Print ")])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.manifestLoading,
                expression: "manifestLoading"
              }
            ],
            staticClass: "invoice-box",
            attrs: { id: "manifest-print-" + _vm.viewObjectId }
          },
          [
            _c("table", { attrs: { cellpadding: "0", cellspacing: "0" } }, [
              _c("tr", { staticClass: "top" }, [
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("table", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "site_name",
                          staticStyle: { width: "450px" }
                        },
                        [
                          _c("font", { attrs: { size: "5" } }, [
                            _vm._v(" TLM Cargo Manifest ")
                          ])
                        ],
                        1
                      ),
                      _vm._m(0)
                    ])
                  ])
                ])
              ]),
              _c("tr", { staticClass: "top" }, [
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("table", { staticClass: "lot-info" }, [
                    _c("tr", [
                      _c("td", { staticStyle: { width: "30%" } }, [
                        _vm._v(" M-B/L#: "),
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.lotInfo.master_no) + " ")
                        ]),
                        _c("br"),
                        _vm._v(" From: " + _vm._s(_vm.lotInfo.ship_from)),
                        _c("br"),
                        _vm._v(" To: " + _vm._s(_vm.lotInfo.ship_to)),
                        _c("br"),
                        _vm._v(
                          " Container #1: " +
                            _vm._s(_vm.lotInfo.container_no_first)
                        ),
                        _c("br"),
                        _vm._v(
                          " Container #2: " +
                            _vm._s(_vm.lotInfo.container_no_second)
                        ),
                        _c("br")
                      ]),
                      _c("td", { staticStyle: { width: "30%" } }, [
                        _vm._v(" LOT #: "),
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("TLM-" + _vm._s(_vm.lotInfo.id))
                        ]),
                        _c("br"),
                        _vm._v(
                          " DEP Date:" +
                            _vm._s(_vm.lotInfo.departure_date) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " DEP Time:" +
                            _vm._s(_vm.lotInfo.departure_time) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " Seal #1: " + _vm._s(_vm.lotInfo.seal_no_first)
                        ),
                        _c("br"),
                        _vm._v(
                          " Seal #2: " + _vm._s(_vm.lotInfo.seal_no_second)
                        ),
                        _c("br")
                      ]),
                      _c(
                        "td",
                        { staticStyle: { width: "30%" } },
                        [
                          _c("br"),
                          _vm._v(" ETA Date: " + _vm._s(_vm.lotInfo.eta_date)),
                          _c("br"),
                          _c("br"),
                          _vm.lotInfo.container_size_first
                            ? [
                                _vm._v(
                                  " Size : " +
                                    _vm._s(_vm.lotInfo.container_size_first) +
                                    "' "
                                )
                              ]
                            : _vm._e(),
                          _c("br"),
                          _vm.lotInfo.container_size_second
                            ? [
                                _vm._v(
                                  " Size : " +
                                    _vm._s(_vm.lotInfo.container_size_second) +
                                    "' "
                                )
                              ]
                            : _vm._e(),
                          _c("br")
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c(
              "table",
              [
                _vm._m(1),
                _vm._l(_vm.lotInfo.houses, function(item) {
                  return [
                    _c("tr", {}, [
                      _c("td", [
                        _c("br"),
                        _c("span", { staticClass: "house_no" }, [
                          _vm._v(_vm._s(item.house_no))
                        ])
                      ]),
                      _c("td", [
                        _c("br"),
                        _vm._v(" " + _vm._s(item.pallet) + " ")
                      ]),
                      _c("td", [
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "small-font" },
                          [
                            item.boxes
                              ? [
                                  _vm._v(
                                    "** " + _vm._s(item.boxes) + " BOXES ** "
                                  ),
                                  _c("br")
                                ]
                              : _vm._e(),
                            item.remarks
                              ? [_vm._v(" ** " + _vm._s(item.remarks) + " ** ")]
                              : _vm._e()
                          ],
                          2
                        )
                      ]),
                      _c("td", [
                        _c("br"),
                        _vm._v(" " + _vm._s(item.gwt) + " ")
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(item.shipper.short_name) + " "),
                        _c("br"),
                        _c("span", { staticClass: "small-font" }, [
                          _vm._v(" " + _vm._s(item.shipper.company) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.shipper.address) + " "),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(item.shipper.city) +
                              ", " +
                              _vm._s(item.shipper.state) +
                              " " +
                              _vm._s(item.shipper.zipcode) +
                              " "
                          ),
                          _c("br")
                        ])
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(item.cnee.short_name) + " "),
                        _c("br"),
                        _c("span", { staticClass: "small-font" }, [
                          _vm._v(" " + _vm._s(item.cnee.company) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.cnee.address) + " "),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(item.cnee.city) +
                              ", " +
                              _vm._s(item.cnee.state) +
                              " " +
                              _vm._s(item.cnee.zipcode) +
                              " "
                          ),
                          _c("br")
                        ])
                      ])
                    ]),
                    _c("tr", { staticClass: "item" }, [
                      _c("td", { staticClass: "foot-line" }),
                      _c("td", { staticClass: "small-font foot-line" }),
                      _c("td", { staticClass: "small-font foot-line" }, [
                        _vm._v(
                          " V.WT: " +
                            _vm._s(item.vwt) +
                            " / G.WT: " +
                            _vm._s(item.gwt) +
                            " "
                        )
                      ]),
                      _c("td", { staticClass: "small-font foot-line" }),
                      _c("td", { staticClass: "small-font foot-line" }, [
                        _vm._v("TEL: " + _vm._s(item.shipper.phone))
                      ]),
                      _c("td", { staticClass: "small-font foot-line" }, [
                        _vm._v("TEL: " + _vm._s(item.cnee.phone))
                      ])
                    ])
                  ]
                })
              ],
              2
            ),
            _c("table", { attrs: { id: "remarks" } }, [
              _c("tr", { staticClass: "item-remarks" }, [
                _c(
                  "td",
                  { staticClass: "font-bold", attrs: { width: "100px;" } },
                  [_vm._v("Remarks:")]
                ),
                _c("td", [_vm._v(_vm._s(_vm.lotInfo.remarks))])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "site-info" }, [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v("CIF Group International Inc.")
      ]),
      _c("br"),
      _vm._v(" 11013 N.W. 30th Street, Suite #115 "),
      _c("br"),
      _vm._v(" Miami, FL 33172 "),
      _c("br"),
      _vm._v(" PHONE: +01 (305) 594-3006 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "heading" }, [
      _c("td", { attrs: { width: "80px" } }, [_vm._v("H/B-L#")]),
      _c("td", [_vm._v("Pallet")]),
      _c("td", { attrs: { width: "180px" } }, [_vm._v("Description")]),
      _c("td", [_vm._v("WT(LB)")]),
      _c("td", [_vm._v("Shipper ")]),
      _c("td", [_vm._v("Consignee")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }