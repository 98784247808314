var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mode == "view"
        ? _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { size: "medium", "label-width": "150px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-bottom": "15px"
                          },
                          attrs: { type: "success", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.editLot()
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Customer: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.customer_name) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Department #: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.for_department) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Status: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.closed_stage) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Importing Carrier: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.importing_carrier_name
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Port Of Origin: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.from_port_of_origin
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Arrival Date:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.arrival_date) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "BOL / AWB: " } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.mawb_no) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Free Time Exp: " } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.free_time_exp) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivery Date:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.delivered_date) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "HAWB: " } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.hawb_no) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Entry:" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.entry_no) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Customer Ref #:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.customer_reference_no
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Transfer By:",
                                    prop: "local_delivery_company",
                                    "label-width": "180px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.local_delivery_company
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Route:" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ae_lot_info.route) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "WHS Order #:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.customer_order_no
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Pickup From:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.pickup_from_company
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Need Escort:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.escort_needed) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Pickup Address:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.pickup_from_address
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Freight Charges:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ae_lot_info.freight_charges) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivery To:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.delivery_to_company
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivery Address:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae_lot_info.delivery_to_address
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "20px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [_vm._v(" Packages:")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.package_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: "",
                                    "show-summary": "",
                                    "sum-text": "Total"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packages",
                                      label: "No. of Pkgs",
                                      width: "200"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.packages) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      644971284
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goods_description",
                                      label: "Description"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "text-more" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .goods_description
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1875985004
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "weight",
                                      label: "Weight",
                                      width: "200"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.weight
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(scope.row.weight)
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "3px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .weight_unit
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3779478196
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Remarks:", prop: "remarks" } },
                        [_vm._v(" " + _vm._s(_vm.ae_lot_info.remarks) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "1020px",
            "close-on-press-escape": false,
            wrapperClosable: false,
            visible: _vm.lotNewEditdrawer,
            "with-header": false,
            "destroy-on-close": true
          }
        },
        [
          _c("lot-new-edit", {
            key: _vm.drawerRandomKey,
            attrs: { objectId: _vm.lotInfo.id },
            on: {
              closeDrawer: _vm.closeDrawer,
              updateLotInfo: _vm.fetchObjectData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }