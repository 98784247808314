//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import InvoiceHub from "@/components/yipComponent/InvoiceHub"; // import fileUploader from '@/api/fileupload';

export default {
  components: {
    InvoiceHub: InvoiceHub
  },
  props: {
    // user: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: '',
    //       email: '',
    //       avatar: '',
    //       roles: [],
    //     };
    //   },
    // },
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      show_title: true,
      forceLoadData: true,
      inv_list_params: {
        superlot_id: 0,
        superhouse_id: 0,
        department: this.$route.meta.department_config.prefix
      },
      cols_show: ["id", "lot_info", "for_house_no", "qbo_invoice_no", "invoice_date", "due_date", "creator"] // forceLoadData:false,
      // inv_list_params:{}

    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {},
  methods: {}
};