var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0px 0px" } },
    [
      _c(
        "div",
        {
          class: _vm.showFilter ? _vm.frameHeader : _vm.frameHeaderWithoutFilter
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.showFilter
                    ? [
                        _vm._l(_vm.quickFilterConfig, function(qf) {
                          return [
                            qf.el_type == "radio"
                              ? _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "filter-bt-group",
                                    attrs: { size: "medium" },
                                    on: {
                                      change: function($event) {
                                        return _vm.fetchInvoiceList(true)
                                      }
                                    },
                                    model: {
                                      value: qf.set_value,
                                      callback: function($$v) {
                                        _vm.$set(qf, "set_value", $$v)
                                      },
                                      expression: "qf.set_value"
                                    }
                                  },
                                  [
                                    _vm._l(qf.option_list, function(opt) {
                                      return [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: opt.value } },
                                          [_vm._v(_vm._s(opt.name))]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : qf.el_type == "input"
                              ? _c("el-input", {
                                  staticStyle: { "padding-left": "15px" },
                                  style: { width: qf.width + "px" },
                                  attrs: {
                                    size: "medium",
                                    placeholder: qf.show_name
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.fetchInvoiceList(true)
                                    }
                                  },
                                  model: {
                                    value: qf.set_value,
                                    callback: function($$v) {
                                      _vm.$set(qf, "set_value", $$v)
                                    },
                                    expression: "qf.set_value"
                                  }
                                })
                              : _vm._e()
                          ]
                        }),
                        _c("yip-contact-remote", {
                          attrs: {
                            placeholder: "Customer",
                            contact_type: "customer",
                            input_width: "200",
                            padding_left: "10"
                          },
                          model: {
                            value: _vm.filter_customer_id,
                            callback: function($$v) {
                              _vm.filter_customer_id = $$v
                            },
                            expression: "filter_customer_id"
                          }
                        }),
                        _c("yip-contact-remote", {
                          attrs: {
                            placeholder: "Bill To",
                            contact_type: "customer",
                            input_width: "200",
                            padding_left: "10"
                          },
                          model: {
                            value: _vm.filter_payer_id,
                            callback: function($$v) {
                              _vm.filter_payer_id = $$v
                            },
                            expression: "filter_payer_id"
                          }
                        }),
                        _vm.filterVisiable
                          ? _c(
                              "el-popover",
                              {
                                staticClass: "filter-popover",
                                staticStyle: { margin: "0px 10px" },
                                attrs: {
                                  placement: "bottom-start",
                                  width: "420",
                                  trigger: "click"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "medium",
                                      type: "primary",
                                      plain: ""
                                    },
                                    slot: "reference"
                                  },
                                  [
                                    _vm._v("Filter "),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right"
                                    })
                                  ]
                                ),
                                _c(
                                  "el-form",
                                  {
                                    ref: "FilterForm",
                                    attrs: {
                                      model: _vm.form,
                                      "label-width": "105px"
                                    }
                                  },
                                  [
                                    _vm._l(_vm.filterConfig, function(ftc) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            key: ftc.option_name,
                                            attrs: {
                                              label: ftc.show_name,
                                              prop: ftc.option_name
                                            }
                                          },
                                          [
                                            ftc.el_type == "date_range"
                                              ? [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      type: "daterange",
                                                      "range-separator": "-",
                                                      "start-placeholder": "",
                                                      "end-placeholder": "",
                                                      format: "MM/dd/yyyy",
                                                      "value-format":
                                                        "MM/dd/yyyy"
                                                    },
                                                    model: {
                                                      value: ftc.set_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          ftc,
                                                          "set_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ftc.set_value"
                                                    }
                                                  })
                                                ]
                                              : ftc.el_type == "input"
                                              ? [
                                                  _c("el-input", {
                                                    model: {
                                                      value: ftc.set_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          ftc,
                                                          "set_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ftc.set_value"
                                                    }
                                                  })
                                                ]
                                              : ftc.el_type == "radio"
                                              ? [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticClass:
                                                        "filter-bt-group",
                                                      attrs: { size: "medium" },
                                                      model: {
                                                        value: ftc.set_value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            ftc,
                                                            "set_value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ftc.set_value"
                                                      }
                                                    },
                                                    [
                                                      _vm._l(
                                                        ftc.option_list,
                                                        function(opts) {
                                                          return [
                                                            _c(
                                                              "el-radio-button",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    opts.value
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    opts.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    }),
                                    _c(
                                      "el-form-item",
                                      { staticStyle: { float: "right" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "medium" },
                                            on: {
                                              click: function($event) {
                                                return _vm.resetFilterForm()
                                              }
                                            }
                                          },
                                          [_vm._v("Reset")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "medium",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchInvoiceList()
                                              }
                                            }
                                          },
                                          [_vm._v("Submit")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c("el-button", {
                    class: _vm.showFilter ? "" : _vm.refreshBtnWithoutFilter,
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      size: "medium",
                      type: "primary",
                      icon: "el-icon-refresh",
                      plain: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.fetchInvoiceList(true)
                      }
                    }
                  }),
                  _c(
                    "span",
                    { staticClass: "frame-title" },
                    [
                      _vm.superLotId > 0 && _vm.showNewButton
                        ? [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  disabled:
                                    _vm.lotInfo.closed_stage != "ACTIVE",
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  size: "medium",
                                  plain: ""
                                },
                                on: { click: _vm.handleNewInvoice }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.superHouseId > 0
                                      ? "New Invoice"
                                      : "New Master Invoice"
                                  )
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.showTitle
                        ? _c(
                            "div",
                            {
                              staticClass: "yip-header",
                              staticStyle: { "margin-right": "5px" }
                            },
                            [_vm._v(" Invoice List ")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.invoiceList,
                    border: "",
                    fit: "",
                    "empty-text": "Empty..."
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "", label: "ID", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [_vm._v(" " + _vm._s(slotProps.row.id) + " ")]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("qbo_invoice_no")
                    ? _c("el-table-column", {
                        attrs: { width: "80px", align: "", label: "QBO Inv" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.qbo_id > 0
                                    ? [
                                        slotProps.row.invoice_pdf_url
                                          ? _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  href:
                                                    slotProps.row
                                                      .invoice_pdf_url,
                                                  target: "_blank",
                                                  type: "warning"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-download"
                                                }),
                                                _vm._v("PDF ")
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    : [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value:
                                                slotProps.row.invoice_syncing,
                                              expression:
                                                "slotProps.row.invoice_syncing"
                                            }
                                          ],
                                          attrs: {
                                            type: "warning",
                                            icon: "el-icon-sort",
                                            size: "mini",
                                            plain: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleSyncInvoice(
                                                slotProps.$index
                                              )
                                            }
                                          }
                                        })
                                      ]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3333382513
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("lot_info")
                    ? _c("el-table-column", {
                        attrs: { width: "100px", align: "", label: "Lot#" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.lot_info.lot_prefix
                                      ) +
                                      "-" +
                                      _vm._s(slotProps.row.lot_info.id) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1839932648
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("lot_info")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "150px",
                          align: "",
                          label: _vm.department_config.inv_master_name
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.lot_info.master_no_display
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              slotProps.row.lot_info
                                                .master_no_display
                                            ) +
                                            " "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              slotProps.row.lot_info.master_no
                                            ) +
                                            " "
                                        )
                                      ]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2227417494
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("for_house_no")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          align: "",
                          label: _vm.department_config.inv_house_name
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.house_info.house_no
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          250935900
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { width: "150px", align: "", label: "Customer" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            slotProps.row.customer
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.customer.short_name
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "150px", align: "", label: "Bill To" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _vm._v(
                              " " + _vm._s(slotProps.row.payer.short_name) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "88px", align: "", label: "Amount" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _vm._v(
                              " $" + _vm._s(slotProps.row.total_amount) + " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("invoice_date")
                    ? _c("el-table-column", {
                        attrs: { width: "100px", align: "", label: "Inv Date" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          slotProps.row.invoice_date
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          38230345
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("due_date")
                    ? _c("el-table-column", {
                        attrs: { width: "100px", align: "", label: "Due Date" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          slotProps.row.due_date
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3188115020
                        )
                      })
                    : _vm._e(),
                  _vm.cols_show.includes("service")
                    ? _c("el-table-column", {
                        attrs: { align: "", label: "Service" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "ul",
                                    { staticStyle: { margin: "0px" } },
                                    [
                                      _vm._l(
                                        slotProps.row.invoiceitems,
                                        function(service) {
                                          return [
                                            _c("li", [
                                              _vm._v(
                                                _vm._s(service.name) +
                                                  " ($" +
                                                  _vm._s(service.amount) +
                                                  ")"
                                              )
                                            ])
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2974808462
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "status-col",
                      label: "Status",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "small",
                                  type: _vm._f("statusFilter")(
                                    slotProps.row.status
                                  )
                                }
                              },
                              [_vm._v(" " + _vm._s(slotProps.row.status) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.cols_show.includes("creator")
                    ? _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          align: "",
                          label: "Created By"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.creator) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1853723585
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { align: "", label: "Actions" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  icon: "el-icon-edit",
                                  type: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEditInvoice(
                                      slotProps.$index,
                                      slotProps.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("Edit")]
                            ),
                            slotProps.row.qbo_id > 0
                              ? _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      href:
                                        "https://app.qbo.intuit.com/app/invoice?txnId=" +
                                        slotProps.row.qbo_id,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " INV#:" +
                                        _vm._s(slotProps.row.qbo_invoice_no) +
                                        " "
                                    ),
                                    _c("i", { staticClass: "el-icon-link" })
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "frame-footer" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-pagination",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0"
                        }
                      ],
                      attrs: {
                        background: "",
                        layout: "slot,sizes, prev, pager, next",
                        total: _vm.total,
                        "page-sizes": [10, 15, 20, 30, 40, 50],
                        "current-page": _vm.listRequestParams.page,
                        "page-size": _vm.listRequestParams.limit
                      },
                      on: {
                        "size-change": function($event) {
                          return _vm.fetchInvoiceList()
                        },
                        "update:currentPage": function($event) {
                          return _vm.$set(_vm.listRequestParams, "page", $event)
                        },
                        "update:current-page": function($event) {
                          return _vm.$set(_vm.listRequestParams, "page", $event)
                        },
                        "current-change": function($event) {
                          return _vm.fetchInvoiceList()
                        },
                        "update:pageSize": function($event) {
                          return _vm.$set(
                            _vm.listRequestParams,
                            "limit",
                            $event
                          )
                        },
                        "update:page-size": function($event) {
                          return _vm.$set(
                            _vm.listRequestParams,
                            "limit",
                            $event
                          )
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v(" Total Records: " + _vm._s(_vm.total))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "Audit Log",
            visible: _vm.auditLogVisible,
            size: "680px",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.auditLogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.auditListLoading,
                          expression: "auditListLoading"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.currentAuditLog }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "expand" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: props.row.modified,
                                      border: "",
                                      "empty-text": "Empty..."
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "column",
                                        label: "Item",
                                        width: "180"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "old",
                                        label: "Before",
                                        width: "180"
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "new",
                                        label: "After",
                                        width: "180"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Time",
                          prop: "created_at",
                          width: "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(slotProps) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDateTime")(
                                        slotProps.row.created_at
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "User", prop: "operator_nickname" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Operation",
                          prop: "event",
                          width: "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "IP Address",
                          prop: "ip_address",
                          width: "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Auditable Type",
                          prop: "auditable_type",
                          width: "200"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "frame-footer" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("el-pagination", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.auditlog_total > 0,
                                    expression: "auditlog_total > 0"
                                  }
                                ],
                                attrs: {
                                  background: "",
                                  layout: "prev, pager, next",
                                  total: _vm.auditlog_total,
                                  "page-count": 10,
                                  "current-page":
                                    _vm.auditListRequestParams.page,
                                  "page-size": _vm.auditListRequestParams.limit
                                },
                                on: {
                                  "size-change": function($event) {
                                    return _vm.fetchInvoiceAuditLog()
                                  },
                                  "update:currentPage": function($event) {
                                    return _vm.$set(
                                      _vm.auditListRequestParams,
                                      "page",
                                      $event
                                    )
                                  },
                                  "update:current-page": function($event) {
                                    return _vm.$set(
                                      _vm.auditListRequestParams,
                                      "page",
                                      $event
                                    )
                                  },
                                  "current-change": function($event) {
                                    return _vm.fetchInvoiceAuditLog()
                                  },
                                  "update:pageSize": function($event) {
                                    return _vm.$set(
                                      _vm.auditListRequestParams,
                                      "limit",
                                      $event
                                    )
                                  },
                                  "update:page-size": function($event) {
                                    return _vm.$set(
                                      _vm.auditListRequestParams,
                                      "limit",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "margin-right": "10px"
                                  },
                                  attrs: { size: "small", round: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.fetchInvoiceAuditLog()
                                    }
                                  }
                                },
                                [_vm._v("Refresh")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "5vh",
            fullscreen: true,
            title: _vm.dialogInvoiceTitle,
            visible: _vm.dialogInvoiceVisible,
            "append-to-body": "",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "before-close": _vm.handleCloseDialogForce
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogInvoiceVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _vm._v(_vm._s(_vm.dialogInvoiceTitle) + " ")
              ]),
              _vm.currentInvoiceId > 0
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "30px",
                            "margin-top": "-5px"
                          },
                          attrs: { type: "warning", plain: "", size: "small" },
                          on: {
                            click: function($event) {
                              _vm.auditLogVisible = true
                            }
                          }
                        },
                        [_vm._v("AuditLog")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  style:
                    "width:" +
                    _vm.invoiceLeftWidth +
                    ";border-right:1px solid #dfe6ec;padding-right:10px;min-height: calc(100vh - 120px);;"
                },
                [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.invoiceFormLoading,
                              expression: "invoiceFormLoading"
                            }
                          ],
                          ref: "house_form",
                          attrs: {
                            model: _vm.currentInvoiceInfo,
                            "label-width": "120px",
                            "element-loading-text":
                              "Loading Invoice data from cloud..."
                          }
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Bill To",
                                        prop: "payer_id"
                                      }
                                    },
                                    [
                                      _c("yip-contact-remote", {
                                        attrs: {
                                          placeholder: "Payer",
                                          contact_type: "customer",
                                          input_width: "200",
                                          show_detail_icon: "true"
                                        },
                                        on: {
                                          applyContact: _vm.handleChangePayer
                                        },
                                        model: {
                                          value:
                                            _vm.currentInvoiceInfo.payer_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentInvoiceInfo,
                                              "payer_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentInvoiceInfo.payer_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.currentInvoiceId > 0
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Status" } },
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: _vm._f("statusFilter")(
                                                  _vm.currentInvoiceInfo.status
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentInvoiceInfo
                                                      .status
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.currentInvoiceId > 0
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 21 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Billing Address" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              disabled: ""
                                            },
                                            model: {
                                              value:
                                                _vm.currentInvoiceInfo
                                                  .payer_name +
                                                "\n" +
                                                _vm.currentInvoiceInfo
                                                  .payer_billing_address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.currentInvoiceInfo
                                                    .payer_name +
                                                    "\n" +
                                                    _vm.currentInvoiceInfo,
                                                  "payer_billing_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        currentInvoiceInfo.payer_name +\n                        '\\n' +\n                        currentInvoiceInfo.payer_billing_address\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Terms" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "60px" },
                                        on: { change: _vm.handleChangeTermDay },
                                        model: {
                                          value:
                                            _vm.currentInvoiceInfo
                                              .payer_terms_days,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentInvoiceInfo,
                                              "payer_terms_days",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentInvoiceInfo.payer_terms_days"
                                        }
                                      }),
                                      _vm._v(" Days ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Invoice Date" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "",
                                          clearable: false,
                                          format: "MM/dd/yyyy",
                                          "value-format": "MM/dd/yyyy"
                                        },
                                        on: {
                                          change: _vm.handleChangeInvoiceDate
                                        },
                                        model: {
                                          value:
                                            _vm.currentInvoiceInfo.invoice_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentInvoiceInfo,
                                              "invoice_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentInvoiceInfo.invoice_date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Due Date" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "",
                                          clearable: false,
                                          format: "MM/dd/yyyy",
                                          "value-format": "MM/dd/yyyy",
                                          disabled: ""
                                        },
                                        model: {
                                          value:
                                            _vm.currentInvoiceInfo.due_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentInvoiceInfo,
                                              "due_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentInvoiceInfo.due_date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Service List" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data:
                                              _vm.currentInvoiceInfo
                                                .invoiceitems,
                                            border: "",
                                            fit: "",
                                            "show-summary": "",
                                            "sum-text": "Summary",
                                            "summary-method":
                                              _vm.getInvoiceSummaries,
                                            "empty-text": "Empty..."
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "service_id",
                                              label: "Service",
                                              width: "350"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "320px"
                                                        },
                                                        attrs: {
                                                          placeholder: "",
                                                          filterable: "",
                                                          "no-data-text":
                                                            "Empty",
                                                          "no-match-text":
                                                            "No Matching Records Found"
                                                        },
                                                        model: {
                                                          value:
                                                            slotProps.row
                                                              .service_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              slotProps.row,
                                                              "service_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "slotProps.row.service_id"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.current_invoice_services,
                                                        function(item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.id,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                value: item.id
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "amount",
                                              label: "Amount",
                                              width: "120"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          slotProps.row.amount,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            slotProps.row,
                                                            "amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "slotProps.row.amount"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              prop: "service_desc",
                                              label: "Description"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          slotProps.row
                                                            .service_desc,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            slotProps.row,
                                                            "service_desc",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "slotProps.row.service_desc"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              label: "",
                                              width: "35"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(slotProps) {
                                                  return [
                                                    _c("el-link", {
                                                      attrs: {
                                                        icon: "el-icon-delete",
                                                        type: "danger"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleRemoveService(
                                                            slotProps.$index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: _vm.handleAddService
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-plus el-icon--right"
                                              }),
                                              _vm._v(" Add Service")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Remarks" } },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "textarea" },
                                        model: {
                                          value:
                                            _vm.currentInvoiceInfo
                                              .remarks_for_customer,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentInvoiceInfo,
                                              "remarks_for_customer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentInvoiceInfo.remarks_for_customer"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  [
                                    _vm.currentInvoiceId > 0
                                      ? [
                                          _vm.invoiceCanDelete
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.deleteLoading,
                                                        expression:
                                                          "deleteLoading"
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      float: "left"
                                                    },
                                                    attrs: {
                                                      type: "danger",
                                                      plain: ""
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleInvoiceDelete
                                                    }
                                                  },
                                                  [_vm._v("Delete")]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      float: "left"
                                                    },
                                                    attrs: {
                                                      type: "danger",
                                                      plain: "",
                                                      disabled: ""
                                                    }
                                                  },
                                                  [_vm._v("Delete")]
                                                )
                                              ]
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticStyle: { float: "right" } },
                                  [
                                    _vm.submitting
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                disabled: ""
                                              }
                                            },
                                            [_vm._v("Submitting...")]
                                          )
                                        ]
                                      : [
                                          _vm.invoiceCanUpdate
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value: _vm.submitting,
                                                        expression: "submitting"
                                                      }
                                                    ],
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.handleInvoiceSubmit
                                                    }
                                                  },
                                                  [_vm._v("Submit")]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      plain: "",
                                                      disabled: ""
                                                    }
                                                  },
                                                  [_vm._v("Submit")]
                                                )
                                              ]
                                        ]
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.canShowRightPanel
                ? _c(
                    "el-col",
                    {
                      style:
                        "width:calc(100% - " +
                        _vm.invoiceLeftWidth +
                        ");padding-left:5px;"
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "h3",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("Lot/House Info")]
                            )
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: { padding: "0 10px" },
                              attrs: { span: 24 }
                            },
                            [
                              _vm.currentLotInfo.id > 0
                                ? [
                                    _c("div", [
                                      _c("span", { staticClass: "inv_meta" }, [
                                        _vm._v("Lot #:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentLotInfo.lot_prefix
                                          ) +
                                          " - " +
                                          _vm._s(_vm.currentLotInfo.id) +
                                          " "
                                      )
                                    ]),
                                    _c("div", [
                                      _c("span", { staticClass: "inv_meta" }, [
                                        _vm._v("ETA Date:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentLotInfo.eta_date) +
                                          " "
                                      )
                                    ]),
                                    _c("div", [
                                      _c("span", { staticClass: "inv_meta" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.department_config
                                              .inv_master_name
                                          ) + ":"
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentLotInfo.master_no_display
                                              ? _vm.currentLotInfo
                                                  .master_no_display
                                              : _vm.currentLotInfo.master_no
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm.currentHouseInfo.id > 0
                                ? [
                                    _c("div", [
                                      _c("span", { staticClass: "inv_meta" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.department_config.inv_house_name
                                          ) + ":"
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentHouseInfo.house_no
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm.currentInvoiceInfo.generated_from_pricing_id >
                              0
                                ? [
                                    _c("div", [
                                      _c("span", { staticClass: "inv_meta" }, [
                                        _vm._v("Generated By IW #:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentInvoiceInfo
                                              .generated_from_pricing_id
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "pub_dialog",
              attrs: {
                width: "80%",
                title: "Send Invoice",
                visible: _vm.dialogSendInvoiceVisible,
                "before-close": _vm.handleInvoiceSendClose,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogSendInvoiceVisible = $event
                }
              }
            },
            [
              _c("invoice-send", {
                attrs: { invoiceId: _vm.invoiceSendId },
                on: {
                  "update:invoiceId": function($event) {
                    _vm.invoiceSendId = $event
                  },
                  "update:invoice-id": function($event) {
                    _vm.invoiceSendId = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }