import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var department_config = {
  id: 5,
  name: 'TLM',
  prefix: 'TLM',
  route_path: 'tlm',
  api_path: 'tlm',
  inv_master_name: 'Master',
  inv_house_name: 'House'
};
var tlmRoutes = {
  path: '/tlm',
  component: Layout,
  redirect: 'noRedirect',
  name: 'TLM',
  alwaysShow: true,
  meta: {
    title: 'TLM',
    icon: 'el-icon-truck',
    roles: ['Admin', 'General Manager', 'Accountant', 'TLM Manager', 'TLM OP', 'TLM Warehouse', 'OP-All'] // permissions: ['view menu '],

  },
  children: [
  /** User managements */
  // {
  //   path: 'test_view',
  //   name: 'Test Page',
  //   component: () => import('@/views/_test'),
  //   meta: { title: 'Test Page', icon: '' }
  // },       
  {
    path: 'index',
    name: 'Lot List',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_tlm/TlmLotList'));
      });
    },
    meta: {
      title: 'Lot List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu tlm index']
    }
  }, {
    path: 'invoice_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_tlm/TlmInvoiceList'));
      });
    },
    name: 'Invoice List',
    meta: {
      title: 'Invoice List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu tlm invoices']
    }
  }, // {
  //   path: 'statement_list',
  //   component: () => import('@/views/share_components/StatementList'),
  //   name: 'Statement List',
  //   meta: { title: 'Statement List', department_config:department_config , icon: '', permissions: ['view menu tlm invoices'] },
  // },    
  {
    path: 'cost_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_tlm/TlmCostList'));
      });
    },
    name: 'Cost List',
    meta: {
      title: 'Cost List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu tlm costs']
    }
  }, {
    path: 'pricing_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_tlm/TlmPricingList'));
      });
    },
    name: 'IW List',
    meta: {
      title: 'IW List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu tlm pricings']
    }
  }, {
    path: 'manifest',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_tlm/TlmManifest'));
      });
    },
    name: 'Manifest',
    meta: {
      title: 'Manifest',
      department_config: department_config,
      icon: ''
    },
    hidden: true
  }]
};
export default tlmRoutes;