var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0px 10px" } },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticStyle: { float: "right" } },
              [
                _c("el-button", {
                  attrs: {
                    size: "medium",
                    type: "primary",
                    icon: "el-icon-refresh",
                    plain: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.reLoadTab()
                    }
                  }
                }),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "medium",
                      plain: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.noteDrawerVisible = true
                      }
                    }
                  },
                  [_vm._v("Add Note")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-text": "Loading...",
                    data: _vm.noteList,
                    "empty-text": "There is no files found",
                    border: "",
                    fit: ""
                  }
                },
                [
                  _vm.showOnInvoice
                    ? [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        })
                      ]
                    : _vm._e(),
                  _vm.cols_show.includes("id")
                    ? _c("el-table-column", {
                        attrs: { align: "", label: "ID", width: "70" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3961030224
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { align: "", label: "Brief" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c("span", { staticClass: "content-cut" }, [
                              _vm._v(" " + _vm._s(slotProps.row.content) + " ")
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "110px", align: "", label: "Created By" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            slotProps.row.creator
                              ? [_vm._v(_vm._s(slotProps.row.creator.nickname))]
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "135px", align: "", label: "Created At" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDateTime")(
                                    slotProps.row.created_at
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "", label: "Actions", width: "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  type: "success",
                                  size: "small",
                                  icon: "el-icon-view",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleNoteView(
                                      slotProps.$index,
                                      slotProps.row
                                    )
                                  }
                                }
                              },
                              [_vm._v(" View ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.reLoadTab
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          ref: "noteDrawer",
          attrs: {
            title: _vm.noteDrawerTitle,
            "before-close": _vm.cancelNotesDrawer,
            visible: _vm.noteDrawerVisible,
            direction: "rtl",
            "custom-class": "demo-drawer",
            "append-to-body": true,
            size: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.noteDrawerVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.noteFormLoading,
                      expression: "noteFormLoading"
                    }
                  ],
                  attrs: {
                    model: _vm.currentNoteInfo,
                    "element-loading-text": "Loading data..."
                  }
                },
                [
                  _c("span", { staticStyle: { padding: "10px" } }, [
                    _vm._v("Put something here:")
                  ]),
                  _c(
                    "el-form-item",
                    { staticStyle: { padding: "10px" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: "5" },
                        model: {
                          value: _vm.currentNoteInfo.content,
                          callback: function($$v) {
                            _vm.$set(_vm.currentNoteInfo, "content", $$v)
                          },
                          expression: "currentNoteInfo.content"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", {
                    staticStyle: { padding: "10px", float: "right" }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "demo-drawer__footer",
                  staticStyle: { float: "right" }
                },
                [
                  _c("el-button", { on: { click: _vm.cancelNotesDrawer } }, [
                    _vm._v("Cancel")
                  ]),
                  _vm.submitting
                    ? [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", disabled: "" } },
                          [_vm._v("Submitting...")]
                        )
                      ]
                    : [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.submitting,
                                expression: "submitting"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.handleNoteSubmit }
                          },
                          [_vm._v("Submit")]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Add Note",
            visible: _vm.addNoteDialogFormVisible,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addNoteDialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.noteForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.noteForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.noteForm, "name", $$v)
                      },
                      expression: "noteForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动区域" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动区域" },
                      model: {
                        value: _vm.noteForm.region,
                        callback: function($$v) {
                          _vm.$set(_vm.noteForm, "region", $$v)
                        },
                        expression: "noteForm.region"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "区域一", value: "shanghai" }
                      }),
                      _c("el-option", {
                        attrs: { label: "区域二", value: "beijing" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addNoteDialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.addNoteDialogFormVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }