import { render, staticRenderFns } from "./lot-delete.vue?vue&type=template&id=47898893&scoped=true&"
import script from "./lot-delete.vue?vue&type=script&lang=js&"
export * from "./lot-delete.vue?vue&type=script&lang=js&"
import style0 from "./lot-delete.vue?vue&type=style&index=0&id=47898893&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47898893",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47898893')) {
      api.createRecord('47898893', component.options)
    } else {
      api.reload('47898893', component.options)
    }
    module.hot.accept("./lot-delete.vue?vue&type=template&id=47898893&scoped=true&", function () {
      api.rerender('47898893', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LotDelete/vue/lot-delete.vue"
export default component.exports