var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "0px 10px" } },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v(" email 参数 ")]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.pdfLoading,
                    expression: "pdfLoading"
                  }
                ],
                staticClass: "pdf_view",
                attrs: { "element-loading-text": "PDF preview is loading..." }
              },
              [
                _vm.currentInvoiceInfo.id > 0
                  ? _c("embed", {
                      attrs: {
                        src: _vm.currentInvoiceInfo.invoice_pdf_local_path,
                        type: "application/pdf",
                        view: "fit"
                      }
                    })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }