var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { float: "right", "margin-bottom": "10px" }
        },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "5px" },
            attrs: { placeholder: "Keyword" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.query.keyword,
              callback: function($$v) {
                _vm.$set(_vm.query, "keyword", $$v)
              },
              expression: "query.keyword"
            }
          }),
          _c("el-button", {
            directives: [{ name: "waves", rawName: "v-waves" }],
            staticClass: "filter-item",
            staticStyle: { "margin-left": "5px" },
            attrs: { type: "primary", plain: "", icon: "el-icon-refresh" },
            on: { click: _vm.handleFilter }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", plain: "", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(" Add Group ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "empty-text": "Empty..."
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "", label: "ID", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.index))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Name", "max-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Desc" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.desc))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Created By", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.creator))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Created At", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(scope.row.created_at))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Actions", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "padding-right": "15px" },
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleGroupEdit(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v(" Edit ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-pagination",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0"
            }
          ],
          attrs: {
            background: "",
            layout: "slot,sizes, prev, pager, next",
            total: _vm.total,
            "page-sizes": [10, 15, 20, 30, 40, 50],
            page: _vm.query.page,
            limit: _vm.query.limit
          },
          on: {
            "size-change": function($event) {
              return _vm.getList()
            },
            "current-change": function($event) {
              return _vm.getList()
            },
            "update:page": function($event) {
              return _vm.$set(_vm.query, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.query, "limit", $event)
            }
          }
        },
        [
          _c("span", { staticStyle: { "margin-right": "20px" } }, [
            _vm._v(" Total Records: " + _vm._s(_vm.total))
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.userCreating,
                  expression: "userCreating"
                }
              ],
              staticClass: "form-container"
            },
            [
              _c(
                "el-form",
                {
                  ref: "groupForm",
                  staticStyle: { "max-width": "500px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.newGroup,
                    "label-position": "left",
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Group Name", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.newGroup.name,
                          callback: function($$v) {
                            _vm.$set(_vm.newGroup, "name", $$v)
                          },
                          expression: "newGroup.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Description", prop: "desc" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.newGroup.desc,
                          callback: function($$v) {
                            _vm.$set(_vm.newGroup, "desc", $$v)
                          },
                          expression: "newGroup.desc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitGroup()
                        }
                      }
                    },
                    [_vm._v(" Confirm ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }