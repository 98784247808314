var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.layoutMode == "drawer"
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "30px",
              padding: "14px 15px",
              "border-bottom": "1px solid #dfe6ec"
            }
          },
          [
            _c("el-page-header", {
              attrs: {
                title: "Cancel",
                content: "Create " + _vm.department_config.name + " Lot"
              },
              on: { back: _vm.handleCloseDrawer }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "view"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              { attrs: { size: "medium", "label-width": "170px" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { type: "success", plain: "" },
                        on: {
                          click: function($event) {
                            _vm.mode = "edit"
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "MBL #: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.master_no) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Status: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.closed_stage) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Port of Loading: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.shipment_from) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Departure from: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.departure_date) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Port of Discharge: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.shipment_to) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "ETA Date: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.eta_date) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Final Dest: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.final_destination) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Final ETA: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.final_eta) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Container #: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.container_no) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "VESSEL: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.vessel) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Voyage:" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.voy_fleight) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Remarks:", prop: "remarks" } },
                      [_vm._v(" " + _vm._s(_vm.ae_lot_info.remarks) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm.mode == "new" || _vm.mode == "edit"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.lot_info_loading,
                expression: "lot_info_loading"
              }
            ],
            attrs: { "element-loading-text": "Loading data..." }
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 27 } },
              [
                _c(
                  "el-form",
                  {
                    ref: "ae-lot-form",
                    attrs: {
                      model: _vm.ae_lot_info,
                      rules: _vm.ae_lot_rules,
                      size: "medium",
                      "label-width": "170px"
                    }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _vm.mode == "edit"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-bottom": "15px"
                                },
                                attrs: { type: "success", plain: "" },
                                on: {
                                  click: function($event) {
                                    _vm.mode = "view"
                                  }
                                }
                              },
                              [_vm._v("View")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "MBL#:", prop: "master_no" }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "MBL ",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.master_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "master_no",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.master_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.ae_lot_info.id > 0
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Status",
                                          prop: "closed_stage"
                                        }
                                      },
                                      [
                                        _c("stage-status-select", {
                                          attrs: {
                                            stage_status:
                                              _vm.ae_lot_info.closed_stage
                                          },
                                          on: {
                                            updateStatus: _vm.updateStageStatus
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Port of Loading:",
                                      prop: "shipment_from"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        placeholder: "From",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.shipment_from,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "shipment_from",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.shipment_from"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Departure Date:",
                                      prop: "departure_date"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "Departure Date:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.departure_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "departure_date",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.departure_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Port of Discharge:",
                                      prop: "shipment_to"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        placeholder: "To",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.shipment_to,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "shipment_to",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.shipment_to"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "ETA Date:",
                                      prop: "eta_date"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "ETA Date:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.eta_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "eta_date",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.eta_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Final Dest:",
                                      prop: "final_destination"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        placeholder: "Final Destination",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.final_destination,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "final_destination",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.final_destination"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Final ETA",
                                      prop: "final_eta"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "Final ETA:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.final_eta,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "final_eta",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.final_eta"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Container #:",
                                      prop: "container_no"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "Container #",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.container_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "container_no",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.container_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "VESSEL:", prop: "vessel" }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "VESSEL",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.vessel,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "vessel",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.vessel"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Voyage:",
                                      prop: "voy_fleight"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "", clearable: "" },
                                      model: {
                                        value: _vm.ae_lot_info.voy_fleight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "voy_fleight",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.voy_fleight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Remarks:", prop: "remarks" } },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 3 }
                              },
                              model: {
                                value: _vm.ae_lot_info.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.ae_lot_info, "remarks", $$v)
                                },
                                expression: "ae_lot_info.remarks"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "large" }
                          },
                          [
                            _c("el-button", { on: { click: _vm.resetForm } }, [
                              _vm._v("Reset")
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.lotCanEdit,
                                  type: "primary"
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }