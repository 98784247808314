var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frame-page" }, [
    _c(
      "div",
      { staticClass: "frame-header" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("yip-table-filter", {
                  attrs: { filterConfig: _vm.tableFilterConfig },
                  on: {
                    "update:filterConfig": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    "update:filter-config": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    updateTableFilterParams: _vm.getTableFilterParams,
                    submitForm: _vm.getHouseList
                  }
                }),
                _c(
                  "span",
                  { staticClass: "frame-title" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          disabled: _vm.lotInfo.closed_stage != "ACTIVE",
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "medium",
                          plain: ""
                        },
                        on: { click: _vm.handleNewHouse }
                      },
                      [_vm._v("New House")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list_body" },
      [
        _c(
          "el-scrollbar",
          { staticClass: "frame-body-scrollbar" },
          [
            _c(
              "el-card",
              { staticClass: "box-card user-bio" },
              [
                _c(
                  "div",
                  { staticClass: "user-activity" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.houseList,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                          "empty-text": "Empty..."
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { align: "", label: "ID", width: "80" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "120px", align: "", label: "BOL#" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.house_no) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "60px", align: "", label: "IW#" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.pricing_id > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(slotProps.row.pricing_id) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "Customer"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        slotProps.row.customer.short_name
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "Shipper"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.shipper.short_name) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "140px", align: "", label: "CNEE" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.cnee.short_name) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "110px",
                            align: "",
                            label: "Invoice Amt"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.invoice_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "110px",
                            align: "",
                            label: "Cost Amt"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.cost_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "Create Date"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          slotProps.row.created_at
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { align: "", label: "Actions" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "16px" },
                                      attrs: {
                                        plain: "",
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditHouse(
                                            slotProps.$index,
                                            slotProps.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Edit ")]
                                  ),
                                  slotProps.row.cost_amount == 0 &&
                                  slotProps.row.invoice_amount == 0
                                    ? _c("el-button", {
                                        staticStyle: { "margin-left": "16px" },
                                        attrs: {
                                          plain: "",
                                          type: "danger",
                                          icon: "el-icon-delete",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleDeleteHouse(
                                              slotProps.$index,
                                              slotProps.row
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "frame-footer" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-pagination",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.total > 0,
                                        expression: "total > 0"
                                      }
                                    ],
                                    attrs: {
                                      background: "",
                                      layout: "slot,sizes, prev, pager, next",
                                      total: _vm.total,
                                      "page-sizes": [10, 15, 20, 30, 40, 50],
                                      "current-page":
                                        _vm.listRequestParams.page,
                                      "page-size": _vm.listRequestParams.limit
                                    },
                                    on: {
                                      "size-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:currentPage": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "update:current-page": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "current-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:pageSize": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      },
                                      "update:page-size": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          " Total Records: " + _vm._s(_vm.total)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      size: "960px",
                      "close-on-press-escape": false,
                      wrapperClosable: false,
                      visible: _vm.drawer,
                      "with-header": false,
                      "destroy-on-close": true
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.drawer = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "14px 15px",
                          "border-bottom": "1px solid #dfe6ec"
                        }
                      },
                      [
                        _c("el-page-header", {
                          attrs: {
                            title: "Back",
                            content: _vm.houseDrawerTitle
                          },
                          on: { back: _vm.handleCloseDrawer }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tabs",
                          {
                            staticClass: "yip-tabs",
                            attrs: { type: "card" },
                            model: {
                              value: _vm.HouseEditActiveTab,
                              callback: function($$v) {
                                _vm.HouseEditActiveTab = $$v
                              },
                              expression: "HouseEditActiveTab"
                            }
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "House Info",
                                  name: "house_info"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.current_house_loading,
                                        expression: "current_house_loading"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        ref: "house_form",
                                        attrs: {
                                          model: _vm.currentHouseInfo,
                                          "label-width": "120px"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "BOL#" } },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .house_no,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "house_no",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.house_no"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "IW#" } },
                                                  [
                                                    _c("el-input", {
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleIWChange()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .pricing_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "pricing_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.pricing_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 3 } },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Load IW info to auto fill Customer/Shipper/CNEE",
                                                      placement: "top"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleIWChange()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "padding-top":
                                                                "10px",
                                                              "font-size":
                                                                "20px",
                                                              color: "#409eff"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-refresh-right"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("el-row", { attrs: { gutter: 20 } }),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 5 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Customer",
                                                      prop: "customer_id"
                                                    }
                                                  },
                                                  [
                                                    _c("yip-contact-remote", {
                                                      attrs: {
                                                        placeholder: "Customer",
                                                        contact_type:
                                                          "customer",
                                                        input_width: "170",
                                                        show_detail_icon: "true"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .customer_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "customer_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.customer_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Shipper",
                                                      prop: "shipper_id"
                                                    }
                                                  },
                                                  [
                                                    _c("yip-contact-remote", {
                                                      attrs: {
                                                        placeholder: "Shipper",
                                                        contact_type: "shipper",
                                                        input_width: "170",
                                                        show_detail_icon: "true"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .shipper_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "shipper_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.shipper_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "CNEE",
                                                      prop: "cnee_id"
                                                    }
                                                  },
                                                  [
                                                    _c("yip-contact-remote", {
                                                      attrs: {
                                                        placeholder: "CNEE",
                                                        contact_type: "cnee",
                                                        input_width: "170",
                                                        show_detail_icon: "true"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .cnee_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "cnee_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.cnee_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Insur dec:"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          oninput:
                                                            "value=value.replace(/[^0-9.]/g,'')"
                                                        },
                                                        on: {
                                                          change: function(
                                                            data
                                                          ) {
                                                            return (_vm.currentHouseInfo.insurance_declarations = _vm.toPrice(
                                                              data
                                                            ))
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.currentHouseInfo
                                                              .insurance_declarations,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.currentHouseInfo,
                                                              "insurance_declarations",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                              currentHouseInfo.insurance_declarations\n                            "
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "prepend" },
                                                          [_vm._v("$")]
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: { label: "C.O.D:" }
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.currentHouseInfo
                                                              .cod,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.currentHouseInfo,
                                                              "cod",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "currentHouseInfo.cod"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "1"
                                                            }
                                                          },
                                                          [_vm._v("Yes")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "0"
                                                            }
                                                          },
                                                          [_vm._v("No")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "7512:" } },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.currentHouseInfo
                                                              .charge_7512,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.currentHouseInfo,
                                                              "charge_7512",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "currentHouseInfo.charge_7512"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "1"
                                                            }
                                                          },
                                                          [_vm._v("Yes")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "0"
                                                            }
                                                          },
                                                          [_vm._v("No")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 5 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: { label: "Pallet:" }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .pallet,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "pallet",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.pallet"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 5 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: { label: "Boxes:" }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .boxes,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "boxes",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.boxes"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 5 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "V.WT:" } },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .vwt,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "vwt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.vwt"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 5 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "G.WT:" } },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .gwt,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "gwt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.gwt"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 20 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: { label: "remarks" }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "textarea"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .remarks,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "remarks",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.remarks"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      float: "right"
                                                    }
                                                  },
                                                  [
                                                    _vm.submitting
                                                      ? [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                disabled: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Submitting..."
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "loading",
                                                                  rawName:
                                                                    "v-loading",
                                                                  value:
                                                                    _vm.submitting,
                                                                  expression:
                                                                    "submitting"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "primary",
                                                                disabled:
                                                                  _vm.lotInfo
                                                                    .closed_stage !=
                                                                  "ACTIVE"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.onSubmit
                                                              }
                                                            },
                                                            [_vm._v("Submit")]
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm.currentHouseId > 0
                              ? [
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.lotInfo.closed_stage !=
                                              "ACTIVE",
                                            label: "Pricing",
                                            name: "house_pricing"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c("pricing-connect", {
                                                    attrs: {
                                                      lotInfo: _vm.lotInfo,
                                                      lotId: _vm.viewObjectId,
                                                      superLotId:
                                                        _vm.lotInfo.superlot_id,
                                                      superHouseId:
                                                        _vm.currentHouseInfo
                                                          .superhouse_id,
                                                      pricingId:
                                                        _vm.currentHouseInfo
                                                          .pricing_id
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Invoices",
                                            name: "house_invoice"
                                          }
                                        },
                                        [
                                          _c("invoice-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.invoicehub_request_params,
                                              cols_show:
                                                _vm.invoicehub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.invoiceShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Costs",
                                            name: "house_cost"
                                          }
                                        },
                                        [
                                          _c("cost-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.costhub_request_params,
                                              cols_show: _vm.costhub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.costShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }