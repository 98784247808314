//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import LotView from "../LotView";
import Pagination from "@/components/Pagination"; // Secondary package based on

import Resource from "@/api/resource";
import fileUploader from "@/api/fileupload";
import { mapGetters } from "vuex"; // const lotResource = new Resource('lot_air_export');

export default {
  components: {
    LotView: LotView
  },
  props: {
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    viewObjectId: {
      type: String,
      default: 0
    },
    editObjectId: {
      type: String,
      default: "0"
    }
  },
  data: function data() {
    return {
      form: {},
      form_init: {
        id: "",
        master_no: "",
        departure_date: "",
        eta_date: "",
        department_id: 5,
        remarks: ""
      }
    };
  },
  methods: {
    // initForm() {
    //   console.log('init form.....')
    //   this.lotInfo = this.lotInfo_init
    //   this.$refs['form'].resetFields()
    // },
    commitLotObject: function commitLotObject(data) {
      console.warn("--commitLotObject on taboverview ---", data);
    },
    backToList: function backToList() {
      console.log("----this.viewObjectId---", this.viewObjectId);
      this.$emit("update:editObjectId", 0);
      this.$emit("update:viewObjectId", this.viewObjectId);
    }
  },
  watch: {
    viewObjectId: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(newV, oldV) {
        console.log("---watch viewObjectId changed in TabHouseList---", newV, oldV);

        if (newV != oldV) {
          console.log("---getting LOT info ...---"); // this.fetchTabData()

          console.log("refetching data....."); // const { data } = lotResource.get(this.viewObjectId);
          // console.log('----lotResource.get----')
          // console.log(data)
          // this.lotInfo = data
          // this.lotInfo_loading = false
          // const vm = this
          // getTlmLotDetail(to_id).then((res) => {
          //   vm.fillTlmLotDetail(res)
          // }).catch(function(error) {
          //   // vm.initForm()
          //   console.log('failed to get lot info...')
          // })
        }
      }
    }
  }
};