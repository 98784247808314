import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var department_config = {
  id: 8,
  name: 'Miscellaneous',
  prefix: 'MIS',
  route_path: 'miscellaneous',
  api_path: 'miscellaneous',
  inv_master_name: 'Master',
  inv_house_name: 'House'
};
var seaImport = {
  path: '/miscellaneous',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Misc',
  alwaysShow: true,
  meta: {
    title: 'Misc',
    icon: 'component',
    roles: ['Admin', 'General Manager', 'Accountant', 'Misc Manager', 'Misc OP', 'Misc Warehouse', 'OP-All'] // permissions: ['view menu '],

  },
  children: [
  /** User managements */
  // {
  //   path: 'test_view',
  //   name: 'Test Page',
  //   component: () => export('@/views/_test'),
  //   meta: { title: 'Test Page', icon: '' }
  // },       
  {
    path: 'index',
    name: 'Lot List',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_misc/LotList'));
      });
    },
    meta: {
      title: 'Lot List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport index']
    }
  }, {
    path: 'invoice_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_misc/InvoiceList'));
      });
    },
    name: 'Invoice List',
    meta: {
      title: 'Invoice List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport invoices']
    }
  }, {
    path: 'cost_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_misc/CostList'));
      });
    },
    name: 'Cost List',
    meta: {
      title: 'Cost List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport costs']
    }
  } // {
  //   path: 'pricing_list',
  //   component: () => import('@/views/dep_miscellaneous/PricingList'),
  //   name: 'IW List',
  //   meta: { title: 'IW List', department_config:department_config ,  icon: '', permissions: ['view menu customsbroker pricings'] },
  // },
  // {
  //   path: 'manifest',
  //   component: () => import('@/views/dep_miscellaneous/Manifest'),
  //   name: 'Manifest',
  //   meta: { title: 'Manifest', department_config:department_config ,  icon: '' },
  //   hidden: true
  // },
  ]
};
export default seaImport;