var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.lot_info_loading,
          expression: "lot_info_loading"
        }
      ],
      staticStyle: { "border-left": "0px" },
      attrs: { "element-loading-text": "Loading data..." }
    },
    [
      _c(
        "el-header",
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticStyle: { width: "50px" } }, [
                _c(
                  "div",
                  { staticStyle: { "padding-top": "10px" } },
                  [
                    _vm.listMode == "left"
                      ? void 0
                      : [
                          _c(
                            "el-link",
                            {
                              staticStyle: { "font-size": "22px" },
                              on: {
                                click: function($event) {
                                  return _vm.handleSetListMode("left")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-arrow-right",
                                attrs: { size: "large" }
                              })
                            ]
                          )
                        ]
                  ],
                  2
                )
              ]),
              _c(
                "el-col",
                { staticStyle: { width: "calc(100% - 50px)" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "yip-header" }, [
                          _vm._v(
                            " LOT #: " +
                              _vm._s(_vm.lotInfo.lot_prefix) +
                              "-" +
                              _vm._s(_vm.lotInfo.id) +
                              " "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "10px" } },
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    hit: "",
                                    type: _vm._f("stageStatusFilter")(
                                      _vm.lotInfo.closed_stage
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.lotInfo.closed_stage) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "yip-header-desc" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.department_config.inv_master_name) +
                              " : " +
                              _vm._s(_vm.lotInfo.master_no) +
                              " "
                          )
                        ])
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple-light" },
                            [
                              _c("lot-delete", {
                                attrs: {
                                  lot_department:
                                    _vm.department_config.api_path,
                                  lotInfo: _vm.lotInfo
                                },
                                on: {
                                  backToLotList: function($event) {
                                    return _vm.backToList()
                                  }
                                }
                              }),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    href: "/tlm/manifest?id=" + _vm.lotInfo.id,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-notebook-2",
                                        size: "medium",
                                        plain: ""
                                      }
                                    },
                                    [_vm._v("Manifest")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "30px" } },
                                [
                                  _c("el-link", {
                                    staticStyle: { "font-size": "25px" },
                                    attrs: { icon: "el-icon-close" },
                                    on: {
                                      click: function($event) {
                                        return _vm.backToList()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeActivity,
            callback: function($$v) {
              _vm.activeActivity = $$v
            },
            expression: "activeActivity"
          }
        },
        _vm._l(_vm.tabsList, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.label, name: item.name } },
            [
              _c(item.contentComponent, {
                tag: "component",
                attrs: {
                  lotInfo: _vm.lotInfo,
                  viewObjectId: _vm.viewObjectId,
                  forceLoadData: item.forceLoadData,
                  superLotId: _vm.lotInfo.superlot_id
                },
                on: {
                  "update:viewObjectId": function($event) {
                    _vm.viewObjectId = $event
                  },
                  "update:view-object-id": function($event) {
                    _vm.viewObjectId = $event
                  },
                  "update:forceLoadData": function($event) {
                    return _vm.$set(item, "forceLoadData", $event)
                  },
                  "update:force-load-data": function($event) {
                    return _vm.$set(item, "forceLoadData", $event)
                  }
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }