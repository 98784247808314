import _objectSpread from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/styles/variables.scss";
import Hamburger from "@/components/Hamburger";
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo,
    Hamburger: Hamburger
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permission_routes", "sidebar"])), {}, {
    routes: function routes() {
      return this.$store.state.permission.routes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      console.log("---toggleSideBar---");
      this.$store.dispatch("app/toggleSideBar");
    }
  }
};