//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { transactionList } from '@/api/remote-search'
export default {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'danger'
      };
      return statusMap[status];
    },
    orderNoFilter: function orderNoFilter(str) {
      return str.substring(0, 30);
    }
  },
  data: function data() {
    return {
      newShipmentList: [{
        awb_no: 'TLM-0015',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }, {
        awb_no: 'TLM-0011',
        ref_no: 'REF-DE889166',
        qty: 1523,
        last_date: '8/10/2021'
      }]
    };
  },
  created: function created() {// this.fetchData()
  },
  methods: {}
};