import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.string.includes";
import store from '@/store';

function checkPermission(el, binding) {
  var value = binding.value;
  var permissions = store.getters && store.getters.permissions;

  if (value && value instanceof Array && value.length > 0) {
    var requiredPermissions = value;
    var hasPermission = permissions.some(function (permission) {
      return requiredPermissions.includes(permission);
    });

    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    throw new Error("Permissions are required! Example: v-permission=\"['manage user','manage permission']\"");
  }
}

export default {
  inserted: function inserted(el, binding) {
    checkPermission(el, binding);
  },
  update: function update(el, binding) {
    checkPermission(el, binding);
  }
};