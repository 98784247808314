import { render, staticRenderFns } from "./CostList.vue?vue&type=template&id=0e44f889&scoped=true&"
import script from "./CostList.vue?vue&type=script&lang=js&"
export * from "./CostList.vue?vue&type=script&lang=js&"
import style0 from "./CostList.vue?vue&type=style&index=0&id=0e44f889&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e44f889",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e44f889')) {
      api.createRecord('0e44f889', component.options)
    } else {
      api.reload('0e44f889', component.options)
    }
    module.hot.accept("./CostList.vue?vue&type=template&id=0e44f889&scoped=true&", function () {
      api.rerender('0e44f889', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dep_customsbroker/CostList.vue"
export default component.exports