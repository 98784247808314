var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "frame-page" },
    [
      _c(
        "div",
        { staticClass: "frame-header" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("yip-table-filter", {
                    attrs: { filterConfig: _vm.tableFilterConfig },
                    on: {
                      "update:filterConfig": function($event) {
                        _vm.tableFilterConfig = $event
                      },
                      "update:filter-config": function($event) {
                        _vm.tableFilterConfig = $event
                      },
                      updateTableFilterParams: _vm.getTableFilterParams,
                      submitForm: _vm.getHouseList
                    }
                  }),
                  _c(
                    "span",
                    { staticClass: "frame-title" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: {
                            disabled: _vm.lotInfo.closed_stage != "ACTIVE",
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "medium",
                            plain: ""
                          },
                          on: { click: _vm.handleNewHouse }
                        },
                        [_vm._v("New HBL")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list_body" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "frame-body-scrollbar" },
            [
              _c(
                "el-card",
                { staticClass: "box-card user-bio" },
                [
                  _c(
                    "div",
                    { staticClass: "user-activity" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading"
                            }
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.houseList,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                            "empty-text": "Empty..."
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { align: "", label: "ID", width: "80" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { width: "100px", align: "", label: "HBL#" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(slotProps.row.house_no) + " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { width: "60px", align: "", label: "IW#" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    slotProps.row.pricing_id > 0
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(slotProps.row.pricing_id) +
                                              " "
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "130px",
                              align: "",
                              label: "Shipper"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.shipper_name) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { width: "130px", align: "", label: "CNEE" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.cnee_name) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "130px",
                              align: "",
                              label: "Agent"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.agent_name) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "130px",
                              align: "",
                              label: "Invoice Amount"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(slotProps.row.invoice_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "110px",
                              align: "",
                              label: "Cost Amount"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(slotProps.row.cost_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "100px",
                              align: "",
                              label: "Created By"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(slotProps.row.creator) + " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "140px",
                              align: "",
                              label: "Create Date"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            slotProps.row.created_at
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { align: "", label: "Actions" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "6px" },
                                        attrs: {
                                          plain: "",
                                          type: "primary",
                                          size: "small",
                                          icon: "el-icon-edit"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEditHouse(
                                              slotProps.$index,
                                              slotProps.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Edit ")]
                                    ),
                                    _c(
                                      "el-dropdown",
                                      {
                                        staticStyle: { "margin-left": "5px" },
                                        on: { command: _vm.handleClickHBL }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "success",
                                              plain: "",
                                              size: "small"
                                            }
                                          },
                                          [
                                            _vm._v(" HBL "),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown"
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.beforeHandleCommand(
                                                    slotProps.row.id,
                                                    "original"
                                                  )
                                                }
                                              },
                                              [_vm._v("ORIGINAL")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.beforeHandleCommand(
                                                    slotProps.row.id,
                                                    "non_negotiable"
                                                  )
                                                }
                                              },
                                              [_vm._v("NON-NEGOTIABLE")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "frame-footer" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-pagination",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.total > 0,
                                          expression: "total > 0"
                                        }
                                      ],
                                      attrs: {
                                        background: "",
                                        layout: "slot,sizes, prev, pager, next",
                                        total: _vm.total,
                                        "page-sizes": [10, 15, 20, 30, 40, 50],
                                        "current-page":
                                          _vm.listRequestParams.page,
                                        "page-size": _vm.listRequestParams.limit
                                      },
                                      on: {
                                        "size-change": function($event) {
                                          return _vm.getHouseList()
                                        },
                                        "update:currentPage": function($event) {
                                          return _vm.$set(
                                            _vm.listRequestParams,
                                            "page",
                                            $event
                                          )
                                        },
                                        "update:current-page": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.listRequestParams,
                                            "page",
                                            $event
                                          )
                                        },
                                        "current-change": function($event) {
                                          return _vm.getHouseList()
                                        },
                                        "update:pageSize": function($event) {
                                          return _vm.$set(
                                            _vm.listRequestParams,
                                            "limit",
                                            $event
                                          )
                                        },
                                        "update:page-size": function($event) {
                                          return _vm.$set(
                                            _vm.listRequestParams,
                                            "limit",
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Total Records: " +
                                              _vm._s(_vm.total)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-drawer",
                    {
                      attrs: {
                        size: "1020px",
                        "close-on-press-escape": false,
                        wrapperClosable: false,
                        visible: _vm.drawer,
                        "with-header": false,
                        "destroy-on-close": true
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.drawer = $event
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "14px 15px",
                            "border-bottom": "1px solid #dfe6ec"
                          }
                        },
                        [
                          _c("el-page-header", {
                            attrs: {
                              title: "Back",
                              content: _vm.houseDrawerTitle
                            },
                            on: { back: _vm.handleCloseDrawer }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "yip-tabs",
                              attrs: { type: "card" },
                              model: {
                                value: _vm.HouseEditActiveTab,
                                callback: function($$v) {
                                  _vm.HouseEditActiveTab = $$v
                                },
                                expression: "HouseEditActiveTab"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "House Info",
                                    name: "house_info"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.current_house_loading,
                                          expression: "current_house_loading"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 27 } },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              ref: "ae-house-form",
                                              attrs: {
                                                model: _vm.currentHouseInfo,
                                                rules: _vm.ae_house_rules,
                                                size: "medium",
                                                "label-width": "150px"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "HBL#:",
                                                                prop: "house_no"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "HBL#:",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .house_no,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "house_no",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.house_no"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Booking #:",
                                                                prop:
                                                                  "booking_no"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "Booking #",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .booking_no,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "booking_no",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.booking_no"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "OBL #",
                                                                prop:
                                                                  "master_no"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  disabled: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .master_no,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "master_no",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.master_no"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Shipper: ",
                                                                prop:
                                                                  "shipper_id"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Shipper",
                                                                    contact_type:
                                                                      "shipper",
                                                                    input_width:
                                                                      "160",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .shipper_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "shipper_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.shipper_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "CNEE: ",
                                                                prop: "cnee_id"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "CNEE",
                                                                    contact_type:
                                                                      "cnee",
                                                                    input_width:
                                                                      "160",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .cnee_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "cnee_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.cnee_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 7 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Departure Date:",
                                                                prop:
                                                                  "departure_date"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    format:
                                                                      "MM/dd/yyyy",
                                                                    "value-format":
                                                                      "MM/dd/yyyy",
                                                                    placeholder:
                                                                      "Departure Date:",
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .departure_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "departure_date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.departure_date"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "NOTIFY PARTY: ",
                                                                prop:
                                                                  "notify_party_id"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "NOTIFY PARTY",
                                                                    contact_type:
                                                                      "cnee",
                                                                    input_width:
                                                                      "160",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .notify_party_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "notify_party_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.notify_party_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "For Delivery Apply To",
                                                                prop:
                                                                  "delivery_apply_id",
                                                                "label-width":
                                                                  "180px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "",
                                                                    contact_type:
                                                                      "agent",
                                                                    input_width:
                                                                      "130",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .delivery_apply_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "delivery_apply_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.delivery_apply_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Forwarding Agent",
                                                                prop: "agent_id"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Agent",
                                                                    contact_type:
                                                                      "agent",
                                                                    input_width:
                                                                      "145",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .agent_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "agent_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.agent_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "PRE-CARRIAGE BY:",
                                                                prop:
                                                                  "pre_carriage_by"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .pre_carriage_by,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "pre_carriage_by",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.pre_carriage_by"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Place of Receipt:",
                                                                prop:
                                                                  "place_of_receipt"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .place_of_receipt,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "place_of_receipt",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.place_of_receipt"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Export Carrier: ",
                                                                prop:
                                                                  "carrier_id"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "yip-contact-remote",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Carrier",
                                                                    contact_type:
                                                                      "carrier",
                                                                    input_width:
                                                                      "145",
                                                                    show_detail_icon:
                                                                      "true"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .carrier_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "carrier_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.carrier_id"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Venssel/Voyage:",
                                                                prop:
                                                                  "venssel_voyage"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .venssel_voyage,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "venssel_voyage",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.venssel_voyage"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Port of Loading:",
                                                                prop:
                                                                  "seaport_departure"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Select seaport",
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.changePortLoading
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .seaport_departure,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "seaport_departure",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.seaport_departure"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.seaport_code,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        attrs: {
                                                                          label:
                                                                            item.name,
                                                                          value:
                                                                            item.name
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 7 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "ETA Date:",
                                                                prop: "eta_date"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    format:
                                                                      "MM/dd/yyyy",
                                                                    "value-format":
                                                                      "MM/dd/yyyy",
                                                                    placeholder:
                                                                      "ETA Date:",
                                                                    clearable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .eta_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "eta_date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.eta_date"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Port of Discharge:",
                                                                prop:
                                                                  "seaport_destination"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Select seaport",
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.changePortDischarge
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .seaport_destination,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "seaport_destination",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.seaport_destination"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.seaport_code,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        attrs: {
                                                                          label:
                                                                            item.name,
                                                                          value:
                                                                            item.name
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Place of Delivery:",
                                                                prop:
                                                                  "place_of_delivery"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .place_of_delivery,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "place_of_delivery",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.place_of_delivery"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 7 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Freight Charges:",
                                                                prop:
                                                                  "freight_charges",
                                                                "label-width":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .freight_charges,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "freight_charges",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.freight_charges"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.freight_chargesOptions,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        attrs: {
                                                                          label:
                                                                            item.label,
                                                                          value:
                                                                            item.value
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Origin of goods:",
                                                                prop:
                                                                  "origin_of_goods"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .origin_of_goods,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "origin_of_goods",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.origin_of_goods"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Loading Pier/Terminal:",
                                                                prop:
                                                                  "loading_pier_terminal",
                                                                "label-width":
                                                                  "170px"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .loading_pier_terminal,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "loading_pier_terminal",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "\n                                    currentHouseInfo.loading_pier_terminal\n                                  "
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Transshipment Port:",
                                                                prop:
                                                                  "transshipment_port"
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "",
                                                                  clearable: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .transshipment_port,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "transshipment_port",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.transshipment_port"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Number of Originals:",
                                                                prop:
                                                                  "number_of_originals",
                                                                "label-width":
                                                                  "180px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .number_of_originals,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "number_of_originals",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.number_of_originals"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.number_of_originalsOptions,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        attrs: {
                                                                          label:
                                                                            item.label,
                                                                          value:
                                                                            item.value
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 7 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Service Type:",
                                                                prop:
                                                                  "service_type",
                                                                "label-width":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.changeServiceType
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .service_type,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "service_type",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.service_type"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.service_typeOptions,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        attrs: {
                                                                          label:
                                                                            item.label,
                                                                          value:
                                                                            item.value
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 16 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Shipper's Load and Count:",
                                                                prop:
                                                                  "shipper_load_count",
                                                                "label-width":
                                                                  "300px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio-group",
                                                                {
                                                                  attrs: {
                                                                    size:
                                                                      "medium"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .shipper_load_count,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "shipper_load_count",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.shipper_load_count"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.shipper_load_countOptions,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-radio",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          label:
                                                                            item.value
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticClass:
                                                            "label-text",
                                                          attrs: { span: 12 }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Particulars Furnished By Shipper "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "30px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "text",
                                                                    icon:
                                                                      "el-icon-plus"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.packing_list_new_line(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Add more container"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "0px",
                                                        "padding-left": "30px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-table",
                                                            {
                                                              attrs: {
                                                                border: "",
                                                                "highlight-current-row":
                                                                  "",
                                                                data:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .packing_list_data,
                                                                "empty-text":
                                                                  "Empty...",
                                                                stripe: "",
                                                                fit: "",
                                                                "show-summary":
                                                                  "",
                                                                "sum-text":
                                                                  "Total"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "container_no",
                                                                    label:
                                                                      "Container#",
                                                                    width: "165"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-more"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      scope
                                                                                        .row
                                                                                        .container_no
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                scope
                                                                                  .row
                                                                                  .seal_no
                                                                                  ? [
                                                                                      _vm._v(
                                                                                        " Seal #: " +
                                                                                          _vm._s(
                                                                                            scope
                                                                                              .row
                                                                                              .seal_no
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                      _c(
                                                                                        "br"
                                                                                      )
                                                                                    ]
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      scope
                                                                                        .row
                                                                                        .container_marks
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "br"
                                                                                )
                                                                              ],
                                                                              2
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "packages",
                                                                    label:
                                                                      "No. of Pkgs",
                                                                    width: "100"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .packages
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "goods_description",
                                                                    label:
                                                                      "Description",
                                                                    width: "290"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-more"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    scope
                                                                                      .row
                                                                                      .goods_description
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "gross_weight",
                                                                    label:
                                                                      "Gross Weight",
                                                                    width: "140"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            scope
                                                                              .row
                                                                              .gross_weight
                                                                              ? [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        scope
                                                                                          .row
                                                                                          .gross_weight
                                                                                      )
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        "margin-left":
                                                                                          "3px"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "KG"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "measurement",
                                                                    label:
                                                                      "Measurement",
                                                                    width: "140"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            scope
                                                                              .row
                                                                              .measurement
                                                                              ? [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        scope
                                                                                          .row
                                                                                          .measurement
                                                                                      )
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        "margin-left":
                                                                                          "3px"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "CBM"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label: "OP"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                attrs: {
                                                                                  size:
                                                                                    "mini",
                                                                                  icon:
                                                                                    "el-icon-edit",
                                                                                  type:
                                                                                    "success",
                                                                                  circle:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.editPackingListRow(
                                                                                      scope.$index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                attrs: {
                                                                                  size:
                                                                                    "mini",
                                                                                  icon:
                                                                                    "el-icon-delete",
                                                                                  type:
                                                                                    "danger",
                                                                                  circle:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.deletePackingListRow(
                                                                                      scope.$index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticClass:
                                                            "label-text",
                                                          attrs: { span: 12 }
                                                        },
                                                        [
                                                          _vm._v(" Charges "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "30px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "text",
                                                                    icon:
                                                                      "el-icon-plus"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.charge_list_new_line(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Add Charge"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px",
                                                        "padding-left": "30px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-table",
                                                            {
                                                              staticStyle: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                border: "",
                                                                "highlight-current-row":
                                                                  "",
                                                                data:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .charge_list_data,
                                                                "empty-text":
                                                                  "Empty...",
                                                                stripe: "",
                                                                fit: "",
                                                                "show-summary":
                                                                  "",
                                                                "sum-text":
                                                                  "Total"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "charge_item",
                                                                    label:
                                                                      "FREIGHT & CHARGES ",
                                                                    width: "280"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .charge_item,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      scope.row,
                                                                                      "charge_item",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "scope.row.charge_item"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "basis",
                                                                    label:
                                                                      "Basis",
                                                                    width: "140"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .basis,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      scope.row,
                                                                                      "basis",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "scope.row.basis"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "rate",
                                                                    label:
                                                                      "Rate",
                                                                    width: "120"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .rate,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      scope.row,
                                                                                      "rate",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "scope.row.rate"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "prepaid",
                                                                    label:
                                                                      "Prepaid",
                                                                    width: "120"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .prepaid,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      scope.row,
                                                                                      "prepaid",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "scope.row.prepaid"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "collect",
                                                                    label:
                                                                      "Collect",
                                                                    width: "120"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    scope
                                                                                      .row
                                                                                      .collect,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      scope.row,
                                                                                      "collect",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "scope.row.collect"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label: "OP"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                attrs: {
                                                                                  size:
                                                                                    "mini",
                                                                                  icon:
                                                                                    "el-icon-delete",
                                                                                  type:
                                                                                    "danger",
                                                                                  circle:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.deleteChargeListRow(
                                                                                      scope.$index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 23 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "20px"
                                                      },
                                                      attrs: {
                                                        label: "Remarks:",
                                                        prop: "remarks"
                                                      }
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        style: { width: "70%" },
                                                        attrs: {
                                                          type: "textarea",
                                                          autosize: {
                                                            minRows: 3,
                                                            maxRows: 3
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.currentHouseInfo
                                                              .remarks,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.currentHouseInfo,
                                                              "remarks",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "currentHouseInfo.remarks"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 23 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        float: "right"
                                                      },
                                                      attrs: { size: "large" }
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.lotInfo
                                                                .closed_stage !=
                                                              "ACTIVE",
                                                            type: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.submitForm
                                                          }
                                                        },
                                                        [_vm._v("Submit")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm.currentHouseId > 0
                                ? [
                                    _vm.currentHouseInfo.superhouse_id > 0
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            attrs: {
                                              label: "Invoices",
                                              name: "house_invoice"
                                            }
                                          },
                                          [
                                            _c("invoice-hub", {
                                              attrs: {
                                                showNewButton: true,
                                                superLotId:
                                                  _vm.lotInfo.superlot_id,
                                                superHouseId:
                                                  _vm.currentHouseInfo
                                                    .superhouse_id,
                                                lotInfo: _vm.lotInfo,
                                                filterParams:
                                                  _vm.invoicehub_request_params,
                                                cols_show:
                                                  _vm.invoicehub_cols_show,
                                                forceLoadData:
                                                  _vm.forceLoadData,
                                                showFilter:
                                                  _vm.invoiceShowFilter
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.currentHouseInfo.superhouse_id > 0
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            attrs: {
                                              label: "Costs",
                                              name: "house_cost"
                                            }
                                          },
                                          [
                                            _c("cost-hub", {
                                              attrs: {
                                                showNewButton: true,
                                                superLotId:
                                                  _vm.lotInfo.superlot_id,
                                                superHouseId:
                                                  _vm.currentHouseInfo
                                                    .superhouse_id,
                                                lotInfo: _vm.lotInfo,
                                                filterParams:
                                                  _vm.costhub_request_params,
                                                cols_show:
                                                  _vm.costhub_cols_show,
                                                forceLoadData:
                                                  _vm.forceLoadData,
                                                showFilter: _vm.costShowFilter
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "packingForm",
          attrs: {
            title: _vm.packingDialogStatus + " Container Detail",
            visible: _vm.dialogPackingFormVisible,
            "append-to-body": "",
            "custom-class": "dialogStyle"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPackingFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.packingRowForm, "label-width": "150px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Container #:", prop: "container_no" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.container_no,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_no",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Seal #:", prop: "seal_no" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.seal_no,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "seal_no", $$v)
                              },
                              expression: "packingRowForm.seal_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Size/Type:",
                            prop: "container_size_type"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.packingRowForm.container_size_type,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "container_size_type",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.container_size_type"
                              }
                            },
                            _vm._l(_vm.container_size_typeOptions, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Marks & Nos. :",
                            prop: "container_marks"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.packingRowForm.container_marks,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_marks",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_marks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Packages:", prop: "packages" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            model: {
                              value: _vm.packingRowForm.packages,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "packages", $$v)
                              },
                              expression: "packingRowForm.packages"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Gross Weight:",
                            prop: "gross_weight"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "160px" },
                              model: {
                                value: _vm.packingRowForm.gross_weight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "gross_weight",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.gross_weight"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("KG")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Measurement:", prop: "measurement" }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "160px" },
                              model: {
                                value: _vm.packingRowForm.measurement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "measurement",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.measurement"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("CBM")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-row"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Description of Packages Goods:",
                    prop: "goods_description"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "430px",
                              "text-transform": "uppercase"
                            },
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.packingRowForm.goods_description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "goods_description",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.goods_description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogPackingFormVisible = false
                    }
                  }
                },
                [_vm._v("discard")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handlePackingRowConfirm()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }