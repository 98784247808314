var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "30px",
            padding: "14px 15px",
            "border-bottom": "1px solid #dfe6ec"
          }
        },
        [
          _c("el-page-header", {
            attrs: {
              title: "Close",
              content: _vm.mode + " " + _vm.department_config.name
            },
            on: { back: _vm.handleCloseDrawer },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      { staticStyle: { width: "900px" } },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm._v(
                            _vm._s(_vm.mode) +
                              " " +
                              _vm._s(_vm.department_config.name) +
                              " "
                          )
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm.ae_lot_info.id > 0
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "padding-right": "10px",
                                    float: "right",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(" Status: "),
                                  _c("stage-status-changer", {
                                    attrs: {
                                      stage_status:
                                        _vm.ae_lot_info.closed_stage,
                                      size: "small"
                                    },
                                    on: { updateStatus: _vm.updateStageStatus }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.lot_info_loading,
              expression: "lot_info_loading"
            }
          ],
          attrs: { "element-loading-text": "Loading data..." }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 27 } },
            [
              _c(
                "el-form",
                {
                  ref: "ae-lot-form",
                  attrs: {
                    model: _vm.ae_lot_info,
                    rules: _vm.ae_lot_rules,
                    size: "medium",
                    "label-width": "150px",
                    "validate-on-rule-change": false
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Customer",
                                    prop: "customer_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "Customer",
                                      contact_type: "customer",
                                      input_width: "170",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.customer_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "customer_id",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.customer_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Department:",
                                    prop: "for_department"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "Department" },
                                      model: {
                                        value: _vm.ae_lot_info.for_department,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "for_department",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.for_department"
                                      }
                                    },
                                    _vm._l(_vm.for_departmentOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ae_lot_info.for_department != "DEL"
                            ? _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Dept. Lot #: ",
                                        prop: "for_lot_id"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "LOT #",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.ae_lot_info.for_lot_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ae_lot_info,
                                                "for_lot_id",
                                                $$v
                                              )
                                            },
                                            expression: "ae_lot_info.for_lot_id"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.ae_lot_info.for_department
                                              ) + "-"
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Importing Carrier",
                                    prop: "importing_carrier_id"
                                  }
                                },
                                [
                                  _c("yip-contact-remote", {
                                    attrs: {
                                      placeholder: "Carrier",
                                      contact_type: "carrier",
                                      input_width: "170",
                                      show_detail_icon: "true"
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.importing_carrier_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "importing_carrier_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.importing_carrier_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Port Of Origin:",
                                    prop: "from_port_of_origin"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "From Port Of Origin",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.from_port_of_origin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "from_port_of_origin",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.from_port_of_origin"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Arrival Date:",
                                    prop: "arrival_date"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      format: "MM/dd/yyyy",
                                      "value-format": "MM/dd/yyyy",
                                      placeholder: "Arrival Date",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.arrival_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "arrival_date",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.arrival_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "BOL / AWB#:",
                                    prop: "mawb_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "BOL / MAWB#",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.mawb_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "mawb_no",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.mawb_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Free Time Exp.",
                                    prop: "free_time_exp",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", clearable: "" },
                                    model: {
                                      value: _vm.ae_lot_info.free_time_exp,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "free_time_exp",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.free_time_exp"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Delivery Date:",
                                    prop: "delivered_date"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      format: "MM/dd/yyyy",
                                      "value-format": "MM/dd/yyyy",
                                      placeholder: "Delivery Date",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.delivered_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "delivered_date",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.delivered_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "HAWB #:", prop: "hawb_no" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "HAWB #",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.hawb_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "hawb_no",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.hawb_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Entry #:", prop: "entry_no" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Entry #",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.entry_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "entry_no",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.entry_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Customer Ref #:",
                                    prop: "customer_reference_no"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Customer Ref #",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.customer_reference_no,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "customer_reference_no",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.customer_reference_no"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Transfer By:",
                                    prop: "local_delivery_company"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        "Local Delivery / Transfer By",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.local_delivery_company,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "local_delivery_company",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.local_delivery_company"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Route :", prop: "route" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "Route" },
                                      model: {
                                        value: _vm.ae_lot_info.route,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "route",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.route"
                                      }
                                    },
                                    _vm._l(_vm.routeOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.ae_lot_info.for_department == "WHS"
                            ? _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "WHS Order #:",
                                        prop: "customer_order_no"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "WHS Order #",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.ae_lot_info.customer_order_no,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "customer_order_no",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ae_lot_info.customer_order_no"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Packaging:",
                                    prop: "service_packaging"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "Packaging" },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.service_packaging,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "service_packaging",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.service_packaging"
                                      }
                                    },
                                    _vm._l(
                                      _vm.service_packagingOptions,
                                      function(item, index) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "C.O.D",
                                    prop: "",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "C.O.D" },
                                      model: {
                                        value: _vm.ae_lot_info.service_cod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "service_cod",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.service_cod"
                                      }
                                    },
                                    _vm._l(_vm.service_codOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _vm.ae_lot_info.service_cod != "None"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "C.O.D Amount:",
                                        prop: "service_cod_amount"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "C.O.D Amount",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.ae_lot_info.service_cod_amount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "service_cod_amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ae_lot_info.service_cod_amount"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Service:",
                                    prop: "",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": "YES",
                                        "false-label": "NO"
                                      },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.service_messenger,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "service_messenger",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.service_messenger"
                                      }
                                    },
                                    [_vm._v("Messenger")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": "YES",
                                        "false-label": "NO"
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.service_liftgate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "service_liftgate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.service_liftgate"
                                      }
                                    },
                                    [_vm._v("Lift Gate")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": "YES",
                                        "false-label": "NO"
                                      },
                                      model: {
                                        value:
                                          _vm.ae_lot_info
                                            .service_inside_delivery,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "service_inside_delivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.service_inside_delivery"
                                      }
                                    },
                                    [_vm._v("Inside Delivery")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Customer Code:",
                                    prop: "customer_code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Customer Code",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.customer_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "customer_code",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.customer_code"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Need Escort:",
                                    prop: "escort_needed",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.ae_lot_info.escort_needed,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "escort_needed",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.escort_needed"
                                      }
                                    },
                                    _vm._l(_vm.escort_neededOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Freight Charges:",
                                    prop: "freight_charges",
                                    "label-width": ""
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.ae_lot_info.freight_charges,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "freight_charges",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.freight_charges"
                                      }
                                    },
                                    _vm._l(_vm.freight_chargesOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Pallet Exchange:",
                                    prop: "pallet_exchange_qty"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Enter Qty",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.pallet_exchange_qty,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "pallet_exchange_qty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.pallet_exchange_qty"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [_c("el-row")], 1),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Pickup From :",
                                    prop: "pickup_from_contact_id"
                                  }
                                },
                                [
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          placeholder: "Search Contact",
                                          "remote-method": function(query) {
                                            _vm.remoteSearchContact(
                                              query,
                                              "all"
                                            )
                                          },
                                          loading: _vm.pickupfromLoading,
                                          "loading-text": "Searching...",
                                          "no-data-text": "Result not found."
                                        },
                                        on: { change: _vm.changePickupfrom },
                                        model: {
                                          value:
                                            _vm.ae_lot_info
                                              .pickup_from_contact_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "pickup_from_contact_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ae_lot_info.pickup_from_contact_id"
                                        }
                                      },
                                      _vm._l(
                                        _vm.remoteContactListCnee,
                                        function(item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                label: item.short_name,
                                                value: item.id
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { float: "left" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.short_name)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "13px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.company))]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 15 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Pickup Company:",
                                    prop: "pickup_from_company"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Pickup From Company",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.pickup_from_company,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "pickup_from_company",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.pickup_from_company"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Pickup Address:",
                                    prop: "pickup_from_address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Pickup Address",
                                      type: "textarea",
                                      autosize: { minRows: 3, maxRows: 3 }
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.pickup_from_address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "pickup_from_address",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.pickup_from_address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Delivery To:",
                                    prop: "delivery_to_contact_id"
                                  }
                                },
                                [
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          placeholder: "Search CNEE",
                                          "remote-method": function(query) {
                                            _vm.remoteSearchContact(
                                              query,
                                              "cnee"
                                            )
                                          },
                                          loading: _vm.cneeLoading,
                                          "loading-text": "Searching...",
                                          "no-data-text": "Result not found."
                                        },
                                        on: { change: _vm.changeCnee },
                                        model: {
                                          value:
                                            _vm.ae_lot_info
                                              .delivery_to_contact_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "delivery_to_contact_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ae_lot_info.delivery_to_contact_id"
                                        }
                                      },
                                      _vm._l(
                                        _vm.remoteContactListCnee,
                                        function(item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                label: item.short_name,
                                                value: item.id
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { float: "left" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.short_name)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "13px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.company))]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 15 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Delivery Company:",
                                    prop: "delivery_to_company"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Delivery To Company",
                                      clearable: ""
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.delivery_to_company,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "delivery_to_company",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.delivery_to_company"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Delivery Address:",
                                    prop: "delivery_to_address"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Delivery To Address",
                                      type: "textarea",
                                      autosize: { minRows: 3, maxRows: 3 }
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.delivery_to_address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "delivery_to_address",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.delivery_to_address"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Delivery Zipcode:",
                                    prop: "delivery_zipcode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Delivery Zipcode",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.delivery_zipcode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "delivery_zipcode",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.delivery_zipcode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label-text", attrs: { span: 12 } },
                            [
                              _vm._v(" Packages "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        icon: "el-icon-plus"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.package_list_new_line(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Add Packages")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "padding-top": "0px",
                            "padding-left": "30px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    "highlight-current-row": "",
                                    data: _vm.ae_lot_info.package_list_data,
                                    "empty-text": "Empty...",
                                    stripe: "",
                                    fit: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packages",
                                      label: "Packages",
                                      width: "180"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.packages,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "packages",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.packages"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goods_description",
                                      label:
                                        "DESCRIPTION OF ARTICLES. SPECIAL MARKS & EXCEPTIONS",
                                      width: "540"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: { type: "textarea" },
                                              model: {
                                                value:
                                                  scope.row.goods_description,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "goods_description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.goods_description"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "weight",
                                      label: "Weight",
                                      width: "200"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input-number", {
                                              staticStyle: { width: "95px" },
                                              attrs: {
                                                min: 0,
                                                controls: false,
                                                precision: 2
                                              },
                                              model: {
                                                value: scope.row.weight,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "weight",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.weight"
                                              }
                                            }),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: {
                                                  width: "80px",
                                                  "padding-left": "5px"
                                                },
                                                attrs: {
                                                  placeholder: "Unit",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: scope.row.weight_unit,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "weight_unit",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.weight_unit"
                                                }
                                              },
                                              _vm._l(
                                                _vm.packing_weight_unitOptions,
                                                function(item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                      disabled: item.disabled
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "OP" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-delete",
                                                type: "danger",
                                                circle: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deletePackingListRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "padding-top": "20px" },
                          attrs: { label: "Remarks:", prop: "remarks" }
                        },
                        [
                          _c("el-input", {
                            style: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 3 }
                            },
                            model: {
                              value: _vm.ae_lot_info.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.ae_lot_info, "remarks", $$v)
                              },
                              expression: "ae_lot_info.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "large" }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !_vm.lotCanEdit,
                                type: "primary"
                              },
                              on: { click: _vm.submitForm }
                            },
                            [_vm._v("Submit")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "packingForm",
          attrs: {
            title: _vm.packingDialogStatus + " Container Detail",
            visible: _vm.dialogPackingFormVisible,
            "append-to-body": "",
            "custom-class": "dialogStyle"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPackingFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.packingRowForm, "label-width": "150px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Container #:", prop: "container_no" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.container_no,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_no",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Seal #:", prop: "seal_no" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.packingRowForm.seal_no,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "seal_no", $$v)
                              },
                              expression: "packingRowForm.seal_no"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Size/Type:",
                            prop: "container_size_type"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.packingRowForm.container_size_type,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.packingRowForm,
                                    "container_size_type",
                                    $$v
                                  )
                                },
                                expression: "packingRowForm.container_size_type"
                              }
                            },
                            _vm._l(_vm.container_size_typeOptions, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Marks & Nos. :",
                            prop: "container_marks"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.packingRowForm.container_marks,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "container_marks",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.container_marks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Packages:", prop: "packages" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            model: {
                              value: _vm.packingRowForm.packages,
                              callback: function($$v) {
                                _vm.$set(_vm.packingRowForm, "packages", $$v)
                              },
                              expression: "packingRowForm.packages"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-row"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Description of Packages Goods:",
                    prop: "goods_description"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "430px" },
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.packingRowForm.goods_description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packingRowForm,
                                  "goods_description",
                                  $$v
                                )
                              },
                              expression: "packingRowForm.goods_description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogPackingFormVisible = false
                    }
                  }
                },
                [_vm._v("discard")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handlePackingRowConfirm()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }