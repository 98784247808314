import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var department_config = {
  id: 4,
  name: 'Ocean Import',
  prefix: 'MOI',
  route_path: 'sea_import',
  api_path: 'sea_import',
  inv_master_name: 'MBL',
  inv_house_name: 'HBL'
};
var seaImport = {
  path: '/sea_import',
  component: Layout,
  redirect: 'noRedirect',
  name: 'OceanImport',
  alwaysShow: true,
  meta: {
    title: 'OceanImport',
    icon: 'el-icon-ship',
    roles: ['Admin', 'General Manager', 'Accountant', 'SeaImport Manager', 'SeaImport OP', 'SeaImport Warehouse', 'OP-All'] // permissions: ['view menu '],

  },
  children: [
  /** User managements */
  // {
  //   path: 'test_view',
  //   name: 'Test Page',
  //   component: () => export('@/views/_test'),
  //   meta: { title: 'Test Page', icon: '' }
  // },       
  {
    path: 'index',
    name: 'Lot List',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_sea_import/LotList'));
      });
    },
    meta: {
      title: 'Lot List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport index']
    }
  }, {
    path: 'invoice_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_sea_import/InvoiceList'));
      });
    },
    name: 'Invoice List',
    meta: {
      title: 'Invoice List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport invoices']
    }
  }, {
    path: 'cost_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_sea_import/CostList'));
      });
    },
    name: 'Cost List',
    meta: {
      title: 'Cost List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu seaimport costs']
    }
  } // {
  //   path: 'pricing_list',
  //   component: () => import('@/views/dep_sea_import/PricingList'),
  //   name: 'IW List',
  //   meta: { title: 'IW List', department_config:department_config ,  icon: '', permissions: ['view menu customsbroker pricings'] },
  // },
  // {
  //   path: 'manifest',
  //   component: () => import('@/views/dep_sea_import/Manifest'),
  //   name: 'Manifest',
  //   meta: { title: 'Manifest', department_config:department_config ,  icon: '' },
  //   hidden: true
  // },
  ]
};
export default seaImport;