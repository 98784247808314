var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.listMode == "left" || _vm.listMode == "hide"
        ? _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      style:
                        "width:" +
                        _vm.ViewerLeftWidth +
                        ";display:" +
                        _vm.ViewerLeftDisplay +
                        ";",
                      attrs: { id: "object-viewer-left-tmp" }
                    },
                    [
                      _c(
                        "el-header",
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple yip-header"
                                  },
                                  [_c("span", [_vm._v("LOT List ")])]
                                )
                              ]),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { span: 16 }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid-content bg-purple-light"
                                    },
                                    [
                                      _c("el-button", {
                                        staticStyle: { margin: "0 5px" },
                                        attrs: {
                                          icon: "el-icon-refresh",
                                          size: "medium",
                                          plain: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchLotList()
                                          }
                                        }
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "font-size": "22px",
                                            "padding-left": "5px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleSetListMode(
                                                "hide"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-arrow-left",
                                            attrs: { size: "large" }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-scrollbar",
                        {
                          staticClass: "frame-body-scrollbar-left",
                          staticStyle: { "padding-left": "12px" }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading"
                                }
                              ],
                              attrs: {
                                data: _vm.lotList,
                                "empty-text": "Empty..."
                              },
                              on: { "row-click": _vm.handleObjectRowClick }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "LOT#",
                                  width: "100"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(slotProps) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(slotProps.row.lot_prefix) +
                                              "-" +
                                              _vm._s(slotProps.row.id) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1494532136
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "master_no",
                                  label: "OBL #",
                                  width: ""
                                }
                              }),
                              _vm.listMode == "full"
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "created_at",
                                      label: "Create Date",
                                      width: "160"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(slotProps) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatDateTime")(
                                                      slotProps.row.created_at
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2395514668
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "10px" } },
                            [
                              _c("el-pagination", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.total > 0,
                                    expression: "total > 0"
                                  }
                                ],
                                attrs: {
                                  background: "",
                                  layout: "prev, pager, next",
                                  total: _vm.total,
                                  "pager-count": 5,
                                  "current-page": _vm.listRequestParams.page,
                                  "page-size": _vm.listRequestParams.limit
                                },
                                on: {
                                  "size-change": function($event) {
                                    return _vm.fetchLotList()
                                  },
                                  "update:currentPage": function($event) {
                                    return _vm.$set(
                                      _vm.listRequestParams,
                                      "page",
                                      $event
                                    )
                                  },
                                  "update:current-page": function($event) {
                                    return _vm.$set(
                                      _vm.listRequestParams,
                                      "page",
                                      $event
                                    )
                                  },
                                  "current-change": function($event) {
                                    return _vm.fetchLotList()
                                  },
                                  "update:pageSize": function($event) {
                                    return _vm.$set(
                                      _vm.listRequestParams,
                                      "limit",
                                      $event
                                    )
                                  },
                                  "update:page-size": function($event) {
                                    return _vm.$set(
                                      _vm.listRequestParams,
                                      "limit",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      style: "width:calc(100% - " + _vm.ViewerLeftWidth + ");",
                      attrs: { id: "object-viewer-right-tmp" }
                    },
                    [
                      _c("lot-details", {
                        attrs: {
                          listMode: _vm.listMode,
                          viewObjectId: _vm.viewLotId
                        },
                        on: {
                          "update:listMode": function($event) {
                            _vm.listMode = $event
                          },
                          "update:list-mode": function($event) {
                            _vm.listMode = $event
                          },
                          "update:viewObjectId": function($event) {
                            _vm.viewLotId = $event
                          },
                          "update:view-object-id": function($event) {
                            _vm.viewLotId = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {},
                    [
                      _c(
                        "el-header",
                        { staticStyle: { "padding-right": "0px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-content bg-purple yip-header"
                                  },
                                  [_c("span", [_vm._v("LOT List ")])]
                                )
                              ]),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { span: 20 }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid-content bg-purple-light"
                                    },
                                    [
                                      _c("yip-table-filter", {
                                        attrs: {
                                          filterConfig: _vm.tableFilterConfig
                                        },
                                        on: {
                                          "update:filterConfig": function(
                                            $event
                                          ) {
                                            _vm.tableFilterConfig = $event
                                          },
                                          "update:filter-config": function(
                                            $event
                                          ) {
                                            _vm.tableFilterConfig = $event
                                          },
                                          updateTableFilterParams:
                                            _vm.getTableFilterParams,
                                          submitForm: _vm.fetchLotList
                                        }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px"
                                          },
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            size: "medium",
                                            plain: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.newLot()
                                            }
                                          }
                                        },
                                        [_vm._v("Create OBL")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {},
                            [
                              _c(
                                "el-scrollbar",
                                {
                                  staticClass: "frame-body-scrollbar",
                                  staticStyle: { margin: "10px" }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.listLoading,
                                          expression: "listLoading"
                                        }
                                      ],
                                      attrs: {
                                        data: _vm.lotList,
                                        border: "",
                                        "empty-text": "Empty..."
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "id",
                                          label: "LOT#",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(slotProps) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      slotProps.row.lot_prefix
                                                    ) +
                                                    "-" +
                                                    _vm._s(slotProps.row.id) +
                                                    " "
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "master_no",
                                          label: "OBL #",
                                          width: "180"
                                        }
                                      }),
                                      _vm.listMode == "full"
                                        ? [
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "houses_count",
                                                label: "Houses",
                                                width: "80"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(slotProps) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              slotProps.row
                                                                .houses_count
                                                                ? slotProps.row
                                                                    .houses_count
                                                                : 0
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3976864520
                                              )
                                            }),
                                            _vm.roles.includes("Admin") ||
                                            _vm.roles.includes(
                                              "General Manager"
                                            ) ||
                                            _vm.roles.includes("Accountant")
                                              ? [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "invoice_amount",
                                                      label: "Invoice Amt",
                                                      width: "105"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    slotProps
                                                                      .row
                                                                      .invoice_amount >
                                                                      0
                                                                      ? "$" +
                                                                          slotProps
                                                                            .row
                                                                            .invoice_amount
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3379374937
                                                    )
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "cost_amount",
                                                      label: "Cost Amt",
                                                      width: "95"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    slotProps
                                                                      .row
                                                                      .cost_amount >
                                                                      0
                                                                      ? "$" +
                                                                          slotProps
                                                                            .row
                                                                            .cost_amount
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3897709401
                                                    )
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "lot_profit",
                                                      label: "Profit",
                                                      width: "95"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              slotProps.row
                                                                .lot_profit > 0
                                                                ? [
                                                                    _vm._v(
                                                                      " $" +
                                                                        _vm._s(
                                                                          slotProps
                                                                            .row
                                                                            .lot_profit
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                : slotProps.row
                                                                    .lot_profit <
                                                                  0
                                                                ? [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "red"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-$" +
                                                                            _vm._s(
                                                                              Math.abs(
                                                                                slotProps
                                                                                  .row
                                                                                  .lot_profit
                                                                              )
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2024391038
                                                    )
                                                  })
                                                ]
                                              : [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "invoices_count",
                                                      label: "Invoices",
                                                      width: "80"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    slotProps
                                                                      .row
                                                                      .invoices_count
                                                                      ? slotProps
                                                                          .row
                                                                          .invoices_count
                                                                      : 0
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1071213608
                                                    )
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "expenses_count",
                                                      label: "Costs",
                                                      width: "70"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    slotProps
                                                                      .row
                                                                      .expenses_count
                                                                      ? slotProps
                                                                          .row
                                                                          .expenses_count
                                                                      : 0
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3119620488
                                                    )
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "lot_profit",
                                                      label: "Profit",
                                                      width: "95"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              slotProps.row
                                                                .lot_profit > 0
                                                                ? [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "green"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Positive"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                : slotProps.row
                                                                    .lot_profit <
                                                                  0
                                                                ? [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "red"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Negative"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      " 0 "
                                                                    )
                                                                  ]
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3933198340
                                                    )
                                                  })
                                                ],
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "seaport_departure",
                                                label: "From",
                                                width: "80"
                                              }
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "seaport_destination",
                                                label: "To",
                                                width: "80"
                                              }
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "departure_date",
                                                label: "Depart Date",
                                                width: "110"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(slotProps) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatDate"
                                                              )(
                                                                slotProps.row
                                                                  .departure_date
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2712813868
                                              )
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm.cols_show.includes("creator")
                                        ? _c("el-table-column", {
                                            attrs: {
                                              width: "120px",
                                              align: "",
                                              label: "Created By"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            slotProps.row
                                                              .creator
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1853723585
                                            )
                                          })
                                        : _vm._e(),
                                      _vm.listMode == "full"
                                        ? _c("el-table-column", {
                                            attrs: {
                                              prop: "created_at",
                                              label: "Created At",
                                              width: "140"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDateTime"
                                                            )(
                                                              slotProps.row
                                                                .created_at
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2395514668
                                            )
                                          })
                                        : _vm._e(),
                                      _vm.listMode != "hide"
                                        ? _c("el-table-column", {
                                            attrs: {
                                              prop: "closed_stage",
                                              label: "Status",
                                              width: "120"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _c("stage-status-tag", {
                                                        attrs: {
                                                          status:
                                                            slotProps.row
                                                              .closed_stage
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4265762390
                                            )
                                          })
                                        : _vm._e(),
                                      _vm.listMode == "full"
                                        ? _c("el-table-column", {
                                            attrs: { label: "Actions" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              _vm.page_paths
                                                                .index +
                                                              "?listMode=left&id=" +
                                                              slotProps.row.id +
                                                              ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px"
                                                              },
                                                              attrs: {
                                                                type: "success",
                                                                size: "small",
                                                                icon:
                                                                  "el-icon-view",
                                                                plain: ""
                                                              }
                                                            },
                                                            [_vm._v(" Edit ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              151828412
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "10px" } },
                        [
                          _c(
                            "el-pagination",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.total > 0,
                                  expression: "total > 0"
                                }
                              ],
                              attrs: {
                                background: "",
                                layout: "slot,sizes, prev, pager, next",
                                total: _vm.total,
                                "page-sizes": [10, 15, 20, 30, 40, 50],
                                "current-page": _vm.listRequestParams.page,
                                "page-size": _vm.listRequestParams.limit
                              },
                              on: {
                                "size-change": function($event) {
                                  return _vm.fetchLotList()
                                },
                                "update:currentPage": function($event) {
                                  return _vm.$set(
                                    _vm.listRequestParams,
                                    "page",
                                    $event
                                  )
                                },
                                "update:current-page": function($event) {
                                  return _vm.$set(
                                    _vm.listRequestParams,
                                    "page",
                                    $event
                                  )
                                },
                                "current-change": function($event) {
                                  return _vm.fetchLotList()
                                },
                                "update:pageSize": function($event) {
                                  return _vm.$set(
                                    _vm.listRequestParams,
                                    "limit",
                                    $event
                                  )
                                },
                                "update:page-size": function($event) {
                                  return _vm.$set(
                                    _vm.listRequestParams,
                                    "limit",
                                    $event
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "20px" } },
                                [_vm._v(" Total Records: " + _vm._s(_vm.total))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "1020px",
            "close-on-press-escape": false,
            wrapperClosable: false,
            visible: _vm.lotNewEditdrawer,
            "with-header": false,
            "destroy-on-close": true
          }
        },
        [
          _c("lot-new-edit", {
            key: _vm.drawerRandomKey,
            attrs: { objectId: _vm.lotIdInit },
            on: { closeDrawer: _vm.closeDrawer }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }