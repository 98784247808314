import { debounce } from '@/utils';
export default {
  data: function data() {
    return {
      $_sidebarElm: null
    };
  },
  mounted: function mounted() {
    this.$_initResizeEvent();
    this.$_initSidebarResizeEvent();
  },
  beforeDestroy: function beforeDestroy() {
    this.$_destroyResizeEvent();
    this.$_destroySidebarResizeEvent();
  },
  // to fixed bug when cached by keep-alive
  // https://github.com/PanJiaChen/vue-element-admin/issues/2116
  activated: function activated() {
    this.$_initResizeEvent();
    this.$_initSidebarResizeEvent();
  },
  deactivated: function deactivated() {
    this.$_destroyResizeEvent();
    this.$_destroySidebarResizeEvent();
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_resizeHandler: function $_resizeHandler() {
      var _this = this;

      return debounce(function () {
        if (_this.chart) {
          _this.chart.resize();
        }
      }, 100)();
    },
    $_initResizeEvent: function $_initResizeEvent() {
      window.addEventListener('resize', this.$_resizeHandler);
    },
    $_destroyResizeEvent: function $_destroyResizeEvent() {
      window.removeEventListener('resize', this.$_resizeHandler);
    },
    $_sidebarResizeHandler: function $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.$_resizeHandler();
      }
    },
    $_initSidebarResizeEvent: function $_initSidebarResizeEvent() {
      this.$_sidebarElm = document.getElementsByClassName('sidebar-container')[0];
      this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler);
    },
    $_destroySidebarResizeEvent: function $_destroySidebarResizeEvent() {
      this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler);
    }
  }
};