var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticStyle: { margin: "0px 0px" } },
      [
        _c(
          "div",
          {
            class: _vm.showFilter
              ? _vm.frameHeader
              : _vm.frameHeaderWithoutFilter
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _vm.showFilter
                      ? [
                          _vm._l(_vm.quickFilterConfig, function(qf) {
                            return [
                              qf.el_type == "radio"
                                ? _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "filter-bt-group",
                                      attrs: { size: "medium" },
                                      on: { change: _vm.fetchDeliveryList },
                                      model: {
                                        value: qf.set_value,
                                        callback: function($$v) {
                                          _vm.$set(qf, "set_value", $$v)
                                        },
                                        expression: "qf.set_value"
                                      }
                                    },
                                    [
                                      _vm._l(qf.option_list, function(opt) {
                                        return [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: opt.value } },
                                            [_vm._v(_vm._s(opt.name))]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : qf.el_type == "input"
                                ? _c("el-input", {
                                    staticStyle: { padding: "0 15px" },
                                    style: { width: qf.width + "px" },
                                    attrs: {
                                      size: "medium",
                                      placeholder: qf.show_name
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.fetchDeliveryList($event)
                                      }
                                    },
                                    model: {
                                      value: qf.set_value,
                                      callback: function($$v) {
                                        _vm.$set(qf, "set_value", $$v)
                                      },
                                      expression: "qf.set_value"
                                    }
                                  })
                                : qf.el_type == "date_range"
                                ? _c("el-date-picker", {
                                    staticClass: "date-picker-class",
                                    attrs: {
                                      size: "medium",
                                      type: "daterange",
                                      "range-separator": "-",
                                      "start-placeholder": "",
                                      "end-placeholder": "",
                                      format: "MM/dd/yyyy",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: "",
                                      "start-placeholder": "Delivery Day From",
                                      "end-placeholder": "Delivery Day To"
                                    },
                                    model: {
                                      value: qf.set_value,
                                      callback: function($$v) {
                                        _vm.$set(qf, "set_value", $$v)
                                      },
                                      expression: "qf.set_value"
                                    }
                                  })
                                : _vm._e()
                            ]
                          }),
                          _c("yip-contact-remote", {
                            staticStyle: { "padding-left": "10px" },
                            attrs: {
                              placeholder: "Customer",
                              contact_type: "customer",
                              input_width: "200"
                            },
                            model: {
                              value: _vm.filter_customer_id,
                              callback: function($$v) {
                                _vm.filter_customer_id = $$v
                              },
                              expression: "filter_customer_id"
                            }
                          })
                        ]
                      : _vm._e(),
                    _c("el-button", {
                      class: _vm.showFilter ? "" : _vm.refreshBtnWithoutFilter,
                      staticStyle: { "margin-left": "5px" },
                      attrs: {
                        size: "medium",
                        type: "primary",
                        icon: "el-icon-refresh",
                        plain: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.fetchDeliveryList()
                        }
                      }
                    }),
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { "padding-left": "10px" },
                        on: {
                          change: function($event) {
                            return _vm.handleSwitchCompactMode()
                          }
                        },
                        model: {
                          value: _vm.isCompactMode,
                          callback: function($$v) {
                            _vm.isCompactMode = $$v
                          },
                          expression: "isCompactMode"
                        }
                      },
                      [_vm._v("Compact Mode")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { "padding-left": "10px" },
                        on: {
                          change: function($event) {
                            return _vm.handleEditAll()
                          }
                        },
                        model: {
                          value: _vm.isEditAll,
                          callback: function($$v) {
                            _vm.isEditAll = $$v
                          },
                          expression: "isEditAll"
                        }
                      },
                      [_vm._v("Edit All")]
                    ),
                    _c(
                      "span",
                      { staticClass: "frame-title" },
                      [
                        _vm.showNewButton
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-right": "5px" },
                                  attrs: {
                                    disabled:
                                      _vm.lotInfo.closed_stage != "ACTIVE",
                                    type: "primary",
                                    icon: "el-icon-plus",
                                    size: "medium",
                                    plain: ""
                                  },
                                  on: { click: _vm.handleNewInvoice }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.superHouseId > 0
                                        ? "New Invoice"
                                        : "New Master Invoice"
                                    )
                                  )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.showTitle
                          ? _c(
                              "div",
                              {
                                staticClass: "yip-header",
                                staticStyle: { "margin-right": "5px" }
                              },
                              [_vm._v(" Delivery Invoicing ")]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.deliveryList,
                      border: "",
                      fit: "",
                      "empty-text": "Empty..."
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "", label: "DO#", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { width: "150px", align: "", label: "Customer" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              _vm._v(
                                " " + _vm._s(slotProps.row.customer_name) + " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    !_vm.isCompactMode
                      ? _c("el-table-column", {
                          attrs: { align: "", label: "Destination" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          slotProps.row.delivery_to_company
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            813639837
                          )
                        })
                      : _vm._e(),
                    !_vm.isCompactMode
                      ? _c("el-table-column", {
                          attrs: { align: "", label: "Inv Amt" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(slotProps.row.invoice_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3365961915
                          )
                        })
                      : _vm._e(),
                    !_vm.isCompactMode
                      ? _c("el-table-column", {
                          attrs: { align: "", label: "Cost Amt" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(slotProps) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(slotProps.row.cost_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1116750241
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Total KG", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(slotProps.row.total_weight_kg) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "C.O.D", width: "70" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.service_cod != "None"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Zipcode", width: "95" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-input", {
                                      staticStyle: { width: "73px" },
                                      attrs: { size: "small" },
                                      model: {
                                        value: slotProps.row.delivery_zipcode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "delivery_zipcode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.delivery_zipcode"
                                      }
                                    })
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.delivery_zipcode) +
                                        " "
                                    )
                                  ]
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Escort", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-input", {
                                      staticStyle: { width: "58px" },
                                      attrs: { size: "small" },
                                      model: {
                                        value: slotProps.row.escort_hours,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "escort_hours",
                                            $$v
                                          )
                                        },
                                        expression: "slotProps.row.escort_hours"
                                      }
                                    }),
                                    _vm._v(" hrs ")
                                  ]
                                : slotProps.row.escort_hours > 0
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.escort_hours) +
                                        " hrs "
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Mistrip", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-text": "Y",
                                        "inactive-text": "N",
                                        "active-value": "YES",
                                        "inactive-value": "NO"
                                      },
                                      model: {
                                        value: slotProps.row.service_mistrip,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "service_mistrip",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.service_mistrip"
                                      }
                                    })
                                  ]
                                : slotProps.row.service_mistrip == "YES"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "LiftGate", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-text": "Y",
                                        "inactive-text": "N",
                                        "active-value": "YES",
                                        "inactive-value": "NO"
                                      },
                                      model: {
                                        value: slotProps.row.service_liftgate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "service_liftgate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.service_liftgate"
                                      }
                                    })
                                  ]
                                : slotProps.row.service_liftgate == "YES"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Messenger", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-text": "Y",
                                        "inactive-text": "N",
                                        "active-value": "YES",
                                        "inactive-value": "NO"
                                      },
                                      model: {
                                        value: slotProps.row.service_messenger,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "service_messenger",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.service_messenger"
                                      }
                                    })
                                  ]
                                : slotProps.row.service_messenger == "YES"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Inside Deliv", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-text": "Y",
                                        "inactive-text": "N",
                                        "active-value": "YES",
                                        "inactive-value": "NO"
                                      },
                                      model: {
                                        value:
                                          slotProps.row.service_inside_delivery,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "service_inside_delivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.service_inside_delivery"
                                      }
                                    })
                                  ]
                                : slotProps.row.service_inside_delivery == "YES"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Total Boxes", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.to_shipping_carrier != "NONE"
                                ? [
                                    slotProps.row.editable
                                      ? [
                                          _c("el-input", {
                                            staticStyle: { width: "55px" },
                                            attrs: { size: "small" },
                                            model: {
                                              value: slotProps.row.total_boxes,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  slotProps.row,
                                                  "total_boxes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "slotProps.row.total_boxes"
                                            }
                                          })
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                slotProps.row.total_boxes
                                              ) +
                                              " "
                                          )
                                        ]
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Waiting Time", width: "110" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-input", {
                                      staticStyle: { width: "58px" },
                                      attrs: { size: "small" },
                                      model: {
                                        value: slotProps.row.waiting_hours,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "waiting_hours",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.waiting_hours"
                                      }
                                    }),
                                    _vm._v(" hrs ")
                                  ]
                                : slotProps.row.waiting_hours > 0
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(slotProps.row.waiting_hours) +
                                        " hrs "
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "After Hour", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.editable
                                ? [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-text": "Y",
                                        "inactive-text": "N",
                                        "active-value": "YES",
                                        "inactive-value": "NO"
                                      },
                                      model: {
                                        value:
                                          slotProps.row.after_hour_delivery,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "after_hour_delivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.after_hour_delivery"
                                      }
                                    })
                                  ]
                                : slotProps.row.after_hour_delivery == "YES"
                                ? [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("Yes")]
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "OT Waiting", width: "105" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.after_hour_delivery == "YES"
                                ? [
                                    slotProps.row.editable
                                      ? [
                                          _c("el-input", {
                                            staticStyle: { width: "58px" },
                                            attrs: { size: "small" },
                                            model: {
                                              value:
                                                slotProps.row.ot_waiting_hours,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  slotProps.row,
                                                  "ot_waiting_hours",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "slotProps.row.ot_waiting_hours"
                                            }
                                          }),
                                          _vm._v(" hrs ")
                                        ]
                                      : slotProps.row.ot_waiting_hours > 0
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                slotProps.row.ot_waiting_hours
                                              ) +
                                              " hrs "
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "", label: "Actions", width: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(slotProps) {
                            return [
                              slotProps.row.updating
                                ? [_vm._v(" Updating... ")]
                                : [
                                    slotProps.row.editable &&
                                    slotProps.row.closed_stage == "ACTIVE"
                                      ? [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                icon: "el-icon-check",
                                                type: "success"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleUpdateDelivery(
                                                    slotProps.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Save")]
                                          ),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "margin-left": "8px"
                                              },
                                              attrs: {
                                                icon: "el-icon-refresh-left",
                                                type: "warning"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleDiscardDelivery(
                                                    slotProps.$index,
                                                    slotProps.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Discard")]
                                          )
                                        ]
                                      : [
                                          slotProps.row.closed_stage == "ACTIVE"
                                            ? [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      icon: "el-icon-edit",
                                                      type: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleEditDelivery(
                                                          slotProps.$index,
                                                          slotProps.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Edit")]
                                                ),
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "10px",
                                                      "font-size": "20px"
                                                    },
                                                    attrs: { type: "success" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleShowInvoicingDrawer(
                                                          slotProps.$index,
                                                          slotProps.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-money"
                                                    })
                                                  ]
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#e6a23c",
                                                      "margin-left": "8px"
                                                    }
                                                  },
                                                  [_vm._v("Closed")]
                                                )
                                              ]
                                        ]
                                  ]
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "frame-footer" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-pagination",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0"
                          }
                        ],
                        attrs: {
                          background: "",
                          layout: "slot,sizes, prev, pager, next",
                          total: _vm.total,
                          "page-sizes": [10, 15, 20, 30, 40, 50],
                          "current-page": _vm.listRequestParams.page,
                          "page-size": _vm.listRequestParams.limit
                        },
                        on: {
                          "size-change": function($event) {
                            return _vm.fetchDeliveryList()
                          },
                          "update:currentPage": function($event) {
                            return _vm.$set(
                              _vm.listRequestParams,
                              "page",
                              $event
                            )
                          },
                          "update:current-page": function($event) {
                            return _vm.$set(
                              _vm.listRequestParams,
                              "page",
                              $event
                            )
                          },
                          "current-change": function($event) {
                            return _vm.fetchDeliveryList()
                          },
                          "update:pageSize": function($event) {
                            return _vm.$set(
                              _vm.listRequestParams,
                              "limit",
                              $event
                            )
                          },
                          "update:page-size": function($event) {
                            return _vm.$set(
                              _vm.listRequestParams,
                              "limit",
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "20px" } },
                          [_vm._v(" Total Records: " + _vm._s(_vm.total))]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-drawer",
          {
            attrs: {
              size: "960px",
              wrapperClosable: false,
              visible: _vm.invoicing_drawer,
              "with-header": false,
              "destroy-on-close": true
            },
            on: {
              "update:visible": function($event) {
                _vm.invoicing_drawer = $event
              }
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "14px 15px",
                  "border-bottom": "1px solid #dfe6ec"
                }
              },
              [
                _c("el-page-header", {
                  attrs: { title: "Cancel", content: _vm.invoicingDrawerTitle },
                  on: { back: _vm.handleCloseDrawer }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.drawer_loading,
                    expression: "drawer_loading"
                  }
                ],
                staticClass: "demo-drawer__content"
              },
              [
                _c(
                  "div",
                  { staticStyle: { margin: "10px" } },
                  [
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "margin-top",
                        attrs: {
                          title: "Delivery Details",
                          column: 3,
                          size: "size",
                          border: ""
                        }
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Delivery #: ")
                            ]),
                            _vm._v(" DEL-" + _vm._s(_vm.delivery_info.id) + " ")
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Customer: ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.delivery_info.customer_name) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Destination: ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.delivery_info.delivery_to_company) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Route: ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.delivery_info.route) + " ")
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Packaging: ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.delivery_info.service_packaging) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Total KG: ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.delivery_info.total_weight_kg) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" C.O.D: ")
                            ]),
                            _vm.delivery_info.service_cod != "None"
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Zipcode: ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.delivery_info.delivery_zipcode) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Escort: ")
                            ]),
                            _vm.delivery_info.escort_hours > 0
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.delivery_info.escort_hours) +
                                      " hrs "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Mistrip: ")
                            ]),
                            _vm.delivery_info.service_mistrip == "YES"
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Messenger: ")
                            ]),
                            _vm.delivery_info.service_messenger == "YES"
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Inside Delivery: ")
                            ]),
                            _vm.delivery_info.service_inside_delivery == "YES"
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("Total Boxes: ")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.delivery_info.total_boxes) + " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("Waiting Time: ")
                            ]),
                            _vm.delivery_info.waiting_hours > 0
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.delivery_info.waiting_hours) +
                                      " hrs "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" After Hour: ")
                            ]),
                            _vm.delivery_info.after_hour_delivery == "YES"
                              ? [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "mini", type: "success" }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" OT Waiting: ")
                            ]),
                            _vm.delivery_info.ot_waiting_hours > 0
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.delivery_info.ot_waiting_hours
                                      ) +
                                      " hrs "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c(
                              "template",
                              { attrs: { span: 2 }, slot: "label" },
                              [_vm._v(" Pallet Exchange: ")]
                            ),
                            _vm.delivery_info.pallet_exchange_qty > 0
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.delivery_info.pallet_exchange_qty
                                      ) +
                                      " plts "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [_c("template", { slot: "label" })],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" Description: ")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.delivery_info.remarks) + " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "template",
                          { slot: "extra" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleGenDeliveryInvoiceCost(
                                      _vm.delivery_info.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("Generate Invoice / Cost")]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { padding: "10px" } },
                  [
                    _vm.pricingInfo.pricing_gen_id > 0
                      ? [
                          _c("h4", [_vm._v("Invoice / Cost List")]),
                          _vm.invoiceList.length > 0
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.invoiceList,
                                            border: "",
                                            fit: ""
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "130px",
                                              align: "",
                                              label: "Payer"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            slotProps.row
                                                              .customer_name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4046200065
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "88px",
                                              align: "",
                                              label: "Amount"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " $" +
                                                          _vm._s(
                                                            slotProps.row
                                                              .total_amount
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              519996872
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              label: "Service"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticStyle: {
                                                            margin: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            slotProps.row
                                                              .invoiceitems,
                                                            function(service) {
                                                              return [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " $" +
                                                                      _vm._s(
                                                                        service.amount
                                                                      ) +
                                                                      " : " +
                                                                      _vm._s(
                                                                        service.name
                                                                      ) +
                                                                      " [ " +
                                                                      _vm._s(
                                                                        service.service_desc
                                                                      ) +
                                                                      "] "
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2583008341
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.costList.length > 0
                            ? _c(
                                "el-row",
                                { staticStyle: { "padding-top": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.costList,
                                            border: "",
                                            fit: ""
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "130px",
                                              align: "",
                                              label: "Vendor"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            slotProps.row
                                                              .payee_name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3906630925
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "88px",
                                              align: "",
                                              label: "Amount"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._v(
                                                        " $" +
                                                          _vm._s(
                                                            slotProps.row
                                                              .total_amount
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              519996872
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "",
                                              label: "Service"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticStyle: {
                                                            margin: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            slotProps.row
                                                              .expenseitems,
                                                            function(service) {
                                                              return [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    " $" +
                                                                      _vm._s(
                                                                        service.amount
                                                                      ) +
                                                                      " : " +
                                                                      _vm._s(
                                                                        service.name
                                                                      ) +
                                                                      " [ " +
                                                                      _vm._s(
                                                                        service.expense_desc
                                                                      ) +
                                                                      " ] "
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4169133849
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.invoiceList.length > 0 || _vm.costList.length > 0
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        margin: "10px 0",
                                        "text-align": "right"
                                      },
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.submitBtnLoading,
                                              expression: "submitBtnLoading"
                                            }
                                          ],
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.handleSubmitInvoiceCost()
                                            }
                                          }
                                        },
                                        [_vm._v(" Submit Invoice/Cost ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }