import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import fileUploader from '@/api/fileupload';
export default {
  components: {
    InvoiceHub: function InvoiceHub() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/yipComponent/InvoiceHub"));
      });
    } // 动态加载组件

  },
  props: {
    // user: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: '',
    //       email: '',
    //       avatar: '',
    //       roles: [],
    //     };
    //   },
    // },
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    forceLoadData: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      inv_list_params: {
        superlot_id: 0,
        superhouse_id: 0,
        department: this.$route.meta.department_config.prefix
      },
      cols_show: ["id", "for_house_no", "qbo_invoice_no", "invoice_date", "due_date", "creator"] // forceLoadData:false,
      // inv_list_params:{}

    };
  },
  watch: {
    "lotInfo.superlot_id": function lotInfoSuperlot_id(val) {
      console.log("-----lotInfo.superlot_id----");
      console.log(val); // this.inv_list_params.superlot_id=val;

      this.$set(this.inv_list_params, "superlot_id", val);
    },
    forceLoadData: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(newV, oldV) {
        if (newV == true) {// console.log("---getting Invoices list...---");
          // this.fetchTabData()
        }
      }
    }
  },
  mounted: function mounted() {// console.log('**** this.forceLoadData in TabInvoices.vue ****')
    // console.log(this.lotInfo)
    // console.log(this.forceLoadData)
  },
  methods: {}
};