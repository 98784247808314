var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("cost-hub", {
        attrs: {
          showTitle: _vm.show_title,
          filterParams: _vm.cost_list_params,
          cols_show: _vm.cols_show,
          forceLoadData: _vm.forceLoadData
        },
        on: {
          "update:forceLoadData": function($event) {
            _vm.forceLoadData = $event
          },
          "update:force-load-data": function($event) {
            _vm.forceLoadData = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }