var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lotInfo.id > 0
    ? _c(
        "div",
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              staticStyle: {
                margin: "20px",
                width: "90%",
                "min-width": "600px"
              },
              attrs: {
                "show-message": false,
                model: _vm.form,
                "label-width": "160px"
              }
            },
            [
              _vm.form.id > 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Chargeable Weight:",
                                prop: "total_chr_weight"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.form.lotTotalChargeableWeight) +
                                  " lbs "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.id > 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Total Trailer Cost:",
                                prop: "total_trailer_cost"
                              }
                            },
                            [
                              _vm._v(
                                " $" +
                                  _vm._s(_vm.form.lotTotalTrailerCost) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Cost per lbs:",
                                prop: "cost_per_lbs"
                              }
                            },
                            [
                              _vm._v(
                                " $" + _vm._s(_vm.form.lotCostPerLbs) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.id > 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Total Master Income:",
                                prop: "total_trailer_income"
                              }
                            },
                            [
                              _vm._v(
                                " $" +
                                  _vm._s(_vm.form.lotTotalMasterIncome) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Income per lbs:",
                                prop: "income_per_lbs"
                              }
                            },
                            [
                              _vm._v(
                                " $" + _vm._s(_vm.form.lotIncomePerLbs) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.id > 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Total Invoice:",
                                prop: "total_invoice"
                              }
                            },
                            [
                              _vm._v(
                                " $" + _vm._s(_vm.form.lotTotalIncome) + " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Total Cost:",
                                prop: "total_cost"
                              }
                            },
                            [_vm._v(" $" + _vm._s(_vm.form.lotTotalCost) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Total Profit:",
                                prop: "total_profit"
                              }
                            },
                            [
                              _vm.form.lotTotalProfit > 0
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "green" } },
                                    [
                                      _vm._v(
                                        "$" + _vm._s(_vm.form.lotTotalProfit)
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        "$" + _vm._s(_vm.form.lotTotalProfit)
                                      )
                                    ]
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("h3", [_vm._v("Master Invoice/Cost List")])
                  ]),
                  _c("el-col", {
                    staticStyle: { "padding-top": "10px" },
                    attrs: { span: 6 }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "padding-top": "10px", "padding-left": "30px" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.lotInvoiceData,
                            "empty-text": "No Master Invoice List",
                            "row-key": "service_id",
                            "default-expand-all": "",
                            stripe: "",
                            fit: "",
                            "show-summary": "",
                            "summary-method": _vm.getLotInvoiceSummaries
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "payer_name",
                              label: "Payer",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(scope.row.payer_name) + " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1621944730
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "charge_name",
                              label: "Service",
                              width: "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "service_amount",
                              label: "Amount",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(amount) {
                                    return [
                                      _vm._v(
                                        " $" +
                                          _vm._s(amount.row.service_amount) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3908790935
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "service_desc",
                              label: "Description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "padding-top": "10px", "padding-left": "30px" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.lotCostData,
                            "empty-text": "No Master Cost List",
                            "row-key": "service_id",
                            "default-expand-all": "",
                            stripe: "",
                            fit: "",
                            "show-summary": "",
                            "summary-method": _vm.getLotCostSummaries
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "vendor_name",
                              label: "Vendor",
                              width: "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expense_name",
                              label: "Cost Item",
                              width: "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "Amount",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(amount) {
                                    return [
                                      _vm._v(
                                        " $" + _vm._s(amount.row.amount) + " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1654611029
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expense_service_desc",
                              label: "Description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("h3", [_vm._v("House list")])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "10px" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoading,
                          expression: "tableDataLoading"
                        }
                      ],
                      ref: "serviceTable",
                      staticStyle: { width: "95%" },
                      attrs: {
                        border: "",
                        "highlight-current-row": "",
                        data: _vm.houseInvoiceData,
                        "empty-text": "No Items",
                        "row-key": _vm.getRowKeys,
                        stripe: "",
                        fit: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "house_no",
                          label: "BOL #",
                          width: "80"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.row.house_no) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2414336487
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "pricing_id", label: "IW #" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href:
                                          "/tlm/pricing_new?id=" +
                                          scope.row.pricing_id,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " # " +
                                          _vm._s(scope.row.pricing_id) +
                                          " "
                                      ),
                                      _c("i", { staticClass: "el-icon-link" })
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3620016364
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "shipper_name", label: "Shipper" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.row.shipper_name) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2001589408
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cnee_name", label: "CNEE" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.row.cnee_name) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2984307688
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "payer_name", label: "Payer" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.row.payer_name) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1621944730
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "house_chargeable_weight",
                          label: "Chargeable LBS",
                          width: "130"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.house_chargeable_weight
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          918894185
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "house_invoice", label: "Income " },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.house_invoice > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moneyNum")(
                                                scope.row.house_invoice
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    : [_vm._v(" (N/A) ")]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2632223329
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "house_cost", label: "Cost " },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.house_cost > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moneyNum")(
                                                scope.row.house_cost
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    : [_vm._v(" (N/A) ")]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2538339713
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "house_profit", label: "Profit " },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.house_profit > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moneyNum")(
                                                scope.row.house_profit
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    : scope.row.house_profit < 0
                                    ? [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("moneyNum")(
                                                  scope.row.house_profit
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    : [_vm._v(" (N/A) ")]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2240593123
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }