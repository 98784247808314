//
//
//
//
//
//
export default {
  name: 'StageStatusTag',
  props: {
    status: {
      type: String,
      required: true
    }
  }
};