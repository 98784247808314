//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Resource from '@/api/resource';
var userResource = new Resource('users');
export default {
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: []
        };
      }
    }
  },
  data: function data() {
    return {
      activeActivity: 'first',
      carouselImages: ['https://cdn.laravue.dev/photo1.png', 'https://cdn.laravue.dev/photo2.png', 'https://cdn.laravue.dev/photo3.jpg', 'https://cdn.laravue.dev/photo4.jpg'],
      updating: false
    };
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log('Switching tab ', tab, event);
    },
    onSubmit: function onSubmit() {
      var _this = this;

      this.updating = true;
      userResource.update(this.user.id, this.user).then(function (response) {
        _this.updating = false;

        _this.$message({
          message: 'User information has been updated successfully',
          type: 'success',
          duration: 3 * 1000
        });
      }).catch(function (error) {
        console.log(error);
        _this.updating = false;
      });
    }
  }
};