import "core-js/modules/es.array.slice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
import dayjs from 'dayjs';
import NP from 'number-precision';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }

  return time + label + 's';
}
/**
 * @param {number} time
 */


export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);

  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */

export function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];

  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }

  return num.toString();
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}
/**
 * Upper case first char
 * @param {String} string
 */

export function uppercaseFirst(string) {
  // console.log('----uppercaseFirst----')
  // console.log(string)
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * formating date
 * @param {String} string
 */

export function formatDate(date) {
  if (!date) {
    return '---';
  } // console.log('~~~~date~~~~',date)
  // return dayjs(date).format('MM/DD/YYYY'); // 使用 dayjs 格式化时间


  return dayjs(date).format('MM/DD/YYYY', {
    timeZone: 'America/New_York'
  });
}
/**
 * formating date
 * @param {String} string
 */

export function formatDateTime(date) {
  if (!date) {
    return '---';
  } // return dayjs(date).format('MM/DD/YYYY HH:mm'); // 使用 dayjs 格式化时间


  return dayjs(date).format('MM/DD/YYYY HH:mm ', {
    timeZone: 'America/New_York'
  });
}
/**
 * formating date
 * @param {String} string
 */

export function readMore(text, length, suffix) {
  return text.substring(0, length) + suffix;
}
/**
 * formating Money
 * @param {String} string
 */

export function moneyNum(value) {
  if (!value) return '0.00';
  return value; // value = value.toString()
  // var str = value/100

  var str = NP.divide(value, 100);
  return str > 0 ? '$' + str : '-$' + Math.abs(str);
}
export function stageStatusFilter(status) {
  var statusMap = {
    'ACTIVE': 'primary',
    'OP_CLOSED': 'warning',
    'ACCOUNTING_CLOSED': 'danger'
  };
  return statusMap[status];
}