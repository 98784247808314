import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import { Message } from 'element-ui';
export function showSystemMsg(msg, msg_type) {
  // console.log('---showSystemMsg---')
  return Message({
    message: msg,
    type: msg_type,
    duration: 3 * 1000,
    offset: 60
  });
}
export function randomNumber() {
  return Math.floor(Math.random() * 1000000);
}
export function openPDFURL(department, target, id) {
  // '/openpdf/air_export/lot/'+lotInfo.id
  var path = '/pdf-awb/' + department + '/' + target + '/' + id;
  var url = process.env.VUE_APP_BASE_API + path;
  var random = randomNumber();
  var connector = url.includes("?") ? '&' : '?';
  url = url + connector + 'random=' + random;
  window.open(url + '', "_blank");
}
export function openMAEManifestURL(id) {
  // '/openpdf/air_export/lot/'+lotInfo.id  air_export_manifest/view/4
  var path = '/air_export_manifest/view/' + id;
  var url = process.env.VUE_APP_DOMAIN + path;
  var random = randomNumber();
  var connector = url.includes("?") ? '&' : '?';
  url = url + connector + 'random=' + random;
  window.open(url + '', "_blank");
}
export function openDeliveryTicketURL(id) {
  // '/openpdf/air_export/lot/'+lotInfo.id
  var path = '/delivery_order/view/' + id;
  var url = process.env.VUE_APP_DOMAIN + path; // let random = randomNumber()
  // let connector = url.includes("?") ? '&' : '?'
  // url = url+connector+'random='+random

  window.open(url + '', "_blank");
}
export function openContactInNewWindow(contact_id) {
  // console.log('hihihi')
  var path = '/contact/index?listMode=left&id=' + contact_id; // let url = process.env.VUE_APP_DOMAIN + path

  window.open(path + '', "_blank");
}
export function getDepartmentList() {
  var $scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'dep';
  // $scope = ['all','others'] 
  var $dep_arr = [{
    name: "Air Export",
    value: "MAE"
  }, {
    name: "Air Import",
    value: "MAI"
  }, {
    name: "Sea Export",
    value: "MOE"
  }, {
    name: "Sea Import",
    value: "MOI"
  }, {
    name: "TLM",
    value: "TLM"
  }, {
    name: "Customs Broker",
    value: "BKG"
  }, {
    name: "Miscellaneous",
    value: "MIS"
  }, {
    name: "Warehouse",
    value: "WHS"
  }, {
    name: "Delivery",
    value: "DEL"
  }];

  if ($scope.includes('all')) {
    $dep_arr.unshift({
      name: "All",
      value: "ALL"
    });
  } // if($scope.includes('others')){
  //   $dep_arr.push({ name:"Others", value: "OTHERS" })
  // }  


  return $dep_arr;
}