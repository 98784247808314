var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "empty-text": "Empty..."
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.index))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              align: "center",
              label: _vm.$t("table.name")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("uppercaseFirst")(scope.row.name)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "left", label: _vm.$t("table.description") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.description))])]
                }
              }
            ])
          }),
          _vm.checkPermission(["manage permission"])
            ? _c(
                "el-table-column",
                {
                  attrs: { align: "center", label: "Actions", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.name !== "admin"
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["manage permission"],
                                        expression: "['manage permission']"
                                      }
                                    ],
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEditPermissions(
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("permission.editPermission")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3977824696
                  )
                },
                [_c("span", [_vm._v("woowow---")])]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "Edit Permissions - " + _vm.currentRole.name
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              staticClass: "form-container"
            },
            [
              _c("div", { staticClass: "permissions-container" }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          model: _vm.currentRole,
                          "label-width": "80px",
                          "label-position": "top"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Menus" } },
                          [
                            _c("el-tree", {
                              ref: "menuPermissions",
                              staticClass: "permission-tree",
                              attrs: {
                                data: _vm.menuPermissions,
                                "default-checked-keys": _vm.permissionKeys(
                                  _vm.roleMenuPermissions
                                ),
                                props: _vm.permissionProps,
                                "show-checkbox": "",
                                "node-key": "id"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          model: _vm.currentRole,
                          "label-width": "80px",
                          "label-position": "top"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Permissions" } },
                          [
                            _c("el-tree", {
                              ref: "otherPermissions",
                              staticClass: "permission-tree",
                              attrs: {
                                data: _vm.otherPermissions,
                                "default-checked-keys": _vm.permissionKeys(
                                  _vm.roleOtherPermissions
                                ),
                                props: _vm.permissionProps,
                                "show-checkbox": "",
                                "node-key": "id"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "clear-left" })
              ]),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("permission.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmPermission }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("permission.confirm")) + " ")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }