export default {
  system: {
    lang: 'zh',
    language: '中文',
    language_switch_success: '语言切换成功',
    cancel: '取消',
    submit: '提交',
    query: '查询',
    db_id: '编号',
    created_at: '创建时间',
    category: '类别',
    all_category: '所有类别',
    list_op: '操作',
    please_select: '请选择',
    status: '状态',
    delete: '删除',
    confirm: '确定',
    vendor: '供应商',
    brand: '品牌',
    orders: '订单数量',
    customer: '客户',
    all_customer: '所有客户',
    sales_name: '销售人员',
    package_damaged: '包装破损',
    regular: '正常',
    assign: '分配',
    discard_changes: '放弃更改',
    style: '样式'
  },
  dashboard: {
    receiving: '进货',
    releasing: '订单',
    products: '产品',
    invoice: '账单',
    inventory_alert: '库存预警'
  },
  product: {
    list: '产品列表',
    new: '新建产品',
    product_code: '产品编码',
    chinese_name: '中文名称',
    english_name: '英文名称',
    sales_price: '零售价',
    regular_sales_price: '正常零售价',
    damaged_sales_price: '破损零售价',
    package: '包装规格',
    assign_category: '分类',
    regular_available_pcs: '常规可用',
    damaged_available_pcs: '破损可用',
    total_received_pcs_regular: '常规总收货',
    total_received_pcs_damaged: '常规总破损',
    assigned_pcs_regular: '常规待出货',
    assigned_pcs_damaged: '破损待出货'
  },
  product_category: {
    list: '产品目录列表',
    new: '新建产品目录'
  },
  releasing_customer: {
    list: '客户列表',
    new: '新增客户'
  },
  product_vendor: {
    list: '供应商列表',
    new: '新建供应商'
  },
  receiving: {
    new: '新建进货单',
    list: '进货单列表',
    master_no: 'MAWB #',
    total_products: '产品总数',
    total_pcs: '件数',
    scan_in: '扫码入库'
  },
  releasing: {
    new: '新建订单',
    list: '订单列表',
    order_no: '订单号',
    total_products: '产品总数',
    total_pcs: '件数',
    prepare_assign: '仓库配货',
    assign_lots: '从货架上配货',
    prepare_form: '备货单',
    order_invoice: '订单发票',
    orderout_setting: '出货设置'
  },
  settings: {
    accounts_list: '登录账号列表',
    accounts_new: '新建账号'
  },
  route: {
    dashboard: '首页',
    receiving: '进货',
    receiving_new: '新建进货单',
    receiving_list: '进货记录',
    receiving_scan_in: '扫码入库',
    releasing: '订单',
    releasing_new: '新建订单',
    releasing_list: '订单记录',
    customer: '客户',
    inventory: '库存',
    inventory_list: '库存列表',
    product: '产品',
    product_list: '产品列表',
    product_category: '产品目录',
    product_vendor: '供应商',
    settings: '设置',
    settings_system: '系统设置',
    settings_account: '登录账号',
    prepare_form: '备货单',
    order_invoice: '订单发票',
    documentation: '文档',
    guide: '引导页',
    permission: '权限测试页',
    rolePermission: '角色权限',
    pagePermission: '页面权限',
    directivePermission: '指令权限',
    icons: '图标',
    components: '组件',
    tinymce: '富文本编辑器',
    markdown: 'Markdown',
    jsonEditor: 'JSON 编辑器',
    dndList: '列表拖拽',
    splitPane: 'Splitpane',
    avatarUpload: '头像上传',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: '小组件',
    backToTop: '返回顶部',
    dragDialog: '拖拽 Dialog',
    dragSelect: '拖拽 Select',
    dragKanban: '可拖拽看板',
    charts: '图表',
    keyboardChart: '键盘图表',
    lineChart: '折线图',
    mixChart: '混合图表',
    example: '综合实例',
    nested: '路由嵌套',
    menu1: '菜单1',
    'menu1-1': '菜单 1-1',
    'menu1-2': '菜单 1-2',
    'menu1-2-1': '菜单 1-2-1',
    'menu1-2-2': '菜单 1-2-2',
    'menu1-3': '菜单 1-3',
    menu2: '菜单 2',
    Table: 'Table',
    dynamicTable: '动态 Table',
    dragTable: '拖拽 Table',
    inlineEditTable: 'Table 内编辑',
    complexTable: '综合 Table',
    tab: 'Tab',
    form: '表单',
    createArticle: '创建文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    selectExcel: '导出 已选择项',
    mergeHeader: '导出 多级表头',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: '换肤',
    clipboardDemo: 'Clipboard',
    i18n: '国际化',
    externalLink: '外链',
    profile: '个人中心'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定'
  },
  example: {
    warning: '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  }
};