import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";

/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
var department_config = {
  id: 7,
  name: 'Customs Broker',
  prefix: 'BKG',
  route_path: 'broker',
  api_path: 'broker',
  inv_master_name: 'MAWB/MBL',
  inv_house_name: 'HAWB/HBL'
};
var customsBroker = {
  path: '/broker',
  component: Layout,
  redirect: 'noRedirect',
  name: 'CustomsBroker',
  alwaysShow: true,
  meta: {
    title: 'CustomsBroker',
    icon: 'el-icon-office-building',
    roles: ['Admin', 'General Manager', 'Accountant', 'Customsbroker Manager', 'Customsbroker OP', 'Customsbroker Warehouse', 'OP-All'] // permissions: ['view menu '],

  },
  children: [
  /** User managements */
  // {
  //   path: 'test_view',
  //   name: 'Test Page',
  //   component: () => export('@/views/_test'),
  //   meta: { title: 'Test Page', icon: '' }
  // },       
  {
    path: 'index',
    name: 'Lot List',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_customsbroker/LotList'));
      });
    },
    meta: {
      title: 'Lot List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu customsbroker index']
    }
  }, {
    path: 'invoice_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_customsbroker/InvoiceList'));
      });
    },
    name: 'Invoice List',
    meta: {
      title: 'Invoice List',
      department_config: department_config,
      icon: '',
      permissions: ['view menu customsbroker invoices']
    }
  }, {
    path: 'cost_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dep_customsbroker/CostList'));
      });
    },
    name: 'Cost Lists',
    meta: {
      title: 'Cost Lists',
      department_config: department_config,
      icon: '',
      permissions: ['view menu customsbroker costs']
    }
  } // {
  //   path: 'pricing_list',
  //   component: () => import('@/views/dep_broker/PricingList'),
  //   name: 'IW List',
  //   meta: { title: 'IW List', department_config:department_config ,  icon: '', permissions: ['view menu customsbroker pricings'] },
  // },
  // {
  //   path: 'manifest',
  //   component: () => import('@/views/dep_broker/Manifest'),
  //   name: 'Manifest',
  //   meta: { title: 'Manifest', department_config:department_config ,  icon: '' },
  //   hidden: true
  // },
  ]
};
export default customsBroker;