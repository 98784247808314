import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PanThumb from '@/components/PanThumb';
export default {
  components: {
    PanThumb: PanThumb
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: []
        };
      }
    }
  },
  data: function data() {
    return {
      social: [{
        'name': 'Followers',
        'count': 1235
      }, {
        'name': 'Following',
        'count': 23512
      }, {
        'name': 'Friends',
        'count': 7242
      }]
    };
  },
  methods: {
    getRole: function getRole() {
      var _this = this;

      var roles = this.user.roles.map(function (value) {
        return _this.$options.filters.uppercaseFirst(value);
      });
      return roles.join(' | ');
    }
  }
};