import _objectSpread from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Resource from "@/api/resource";
import { mapGetters } from "vuex";
/*向父组件传递的数据：
 * commitSelection     被选中的数据
 *
 * */

export default {
  name: "lot-delete",
  model: {
    prop: "user_selected",
    event: "changeValue"
  },
  created: function created() {
    console.log("----lot-delete-created---");
    console.log(this.lot_department);
    console.log(this.lotInfo); // if (this.remoteUserList.length == 0) {
    //   console.log("----remoteSearchUser---");
    //   this.remoteSearchUser();
    // }
    // console.log(' ObjectDetails created....')

    this.lotResource = new Resource("lot/" + this.lot_department);
  },
  data: function data() {
    return {
      // val_tmp: "",
      // searchUserLoading: false,
      // remoteUserList: [],
      // formData: {},
      // dropdownFilterAllList: [],
      //   listQuery: this.listParams ,
      //   batchOpOptions: this.batchOpConfig ,
      //   rowOpOptions: this.rowOpConfig ,
      //   advSearchOptions: advSearchOptions_init,
      //   dataSource: [],// 这是数据源
      //   column_data: {},
      scoped: {
        // 是否展示下面的列表
        // showFlag: false,
        // 当前选中的
        selected: this.user_selected // remoteUserList: [],

      }
    };
  },
  props: {
    //列表对应的API对象名称，用于组成指向edit页面的URL
    // editURI: {
    //     type: String,
    //     default: function () {
    //         return ''
    //     }
    // },
    lotInfo: {
      type: Object
    },
    lot_department: String // input_width: { type: String, default: "180" },
    // user_selected: [String, Number],
    // placeholder: String,
    // user_type: String,
    // remoteUserSaved: { type: Array },
    // userInfo: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       el_type: "remote-user",
    //       user_type: "customer",
    //       option_name: "customer_id",
    //       show_name: "Customer",
    //       set_value: "",
    //       init_value: "",
    //       action: "instant",
    //       width: "150",
    //     };
    //   },
    // },

  },
  methods: {
    deleteLot: function deleteLot() {
      var _this = this;

      // console.log('handlePeopleEdit',index, row);
      this.$confirm("Are you sure to Delete this Lot?", "Caution", {
        type: "warning"
      }).then(function () {
        _this.listLoading = true; // console.log(index, row);

        var vm = _this;

        _this.lotResource.destroy(_this.lotInfo.id).then(function (res) {
          vm.$message({
            type: "success",
            message: "Lot has been deleted!",
            duration: 2 * 1000
          });
          vm.listLoading = false; // await new Promise((r) => setTimeout(r, 5000));

          _this.$emit("backToLotList");
        }).catch(function (error) {
          console.log("---del error---", error); // alert will popup in axios request module.
          // console.log(error);

          vm.listLoading = false;
        });
      }).catch(function () {});
    } // changeValue(item) {
    //   this.scoped.selected = item;
    //   this.scoped.showFlag = false;
    //   console.log("---changeValue in yipUserRemote---");
    //   console.log(this.scoped.selected);
    //   this.$emit("changeValue", this.scoped.selected);
    // },

  },
  mounted: function mounted() {// this.dropdownFilterAllList = this.dropdownFilterInit.concat(
    //   this.filterConfig.dropdownFilterConfig
    // );
    // console.log("-----dropdownFilterAllList----", this.dropdownFilterAllList);
    //控制实时搜索频率，@change 事件后600ms 才发送搜索请求
    //   this.instant_search_sync = _.debounce(this.instant_search_sync, 600);
    //   console.log('---init table filter --mounted---')
    //   this.getList();
    //   console.log('---init  table filter --done---')
    // console.log('rout-params-------',this.$route.query.id);
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["roles"])), {}, {
    canDelete: function canDelete() {
      return this.lotInfo.closed_stage == "ACTIVE" && this.lotInfo.expenses_count == 0 && this.lotInfo.invoices_count == 0 && this.lotInfo.houses_count == 0;
    }
  })
};