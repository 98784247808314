//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { validEmail } from '@/utils/validate';
export default {
  name: 'Login',
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      console.log('---validating email....', value);

      if (!validEmail(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };

    var validateUsername = function validateUsername(rule, value, callback) {
      console.log('---validating Username....', value);

      if (value.length < 2) {
        callback(new Error('Please enter the correct username'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };

    return {
      env: process.env.VUE_APP_ENV,
      loginForm: {
        // email: 'admin@laravue.dev',
        username: '',
        password: 'laravue'
      },
      loginRules: {
        // email: [{ required: true, trigger: 'blur', validator: validateEmail }],
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        console.log('----valid---', valid);

        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _this2.$router.push({
              path: _this2.redirect || '/'
            });

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};