var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.layoutMode == "drawer"
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "30px",
              padding: "14px 15px",
              "border-bottom": "1px solid #dfe6ec"
            }
          },
          [
            _c("el-page-header", {
              attrs: {
                title: "Cancel",
                content: "Create " + _vm.department_config.name + " Lot"
              },
              on: { back: _vm.handleCloseDrawer }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "view"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              { attrs: { size: "medium", "label-width": "150px" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { type: "success", plain: "" },
                        on: {
                          click: function($event) {
                            _vm.mode = "edit"
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Master #: " } },
                              [
                                _vm.ae_lot_info.master_type == "Other"
                                  ? _c("span", [_vm._v("Other")])
                                  : _vm._e(),
                                _vm.ae_lot_info.master_type == "Air"
                                  ? _c("span", [_vm._v("MAWB-Air")])
                                  : _vm._e(),
                                _vm.ae_lot_info.master_type == "Ocean"
                                  ? _c("span", [_vm._v("MBL-Ocean")])
                                  : _vm._e(),
                                _vm.ae_lot_info.master_type == "Air"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.ae_lot_info.airline_code) +
                                          " - "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.master_no) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Departure from: " } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.ae_lot_info.departure_date) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "ETA Date: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.eta_date) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _vm.ae_lot_info.master_type == "Ocean"
                          ? [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Container #: " } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ae_lot_info.container_no) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm.ae_lot_info.master_type == "Air"
                          ? [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "PMC #: " } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ae_lot_info.pmc_no) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm.ae_lot_info.master_type == "Other"
                          ? [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Ref #: " } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ae_lot_info.other_ref_no) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Entry #: " } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.ae_lot_info.entry_no) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Remarks:", prop: "remarks" } },
                      [_vm._v(" " + _vm._s(_vm.ae_lot_info.remarks) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm.mode == "new" || _vm.mode == "edit"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.lot_info_loading,
                expression: "lot_info_loading"
              }
            ],
            attrs: { "element-loading-text": "Loading data..." }
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 27 } },
              [
                _c(
                  "el-form",
                  {
                    ref: "ae-lot-form",
                    attrs: {
                      model: _vm.ae_lot_info,
                      rules: _vm.ae_lot_rules,
                      size: "medium",
                      "label-width": "150px"
                    }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _vm.mode == "edit"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-bottom": "15px"
                                },
                                attrs: { type: "success", plain: "" },
                                on: {
                                  click: function($event) {
                                    _vm.mode = "view"
                                  }
                                }
                              },
                              [_vm._v("View")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Master#:",
                                      prop: "master_no"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        style: { width: "130px" },
                                        attrs: {
                                          placeholder: "Select AWB/BOL",
                                          clearable: "",
                                          filterable: ""
                                        },
                                        model: {
                                          value: _vm.ae_lot_info.master_type,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ae_lot_info,
                                              "master_type",
                                              $$v
                                            )
                                          },
                                          expression: "ae_lot_info.master_type"
                                        }
                                      },
                                      _vm._l(_vm.master_type_list, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: item.val,
                                            attrs: {
                                              label: item.name,
                                              value: item.val
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ])
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                    _vm.ae_lot_info.master_type == "Air"
                                      ? _c("el-input", {
                                          staticStyle: {
                                            width: "110px",
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            placeholder: "AirlineCode ",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.ae_lot_info.airline_code,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ae_lot_info,
                                                "airline_code",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ae_lot_info.airline_code"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" - "),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "170px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        placeholder: "MAWB/MBL ",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.master_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "master_no",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.master_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.ae_lot_info.id > 0
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "Status",
                                          prop: "closed_stage"
                                        }
                                      },
                                      [
                                        _c("stage-status-select", {
                                          attrs: {
                                            stage_status:
                                              _vm.ae_lot_info.closed_stage
                                          },
                                          on: {
                                            updateStatus: _vm.updateStageStatus
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Departure Date:",
                                      prop: "departure_date"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "Departure Date:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.departure_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "departure_date",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.departure_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "ETA Date:",
                                      prop: "eta_date"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      style: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "MM/dd/yyyy",
                                        "value-format": "MM/dd/yyyy",
                                        placeholder: "ETA Date:",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.eta_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "eta_date",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.eta_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _vm.ae_lot_info.master_type == "Ocean"
                              ? [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Container #:",
                                            prop: "container_no"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "Container #",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.ae_lot_info.container_no,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ae_lot_info,
                                                  "container_no",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ae_lot_info.container_no"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm.ae_lot_info.master_type == "Air"
                              ? [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "PMC #:",
                                            prop: "pmc_no"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "PMC #",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.ae_lot_info.pmc_no,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ae_lot_info,
                                                  "pmc_no",
                                                  $$v
                                                )
                                              },
                                              expression: "ae_lot_info.pmc_no"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm.ae_lot_info.master_type == "Other"
                              ? [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Ref #:",
                                            prop: "other_ref_no"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "Ref #",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.ae_lot_info.other_ref_no,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ae_lot_info,
                                                  "other_ref_no",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ae_lot_info.other_ref_no"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Entry #:",
                                      prop: "entry_no"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "Entry #",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.entry_no,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "entry_no",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.entry_no"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Customer",
                                      prop: "customer_id"
                                    }
                                  },
                                  [
                                    _c("yip-contact-remote", {
                                      attrs: {
                                        placeholder: "Customer",
                                        contact_type: "customer",
                                        input_width: "170",
                                        show_detail_icon: "true"
                                      },
                                      model: {
                                        value: _vm.ae_lot_info.customer_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "customer_id",
                                            $$v
                                          )
                                        },
                                        expression: "ae_lot_info.customer_id"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Remarks:", prop: "remarks" } },
                          [
                            _c("el-input", {
                              style: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 3 }
                              },
                              model: {
                                value: _vm.ae_lot_info.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.ae_lot_info, "remarks", $$v)
                                },
                                expression: "ae_lot_info.remarks"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "large" }
                          },
                          [
                            _c("el-button", { on: { click: _vm.resetForm } }, [
                              _vm._v("Reset")
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.lotCanEdit
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }