var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "margin-bottom": "30px",
          padding: "14px 15px",
          "border-bottom": "1px solid #dfe6ec"
        }
      },
      [
        _c("el-page-header", {
          attrs: {
            title: "Cancel",
            content: _vm.mode + " " + _vm.department_config.name + " MAWB"
          },
          on: { back: _vm.handleCloseDrawer }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.lot_info_loading,
            expression: "lot_info_loading"
          }
        ],
        attrs: { "element-loading-text": "Loading data..." }
      },
      [
        _c(
          "el-row",
          { attrs: { gutter: 27 } },
          [
            _c(
              "el-form",
              {
                ref: "ae-lot-form",
                attrs: {
                  model: _vm.ae_lot_info,
                  rules: _vm.ae_lot_rules,
                  size: "medium",
                  "label-width": "150px"
                }
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "MAWB#:", prop: "master_no" } },
                              [
                                _c("el-input", {
                                  staticClass: "input_auto",
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.ae_lot_info.master_no_prefix,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "master_no_prefix",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.master_no_prefix"
                                  }
                                }),
                                _vm._v(" - "),
                                _vm.ae_lot_info.flight_list
                                  ? _c("el-input", {
                                      staticClass: "input_auto",
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.flight_list[0]
                                            .airport_departure,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info.flight_list[0],
                                            "airport_departure",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.flight_list[0].airport_departure"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" - "),
                                _c("el-input", {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "MAWB#:",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.ae_lot_info.master_no,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "master_no",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.master_no"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.ae_lot_info.id > 0
                          ? _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Status",
                                      prop: "closed_stage"
                                    }
                                  },
                                  [
                                    _c("stage-status-select", {
                                      attrs: {
                                        stage_status:
                                          _vm.ae_lot_info.closed_stage
                                      },
                                      on: {
                                        updateStatus: _vm.updateStageStatus
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { padding: "0px 10px 10px 80px" },
                    attrs: { span: 23 }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { border: "", data: _vm.ae_lot_info.flight_list }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { align: "", label: "Flight #", width: "70" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "airport_departure",
                            width: "180",
                            align: "",
                            label: "Departure"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select airport",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      model: {
                                        value: slotProps.row.airport_departure,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "airport_departure",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.airport_departure"
                                      }
                                    },
                                    _vm._l(_vm.airport_code, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.code,
                                            value: item.code
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.code) +
                                                " - " +
                                                _vm._s(item.name)
                                            )
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "airport_destination",
                            label: "Destination",
                            width: "180"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select airport",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      model: {
                                        value:
                                          slotProps.row.airport_destination,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "airport_destination",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "slotProps.row.airport_destination"
                                      }
                                    },
                                    _vm._l(_vm.airport_code, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.code,
                                            value: item.code
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.code) +
                                                " - " +
                                                _vm._s(item.name)
                                            )
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "departure_date",
                            label: "Departure Date",
                            width: "170"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      format: "MM/dd/yyyy",
                                      "value-format": "MM/dd/yyyy",
                                      placeholder: "Departure Date:",
                                      clearable: ""
                                    },
                                    model: {
                                      value: slotProps.row.departure_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          slotProps.row,
                                          "departure_date",
                                          $$v
                                        )
                                      },
                                      expression: "slotProps.row.departure_date"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "carrier_id",
                            label: "Carrier",
                            width: "200"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select Airline",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeCarrierInTable(
                                            slotProps.$index,
                                            slotProps.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: slotProps.row.carrier_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            slotProps.row,
                                            "carrier_id",
                                            $$v
                                          )
                                        },
                                        expression: "slotProps.row.carrier_id"
                                      }
                                    },
                                    _vm._l(_vm.contactSimpleCarrier, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.company,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "flight", label: "Flight" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c("el-input", {
                                    staticClass: "input_auto",
                                    attrs: { disabled: "" },
                                    model: {
                                      value: slotProps.row.airline_iata_code,
                                      callback: function($$v) {
                                        _vm.$set(
                                          slotProps.row,
                                          "airline_iata_code",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "slotProps.row.airline_iata_code"
                                    }
                                  }),
                                  _vm._v(" - "),
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      placeholder: "Flight",
                                      clearable: ""
                                    },
                                    model: {
                                      value: slotProps.row.flight,
                                      callback: function($$v) {
                                        _vm.$set(slotProps.row, "flight", $$v)
                                      },
                                      expression: "slotProps.row.flight"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Shipper", prop: "shipper_id" }
                              },
                              [
                                _c("yip-contact-remote", {
                                  attrs: {
                                    placeholder: "Shipper",
                                    contact_type: "shipper",
                                    input_width: "170",
                                    show_detail_icon: "true"
                                  },
                                  model: {
                                    value: _vm.ae_lot_info.shipper_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "shipper_id",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.shipper_id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "CNEE", prop: "cnee_id" } },
                              [
                                _c("yip-contact-remote", {
                                  attrs: {
                                    placeholder: "CNEE",
                                    contact_type: "cnee",
                                    input_width: "170",
                                    show_detail_icon: "true"
                                  },
                                  model: {
                                    value: _vm.ae_lot_info.cnee_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ae_lot_info, "cnee_id", $$v)
                                    },
                                    expression: "ae_lot_info.cnee_id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 7 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "1st Leg MAWB#:",
                                  prop: "first_leg_mawb_no"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { placeholder: "1st Leg MAWB#:" },
                                  model: {
                                    value: _vm.ae_lot_info.first_leg_mawb_no,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "first_leg_mawb_no",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.first_leg_mawb_no"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Agent", prop: "agent_id" } },
                              [
                                _c("yip-contact-remote", {
                                  attrs: {
                                    placeholder: "Agent",
                                    contact_type: "agent",
                                    input_width: "170",
                                    show_detail_icon: "true"
                                  },
                                  on: { applyContact: _vm.handleChangeAgent },
                                  model: {
                                    value: _vm.ae_lot_info.agent_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ae_lot_info, "agent_id", $$v)
                                    },
                                    expression: "ae_lot_info.agent_id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Agent IATA Code:",
                                  prop: "agent_iata_code"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { clearable: "", disabled: "" },
                                  model: {
                                    value: _vm.ae_lot_info.agent_iata_code,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "agent_iata_code",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.agent_iata_code"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Accounting Info:",
                                  prop: "accounting_info"
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { size: "medium" },
                                    model: {
                                      value: _vm.ae_lot_info.accounting_info,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "accounting_info",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.accounting_info"
                                    }
                                  },
                                  _vm._l(_vm.accounting_infoOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: {
                                          label: item.value,
                                          disabled: item.disabled
                                        }
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Currency:", prop: "currency" }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { placeholder: "Currency:" },
                                  model: {
                                    value: _vm.ae_lot_info.currency,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ae_lot_info, "currency", $$v)
                                    },
                                    expression: "ae_lot_info.currency"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Accounting Info:",
                                  prop: "accounting_info_details"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "70%" },
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 3, maxRows: 3 }
                                  },
                                  model: {
                                    value:
                                      _vm.ae_lot_info.accounting_info_details,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "accounting_info_details",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ae_lot_info.accounting_info_details"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "120px",
                                  label: "Insurance:",
                                  prop: "insurance"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { placeholder: "Insurance" },
                                  model: {
                                    value: _vm.ae_lot_info.insurance,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "insurance",
                                        $$v
                                      )
                                    },
                                    expression: "ae_lot_info.insurance"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "180px",
                                  label: "ChgsCode-WT/VAL",
                                  prop: "charge_code_wt_val"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.ae_lot_info.charge_code_wt_val,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "charge_code_wt_val",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.charge_code_wt_val"
                                    }
                                  },
                                  _vm._l(_vm.charge_codesOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "180px",
                                  label: "ChgsCode-Other",
                                  prop: "charge_code_other"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.ae_lot_info.charge_code_other,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "charge_code_other",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.charge_code_other"
                                    }
                                  },
                                  _vm._l(_vm.charge_codesOptions, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "280px",
                                  label: "Declared Value for Carriage: ",
                                  prop: "declared_value_for_carriage"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "Declared Value for Carriage"
                                  },
                                  model: {
                                    value:
                                      _vm.ae_lot_info
                                        .declared_value_for_carriage,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "declared_value_for_carriage",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ae_lot_info.declared_value_for_carriage"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "220px",
                                  label: "Declared Value for Customs: ",
                                  prop: "declared_value_for_customs"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "Declared Value for Customs"
                                  },
                                  model: {
                                    value:
                                      _vm.ae_lot_info
                                        .declared_value_for_customs,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "declared_value_for_customs",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ae_lot_info.declared_value_for_customs"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Handling Information:",
                          prop: "handling_information"
                        }
                      },
                      [
                        _c("el-input", {
                          style: { width: "70%" },
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 3, maxRows: 3 }
                          },
                          model: {
                            value: _vm.ae_lot_info.handling_information,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ae_lot_info,
                                "handling_information",
                                $$v
                              )
                            },
                            expression: "ae_lot_info.handling_information"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { padding: "0px 10px 10px 80px" },
                    attrs: { span: 23 }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { border: "", data: [{ pcs: "" }] }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_pcs",
                            label: "PCS",
                            width: "80"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.ae_lot_info.packing_pcs,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "packing_pcs",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.packing_pcs"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_weight_unit",
                            label: "Weight Unit",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value:
                                          _vm.ae_lot_info.packing_weight_unit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ae_lot_info,
                                            "packing_weight_unit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ae_lot_info.packing_weight_unit"
                                      }
                                    },
                                    _vm._l(
                                      _vm.packing_weight_unitOptions,
                                      function(item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                            disabled: item.disabled
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_gross_weight",
                            label: "Gross Weight",
                            width: "100"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.ae_lot_info.packing_gross_weight,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "packing_gross_weight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.packing_gross_weight"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_chargeable_weight",
                            label: "Chargeable Weight",
                            width: "100"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    on: {
                                      change: function($event) {
                                        return _vm.updateChargeTotal()
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info
                                          .packing_chargeable_weight,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "packing_chargeable_weight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.packing_chargeable_weight"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_rate",
                            label: "Rate",
                            width: "90"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _vm._v(" Rate "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "'AS AGREED' will be showed on documents when Rate set to 0",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                        staticStyle: { color: "darkgoldenrod" }
                                      })
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    on: {
                                      change: function($event) {
                                        return _vm.updateChargeTotal()
                                      }
                                    },
                                    model: {
                                      value: _vm.ae_lot_info.packing_rate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "packing_rate",
                                          $$v
                                        )
                                      },
                                      expression: "ae_lot_info.packing_rate"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_total",
                            label: "Total",
                            width: "100"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(_vm.packing_total) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "packing_nature_of_goods",
                            label: "Nature of goods"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    staticStyle: {},
                                    attrs: {
                                      autosize: { minRows: 2, maxRows: 5 },
                                      type: "textarea"
                                    },
                                    model: {
                                      value:
                                        _vm.ae_lot_info.packing_nature_of_goods,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ae_lot_info,
                                          "packing_nature_of_goods",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ae_lot_info.packing_nature_of_goods"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Remarks:", prop: "packing_remarks" } },
                      [
                        _c("el-input", {
                          style: { width: "70%" },
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 3, maxRows: 3 }
                          },
                          model: {
                            value: _vm.ae_lot_info.packing_remarks,
                            callback: function($$v) {
                              _vm.$set(_vm.ae_lot_info, "packing_remarks", $$v)
                            },
                            expression: "ae_lot_info.packing_remarks"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Other Charges:",
                          prop: "other_charges"
                        }
                      },
                      [
                        _c("el-input", {
                          style: { width: "100%" },
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 3, maxRows: 3 }
                          },
                          model: {
                            value: _vm.ae_lot_info.other_charges,
                            callback: function($$v) {
                              _vm.$set(_vm.ae_lot_info, "other_charges", $$v)
                            },
                            expression: "ae_lot_info.other_charges"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "250px",
                                  label: "Total Other Charge Due Carrier:",
                                  prop: "other_charge_total_due_carrier"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value:
                                      _vm.ae_lot_info
                                        .other_charge_total_due_carrier,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "other_charge_total_due_carrier",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ae_lot_info.other_charge_total_due_carrier"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "250px",
                                  label: "Total Other Charge Due Agent:",
                                  prop: "other_charge_total_due_agent"
                                }
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value:
                                      _vm.ae_lot_info
                                        .other_charge_total_due_agent,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ae_lot_info,
                                        "other_charge_total_due_agent",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ae_lot_info.other_charge_total_due_agent"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 23 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { float: "right" },
                        attrs: { size: "large" }
                      },
                      [
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v("Reset")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !_vm.lotCanEdit,
                              type: "primary"
                            },
                            on: { click: _vm.submitForm }
                          },
                          [_vm._v("Submit")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }