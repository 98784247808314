import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.string.includes";
import store from '@/store';
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
// export default function checkPermission(value) {
//   if (value && value instanceof Array && value.length > 0) {
//     const roles = store.getters && store.getters.roles
//     const permissionRoles = value
//     const hasPermission = roles.some(role => {
//       return permissionRoles.includes(role)
//     })
//     return hasPermission
//   } else {
//     console.error(`need roles! Like v-permission="['admin','editor']"`)
//     return false
//   }
// }

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/Directive.vue
 */

export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var permissions = store.getters && store.getters.permissions; // console.log('----checkPermission---',permissions,value)

    var requiredPermissions = value;
    var hasPermission = permissions.some(function (permission) {
      return requiredPermissions.includes(permission);
    });
    return hasPermission;
  } else {
    console.error("Need permissions! Like v-permission=\"['manage permission','edit article']\"");
    return false;
  }
}