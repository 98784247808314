var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-row",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dataLoading,
                  expression: "dataLoading"
                }
              ],
              attrs: { span: 24, "element-loading-text": "Loading Data..." }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "padding-top": "10px", "padding-left": "30px" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("h3", [_vm._v("Lot Profit")])
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-button", {
                                staticStyle: {
                                  "margin-left": "5px",
                                  float: "right"
                                },
                                attrs: {
                                  size: "medium",
                                  type: "primary",
                                  icon: "el-icon-refresh",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fetchProfitInfo()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.lotProfitData,
                            "empty-text": "No Profit found",
                            "row-key": "service_id",
                            "default-expand-all": "",
                            stripe: "",
                            fit: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount_invoice",
                              label: "Invoice Amount",
                              width: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(amount) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(amount.row.amount_invoice) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount_expense",
                              label: "Cost Amount",
                              width: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(amount) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(amount.row.amount_expense) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount_profit",
                              label: "Profit Amount",
                              width: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(amount) {
                                  return [
                                    amount.row.amount_profit > 0
                                      ? [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "green" } },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    amount.row.amount_profit
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      : amount.row.amount_profit < 0
                                      ? [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    amount.row.amount_profit
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      : [_vm._v(" 0 ")]
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "padding-top": "10px", "padding-left": "30px" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("h3", [_vm._v("Invoice List")]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.lotInvoiceData,
                            "empty-text": "No Invoices found",
                            "row-key": "service_id",
                            "default-expand-all": "",
                            stripe: "",
                            fit: "",
                            "show-summary": "",
                            "summary-method": _vm.getLotInvoiceSummaries
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "payer_id",
                              label: "Payer",
                              width: "250"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.payer.company) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "status",
                              label: "Status",
                              width: ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "total_amount",
                              label: "Amount",
                              width: "180"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(amount) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(amount.row.total_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }, [_c("p")]),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("h3", [_vm._v("Cost List")]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.lotCostData,
                            "empty-text": "No Costs found",
                            "row-key": "service_id",
                            "default-expand-all": "",
                            stripe: "",
                            fit: "",
                            "show-summary": "",
                            "summary-method": _vm.getLotCostSummaries
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "payee_id",
                              label: "Vendor",
                              width: "250"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.payee.company) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "status",
                              label: "Status",
                              width: ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "total_amount",
                              label: "Amount",
                              width: "180"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(amount) {
                                  return [
                                    _vm._v(
                                      " $" +
                                        _vm._s(amount.row.total_amount) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-row", {
                staticStyle: { "padding-top": "10px", "padding-left": "30px" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }