var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lotInfo.superlot_id > 0
    ? _c(
        "div",
        [
          _c("invoice-hub", {
            attrs: {
              superLotId: _vm.lotInfo.superlot_id,
              lotInfo: _vm.lotInfo,
              filterParams: _vm.inv_list_params,
              cols_show: _vm.cols_show,
              forceLoadData: _vm.forceLoadData
            },
            on: {
              "update:forceLoadData": function($event) {
                _vm.forceLoadData = $event
              },
              "update:force-load-data": function($event) {
                _vm.forceLoadData = $event
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }