import request from '@/utils/request';
export function fileUploader(fileobj) {
  console.log('-----fileUploader----');
  console.log(fileobj);
  console.log('--file type---', fileobj.file.type);
  console.log('--file content---', fileobj.file);
  var param = new FormData();
  param.append('file', fileobj.file);
  param.append('size', fileobj.file.size);
  param.append('size', fileobj.data);
  console.log('--param contentswwww---', param.get('file')); // let transformData = {'data':param}

  return request({
    method: 'post',
    // url: '/files/upload',
    url: 'https://test-file-upload.besharp.workers.dev/',
    // headers: {'Content-Type':'multipart/form-data','transformRequest':{'data':param} },
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    // 将content-type 设置成此值，laravel才可以接收到文件
    data: param
  });
}
export { fileUploader as default };