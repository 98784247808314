var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { size: _vm.size, placeholder: "" },
      on: { change: _vm.handleUpdateStatus },
      model: {
        value: _vm.status_list,
        callback: function($$v) {
          _vm.status_list = $$v
        },
        expression: "status_list"
      }
    },
    _vm._l(_vm.stage_status_list, function(stage) {
      return _c("el-option", {
        key: stage,
        attrs: { label: stage, value: stage }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }