import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/core-js/modules/es.array.iterator.js";
import "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/core-js/modules/es.promise.js";
import "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/core-js/modules/es.object.assign.js";
import "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import i18n from './lang'; // internationalization

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';
import '@/icons'; // icon

import '@/permission'; // permission control

import generateTitle from './utils/i18n';
import { showSystemMsg } from '@/utils/common';
Vue.prototype.$showSystemMsg = showSystemMsg;
import bus from './utils/bus'; // import TableContact from './api/local_db/TableContact'
// import TableLocationCode from './api/local_db/TableLocationCode'

import * as filters from './filters'; // global filters

window._ = require('lodash'); // 版本

Vue.prototype.$version = process.env.VUE_APP_VERSION; // 构建时间

Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME; // Vue.prototype.$buildTime ='7/9/2021 01:13'

Vue.prototype.$buildTimeStamp = process.env.VUE_APP_BUILD_TIMESTAMP;
Vue.prototype.$bus = bus;
import StageStatusSelect from './components/StageStatusSelect';
Vue.component('stage-status-select', StageStatusSelect);
import StageStatusChanger from './components/StageStatusChanger';
Vue.component('stage-status-changer', StageStatusChanger);
import StageStatusTag from './components/StageStatusTag';
Vue.component('stage-status-tag', StageStatusTag);
import yipTableFilter from './components/yipTableFilter';
Vue.component('yip-table-filter', yipTableFilter); // console.warn('log in main.js')
// console.log(' ----- syncContacts in main.js ----')
// Vue.prototype.$local_contacts = TableContact
// Vue.prototype.$local_location_codes = TableLocationCode
// TableContact.syncContacts()
// TableLocationCode.syncLocationCodes()

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.use(ElementUI, {}); // Vue.use(Element, {
//   // size: Cookies.get('size') || 'medium', // set element-ui default size
//   i18n: (key, value) => i18n.t(key, value)
// })
// register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
});