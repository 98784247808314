import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/opt/dockerSpace/www/cif_project/cif_op_system/cif_op_system_frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { asyncRoutes, constantRoutes } from '@/router';
/**
 * Check if it matches the current user right by meta.role
 * @param {String[]} roles
 * @param {String[]} permissions
 * @param route
 */

function canAccess(roles, permissions, route) {
  if (route.meta) {
    var hasRole = true;
    var hasPermission = true;

    if (route.meta.roles || route.meta.permissions) {
      // If it has meta.roles or meta.permissions, accessible = hasRole || permission
      hasRole = false;
      hasPermission = false;

      if (route.meta.roles) {
        hasRole = roles.some(function (role) {
          return route.meta.roles.includes(role);
        });
      }

      if (route.meta.permissions) {
        hasPermission = permissions.some(function (permission) {
          return route.meta.permissions.includes(permission);
        });
      }
    }

    return hasRole || hasPermission;
  } // If no meta.roles/meta.permissions inputted - the route should be accessible


  return true;
}
/**
 * Find all routes of this role
 * @param routes asyncRoutes
 * @param roles
 */


function filterAsyncRoutes(routes, roles, permissions) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (canAccess(roles, permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles, permissions);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, _ref2) {
    var commit = _ref.commit;
    var roles = _ref2.roles,
        permissions = _ref2.permissions;
    return new Promise(function (resolve) {
      var accessedRoutes;

      if (roles.includes('Admin', 'General Manager')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles, permissions);
      }

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};