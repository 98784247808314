var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frame-page" }, [
    _c(
      "div",
      { staticClass: "frame-header" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("yip-table-filter", {
                  attrs: { filterConfig: _vm.tableFilterConfig },
                  on: {
                    "update:filterConfig": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    "update:filter-config": function($event) {
                      _vm.tableFilterConfig = $event
                    },
                    updateTableFilterParams: _vm.getTableFilterParams,
                    submitForm: _vm.getHouseList
                  }
                }),
                _c(
                  "span",
                  { staticClass: "frame-title" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          disabled: _vm.lotInfo.closed_stage != "ACTIVE",
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "medium",
                          plain: ""
                        },
                        on: { click: _vm.handleNewHouse }
                      },
                      [_vm._v("New House")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list_body" },
      [
        _c(
          "el-scrollbar",
          { staticClass: "frame-body-scrollbar" },
          [
            _c(
              "el-card",
              { staticClass: "box-card user-bio" },
              [
                _c(
                  "div",
                  { staticClass: "user-activity" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.houseList,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                          "empty-text": "Empty..."
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { align: "", label: "ID", width: "80" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(" " + _vm._s(slotProps.row.id) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "100px", align: "", label: "HAWB#" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.house_no) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "60px", align: "", label: "IW#" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  slotProps.row.pricing_id > 0
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(slotProps.row.pricing_id) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "130px",
                            align: "",
                            label: "Shipper"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(slotProps.row.shipper_name) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "130px", align: "", label: "CNEE" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.cnee_name) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { width: "130px", align: "", label: "Agent" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.agent_name) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "130px",
                            align: "",
                            label: "Invoice Amount"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.invoice_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "110px",
                            align: "",
                            label: "Cost Amount"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " $" +
                                      _vm._s(slotProps.row.cost_amount) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100px",
                            align: "",
                            label: "Created By"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(slotProps.row.creator) + " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "140px",
                            align: "",
                            label: "Create Date"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          slotProps.row.created_at
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { align: "", label: "Actions" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "6px" },
                                      attrs: {
                                        plain: "",
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditHouse(
                                            slotProps.$index,
                                            slotProps.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Edit ")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openPDFURL(
                                            _vm.department_config.prefix,
                                            "house",
                                            slotProps.row.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "success",
                                            size: "medium",
                                            plain: ""
                                          }
                                        },
                                        [_vm._v("Airway Bill")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "frame-footer" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-pagination",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.total > 0,
                                        expression: "total > 0"
                                      }
                                    ],
                                    attrs: {
                                      background: "",
                                      layout: "slot,sizes, prev, pager, next",
                                      total: _vm.total,
                                      "page-sizes": [10, 15, 20, 30, 40, 50],
                                      "current-page":
                                        _vm.listRequestParams.page,
                                      "page-size": _vm.listRequestParams.limit
                                    },
                                    on: {
                                      "size-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:currentPage": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "update:current-page": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "page",
                                          $event
                                        )
                                      },
                                      "current-change": function($event) {
                                        return _vm.getHouseList()
                                      },
                                      "update:pageSize": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      },
                                      "update:page-size": function($event) {
                                        return _vm.$set(
                                          _vm.listRequestParams,
                                          "limit",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          " Total Records: " + _vm._s(_vm.total)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      size: "1120px",
                      "close-on-press-escape": false,
                      wrapperClosable: false,
                      visible: _vm.drawer,
                      "with-header": false,
                      "destroy-on-close": true
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.drawer = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "14px 15px",
                          "border-bottom": "1px solid #dfe6ec"
                        }
                      },
                      [
                        _c("el-page-header", {
                          attrs: {
                            title: "Cancel",
                            content: _vm.houseDrawerTitle
                          },
                          on: { back: _vm.handleCloseDrawer }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tabs",
                          {
                            staticClass: "yip-tabs",
                            attrs: { type: "card" },
                            model: {
                              value: _vm.HouseEditActiveTab,
                              callback: function($$v) {
                                _vm.HouseEditActiveTab = $$v
                              },
                              expression: "HouseEditActiveTab"
                            }
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "House Info",
                                  name: "house_info"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.current_house_loading,
                                        expression: "current_house_loading"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 27 } },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "ae-house-form",
                                            attrs: {
                                              model: _vm.currentHouseInfo,
                                              rules: _vm.ae_house_rules,
                                              size: "medium",
                                              "label-width": "150px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "MAWB#:",
                                                              prop: "master_no"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                disabled: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.lotInfo
                                                                    .master_no_display,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.lotInfo,
                                                                    "master_no_display",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "lotInfo.master_no_display"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "HAWB#:",
                                                              prop: "house_no"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "150px"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "HAWB#:",
                                                                clearable: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .house_no,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "house_no",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "currentHouseInfo.house_no"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 7 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "1st Leg MAWB#:",
                                                              prop:
                                                                "first_leg_mawb_no"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "1st Leg MAWB#:"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .first_leg_mawb_no,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "first_leg_mawb_no",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "currentHouseInfo.first_leg_mawb_no"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  padding: "0px 10px 10px 80px"
                                                },
                                                attrs: { span: 23 }
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      border: "",
                                                      data:
                                                        _vm.currentHouseInfo
                                                          .flight_list
                                                    }
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "",
                                                        label: "Flight #",
                                                        width: "70"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    slotProps
                                                                      .row.id
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "airport_departure",
                                                        width: "175",
                                                        align: "",
                                                        label: "Departure"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Select airport",
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      slotProps
                                                                        .row
                                                                        .id ==
                                                                      "1st"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      slotProps
                                                                        .row
                                                                        .airport_departure,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        slotProps.row,
                                                                        "airport_departure",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "slotProps.row.airport_departure"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.airport_code,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        attrs: {
                                                                          label:
                                                                            item.code,
                                                                          value:
                                                                            item.code
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.code
                                                                              ) +
                                                                                " - " +
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "airport_destination",
                                                        label: "Destination",
                                                        width: "175"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Select airport",
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      "",
                                                                    disabled:
                                                                      slotProps
                                                                        .row
                                                                        .id ==
                                                                      "1st"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      slotProps
                                                                        .row
                                                                        .airport_destination,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        slotProps.row,
                                                                        "airport_destination",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "slotProps.row.airport_destination"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.airport_code,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        attrs: {
                                                                          label:
                                                                            item.code,
                                                                          value:
                                                                            item.code
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.code
                                                                              ) +
                                                                                " - " +
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "departure_date",
                                                        label: "Departure Date",
                                                        width: "170"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    format:
                                                                      "MM/dd/yyyy",
                                                                    "value-format":
                                                                      "MM/dd/yyyy",
                                                                    placeholder:
                                                                      "Departure Date:",
                                                                    clearable:
                                                                      "",
                                                                    disabled:
                                                                      slotProps
                                                                        .row
                                                                        .id ==
                                                                      "1st"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      slotProps
                                                                        .row
                                                                        .departure_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        slotProps.row,
                                                                        "departure_date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "slotProps.row.departure_date"
                                                                  }
                                                                }
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "carrier_id",
                                                        label: "Carrier",
                                                        width: "190"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Select Airline",
                                                                    disabled:
                                                                      slotProps
                                                                        .row
                                                                        .id ==
                                                                      "1st",
                                                                    clearable:
                                                                      "",
                                                                    filterable:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeCarrierInTable(
                                                                        slotProps.$index,
                                                                        slotProps.row
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      slotProps
                                                                        .row
                                                                        .carrier_id,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        slotProps.row,
                                                                        "carrier_id",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "slotProps.row.carrier_id"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.contactSimpleCarrier,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.id,
                                                                        attrs: {
                                                                          label:
                                                                            item.company,
                                                                          value:
                                                                            item.id
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "flight",
                                                        label: "Flight"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "input_auto",
                                                                attrs: {
                                                                  disabled: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    slotProps
                                                                      .row
                                                                      .airline_iata_code,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      slotProps.row,
                                                                      "airline_iata_code",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "slotProps.row.airline_iata_code"
                                                                }
                                                              }),
                                                              _vm._v(" - "),
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width: "100px"
                                                                },
                                                                attrs: {
                                                                  placeholder:
                                                                    "Flight",
                                                                  clearable: "",
                                                                  disabled:
                                                                    slotProps
                                                                      .row.id ==
                                                                    "1st"
                                                                },
                                                                model: {
                                                                  value:
                                                                    slotProps
                                                                      .row
                                                                      .flight,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      slotProps.row,
                                                                      "flight",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "slotProps.row.flight"
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "Shipper:",
                                                              prop: "shipper_id"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "yip-contact-remote",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    "Shipper",
                                                                  contact_type:
                                                                    "shipper",
                                                                  input_width:
                                                                    "190",
                                                                  show_detail_icon:
                                                                    "true"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .shipper_id,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "shipper_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.shipper_id"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "CNEE:",
                                                              prop: "cnee_id"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "yip-contact-remote",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    "CNEE",
                                                                  contact_type:
                                                                    "cnee",
                                                                  input_width:
                                                                    "190",
                                                                  show_detail_icon:
                                                                    "true"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .cnee_id,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "cnee_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.cnee_id"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "Agent",
                                                              prop: "agent_id"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "yip-contact-remote",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    "Agent",
                                                                  contact_type:
                                                                    "agent",
                                                                  input_width:
                                                                    "170",
                                                                  show_detail_icon:
                                                                    "true"
                                                                },
                                                                on: {
                                                                  applyContact:
                                                                    _vm.handleChangeAgent
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .agent_id,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "agent_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.agent_id"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Agent IATA Code:",
                                                              prop:
                                                                "agent_iata_code"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                clearable: "",
                                                                disabled: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .agent_iata_code,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "agent_iata_code",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "currentHouseInfo.agent_iata_code"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Accounting Info:",
                                                              prop:
                                                                "accounting_info"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio-group",
                                                              {
                                                                attrs: {
                                                                  size: "medium"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .accounting_info,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "accounting_info",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.accounting_info"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.accounting_infoOptions,
                                                                function(
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-radio",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.value,
                                                                        disabled:
                                                                          item.disabled
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.label
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Currency:",
                                                              prop: "currency"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Currency:"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .currency,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "currency",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "currentHouseInfo.currency"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 22 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Accounting Info:",
                                                              prop:
                                                                "accounting_info_details"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "70%"
                                                              },
                                                              attrs: {
                                                                type:
                                                                  "textarea",
                                                                autosize: {
                                                                  minRows: 3,
                                                                  maxRows: 3
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .accounting_info_details,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "accounting_info_details",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                    currentHouseInfo.accounting_info_details\n                                  "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "120px",
                                                              label:
                                                                "Insurance:",
                                                              prop: "insurance"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Insurance"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .insurance,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "insurance",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "currentHouseInfo.insurance"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "180px",
                                                              label:
                                                                "ChgsCode-WT/VAL",
                                                              prop:
                                                                "charge_code_wt_val"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .charge_code_wt_val,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "charge_code_wt_val",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.charge_code_wt_val"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.charge_codesOptions,
                                                                function(
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key:
                                                                        item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value
                                                                      }
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 8 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "180px",
                                                              label:
                                                                "ChgsCode-Other",
                                                              prop:
                                                                "charge_code_other"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .charge_code_other,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "charge_code_other",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.charge_code_other"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.charge_codesOptions,
                                                                function(
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key:
                                                                        item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value
                                                                      }
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 10 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "280px",
                                                              label:
                                                                "Declared Value for Carriage: ",
                                                              prop:
                                                                "declared_value_for_carriage"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Declared Value for Carriage"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .declared_value_for_carriage,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "declared_value_for_carriage",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                    currentHouseInfo.declared_value_for_carriage\n                                  "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 10 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "220px",
                                                              label:
                                                                "Declared Value for Customs: ",
                                                              prop:
                                                                "declared_value_for_customs"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Declared Value for Customs"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .declared_value_for_customs,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "declared_value_for_customs",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                    currentHouseInfo.declared_value_for_customs\n                                  "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 23 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Handling Information:",
                                                      prop:
                                                        "handling_information"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      style: { width: "70%" },
                                                      attrs: {
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 3,
                                                          maxRows: 3
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .handling_information,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "handling_information",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.handling_information"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  padding: "0px 10px 10px 80px"
                                                },
                                                attrs: { span: 23 }
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      border: "",
                                                      data: [{ pcs: "" }]
                                                    }
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "packing_pcs",
                                                        label: "PCS",
                                                        width: "80"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .packing_pcs,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "packing_pcs",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.packing_pcs"
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "packing_weight_unit",
                                                        label: "Weight Unit",
                                                        width: "120"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  style: {
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    clearable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentHouseInfo
                                                                        .packing_weight_unit,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentHouseInfo,
                                                                        "packing_weight_unit",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentHouseInfo.packing_weight_unit"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.packing_weight_unitOptions,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          label:
                                                                            item.label,
                                                                          value:
                                                                            item.value,
                                                                          disabled:
                                                                            item.disabled
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "packing_gross_weight",
                                                        label: "Gross Weight",
                                                        width: "100"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .packing_gross_weight,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "packing_gross_weight",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "\n                                    currentHouseInfo.packing_gross_weight\n                                  "
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "packing_chargeable_weight",
                                                        label:
                                                          "Chargeable Weight",
                                                        width: "100"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateChargeTotal()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .packing_chargeable_weight,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "packing_chargeable_weight",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "\n                                    currentHouseInfo.packing_chargeable_weight\n                                  "
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "packing_rate",
                                                        label: "Rate",
                                                        width: "90"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "header",
                                                          fn: function(scope) {
                                                            return [
                                                              _vm._v(" Rate "),
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "dark",
                                                                    content:
                                                                      "'AS AGREED' will be showed on documents when Rate set to 0",
                                                                    placement:
                                                                      "top"
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    staticStyle: {
                                                                      color:
                                                                        "darkgoldenrod"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateChargeTotal()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .packing_rate,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "packing_rate",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "currentHouseInfo.packing_rate"
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "packing_total",
                                                        label: "Total",
                                                        width: "100"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.packing_total
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop:
                                                          "packing_nature_of_goods",
                                                        label: "Nature of goods"
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  type:
                                                                    "textarea"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .currentHouseInfo
                                                                      .packing_nature_of_goods,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.currentHouseInfo,
                                                                      "packing_nature_of_goods",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "\n                                    currentHouseInfo.packing_nature_of_goods\n                                  "
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 23 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Remarks:",
                                                      prop: "packing_remarks"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      style: { width: "70%" },
                                                      attrs: {
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 3,
                                                          maxRows: 3
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .packing_remarks,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "packing_remarks",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.packing_remarks"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 23 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "Other Charges:",
                                                      prop: "other_charges"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      style: { width: "100%" },
                                                      attrs: {
                                                        type: "textarea",
                                                        autosize: {
                                                          minRows: 3,
                                                          maxRows: 3
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentHouseInfo
                                                            .other_charges,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.currentHouseInfo,
                                                            "other_charges",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "currentHouseInfo.other_charges"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "250px",
                                                              label:
                                                                "Total Other Charge Due Carrier:",
                                                              prop:
                                                                "other_charge_total_due_carrier"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .other_charge_total_due_carrier,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "other_charge_total_due_carrier",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                    currentHouseInfo.other_charge_total_due_carrier\n                                  "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "250px",
                                                              label:
                                                                "Total Other Charge Due Agent:",
                                                              prop:
                                                                "other_charge_total_due_agent"
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              style: {
                                                                width: "100%"
                                                              },
                                                              attrs: {
                                                                placeholder: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .currentHouseInfo
                                                                    .other_charge_total_due_agent,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.currentHouseInfo,
                                                                    "other_charge_total_due_agent",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                    currentHouseInfo.other_charge_total_due_agent\n                                  "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 23 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      float: "right"
                                                    },
                                                    attrs: { size: "large" }
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        on: {
                                                          click: _vm.resetForm
                                                        }
                                                      },
                                                      [_vm._v("Reset")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.lotInfo
                                                              .closed_stage !=
                                                            "ACTIVE",
                                                          type: "primary"
                                                        },
                                                        on: {
                                                          click: _vm.submitForm
                                                        }
                                                      },
                                                      [_vm._v("Submit")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm.currentHouseId > 0
                              ? [
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Invoices",
                                            name: "house_invoice"
                                          }
                                        },
                                        [
                                          _c("invoice-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.invoicehub_request_params,
                                              cols_show:
                                                _vm.invoicehub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.invoiceShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentHouseInfo.superhouse_id > 0
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "Costs",
                                            name: "house_cost"
                                          }
                                        },
                                        [
                                          _c("cost-hub", {
                                            attrs: {
                                              showNewButton: true,
                                              superLotId:
                                                _vm.lotInfo.superlot_id,
                                              superHouseId:
                                                _vm.currentHouseInfo
                                                  .superhouse_id,
                                              lotInfo: _vm.lotInfo,
                                              filterParams:
                                                _vm.costhub_request_params,
                                              cols_show: _vm.costhub_cols_show,
                                              forceLoadData: _vm.forceLoadData,
                                              showFilter: _vm.costShowFilter
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }