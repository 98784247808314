var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 40 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("messages")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-message" },
                [
                  _c("i", {
                    staticClass: "el-icon-document-copy",
                    staticStyle: { "font-size": "48px" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" LOTs ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 370, duration: 5 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("shoppings")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-shopping" },
                [
                  _c("i", {
                    staticClass: "el-icon-truck",
                    staticStyle: { "font-size": "48px" },
                    attrs: { type: "primary" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" Shipments ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 25, duration: 5 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("newVisitis")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-people" },
                [
                  _c("i", {
                    staticClass: "el-icon-sugar",
                    staticStyle: { "font-size": "48px" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" Others ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 235, duration: 3 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("purchases")
                }
              }
            },
            [
              _c("div", { staticClass: "card-panel-icon-wrapper icon-money" }, [
                _c("i", {
                  staticClass: "el-icon-money",
                  staticStyle: { "font-size": "48px" }
                })
              ]),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card-panel-text",
                      staticStyle: { "text-align": "right" }
                    },
                    [_vm._v(" Pending ")]
                  ),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 68, duration: 3 }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }