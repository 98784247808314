//
//
//
//
//
//
//
//
//
//
import ContactHub from "./ContactComponents/ContactHub"; // import Resource from '@/api/resource';
// import ObjectList from './components/ObjectList';
// import ObjectDetails from './components/ObjectDetails';
// import { mapGetters } from 'vuex'
// const userResource = new Resource('users');
// const articleResource = new Resource('articles');
// const lotResource = new Resource('tlm_lot');

export default {
  // name: 'EditObject',
  components: {
    ContactHub: ContactHub
  },
  data: function data() {
    return {
      // user: {},
      // ViewerLeftWidth:'350px',
      listMode: "full",
      forceLoadData: true,
      contact_list_params: {
        department: "TLM"
      },
      cols_show: ["id", "bill_no", "for_house_no", "expense_date", "due_date", "creator"]
    };
  },
  computed: {
    // 计算属性的 getter
    ViewerLeftWidth: function ViewerLeftWidth() {
      // `this` 指向 vm 实例
      if (this.listMode == "hide") {
        return "0px";
      } else if (this.listMode == "left") {
        return "350px";
      } // return this.message.split('').reverse().join('')

    }
  },
  watch: {
    "$route.query": function $routeQuery(query, oldQuery) {// ...
      //  console.log('$route.query --- new query:',query)
      //  console.log('$route.query --- old query:',oldQuery)
    },
    deep: true
  },
  beforeMount: function beforeMount() {
    var url_query = this.$route.query;

    if (url_query.listMode !== undefined && url_query.listMode != "full") {
      // 如果用户直接访问URL，则准确加载试图，例如某用户copy url (http://test.com/object/index?id=159&listMode=left )发给其他人，其他人在email里面直接点击此URL， 则能看到 id为159 的object 详情页面
      // console.log("**loading object details**");
      this.listMode = url_query.listMode; // this.viewLotId = url_query.id
    } // console.log('【---------】----url in created----',url)
    // console.log("【---------】----url_query in beforeMount----", url_query);

  },
  created: function created() {// console.log("---parent page created----");
  },
  mounted: function mounted() {
    var url_query = this.$route.query;
    var url = window.location.href;
  },
  methods: {// async syncDepartmentInvoiceServices() {
    //   await this.$store.dispatch('settings/getDepartmentInvoiceServices','tlm')
    // },
    // async syncDepartmentCostServices() {
    //   await this.$store.dispatch('settings/getDepartmentCostServices','tlm')
    // }
  }
};