var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { float: "right", "margin-bottom": "10px" }
        },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "5px" },
            attrs: { placeholder: _vm.$t("table.keyword") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.query.keyword,
              callback: function($$v) {
                _vm.$set(_vm.query, "keyword", $$v)
              },
              expression: "query.keyword"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: _vm.$t("table.role"), clearable: "" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.query.role,
                callback: function($$v) {
                  _vm.$set(_vm.query, "role", $$v)
                },
                expression: "query.role"
              }
            },
            _vm._l(_vm.roles, function(item) {
              return _c("el-option", {
                key: item,
                attrs: { label: _vm._f("uppercaseFirst")(item), value: item }
              })
            }),
            1
          ),
          _c("el-button", {
            directives: [{ name: "waves", rawName: "v-waves" }],
            staticClass: "filter-item",
            staticStyle: { "margin-left": "5px" },
            attrs: { type: "primary", plain: "", icon: "el-icon-refresh" },
            on: { click: _vm.handleFilter }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", plain: "", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(" Add User ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "empty-text": "Empty..."
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "", label: "ID", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.index))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Name", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Email" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Role", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.roles.join(", ")))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "", label: "Actions", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/administrator/users/edit/" + scope.row.id
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "padding-right": "15px" },
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-edit"
                            }
                          },
                          [_vm._v(" Edit ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "warning",
                          size: "small",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleEditPermissions(scope.row.id)
                          }
                        }
                      },
                      [_vm._v(" Permissions ")]
                    ),
                    scope.row.roles.includes("visitor")
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["manage user"],
                                expression: "['manage user']"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              size: "small",
                              icon: "el-icon-delete"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.row.name
                                )
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPermissionVisible,
            title: "Edit Permissions - " + _vm.currentUser.name
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPermissionVisible = $event
            }
          }
        },
        [
          _vm.currentUser.name
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogPermissionLoading,
                      expression: "dialogPermissionLoading"
                    }
                  ],
                  staticClass: "form-container"
                },
                [
                  _c("div", { staticClass: "permissions-container" }, [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.currentUser,
                              "label-width": "80px",
                              "label-position": "top"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Menus" } },
                              [
                                _c("el-tree", {
                                  ref: "menuPermissions",
                                  staticClass: "permission-tree",
                                  attrs: {
                                    data: _vm.normalizedMenuPermissions,
                                    "default-checked-keys": _vm.permissionKeys(
                                      _vm.userMenuPermissions
                                    ),
                                    props: _vm.permissionProps,
                                    "show-checkbox": "",
                                    "node-key": "id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.currentUser,
                              "label-width": "80px",
                              "label-position": "top"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Permissions" } },
                              [
                                _c("el-tree", {
                                  ref: "otherPermissions",
                                  staticClass: "permission-tree",
                                  attrs: {
                                    data: _vm.normalizedOtherPermissions,
                                    "default-checked-keys": _vm.permissionKeys(
                                      _vm.userOtherPermissions
                                    ),
                                    props: _vm.permissionProps,
                                    "show-checkbox": "",
                                    "node-key": "id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "clear-left" })
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              _vm.dialogPermissionVisible = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("permission.cancel")) + " "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmPermission }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("permission.confirm")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "Create new user", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.userCreating,
                  expression: "userCreating"
                }
              ],
              staticClass: "form-container"
            },
            [
              _c(
                "el-form",
                {
                  ref: "userForm",
                  staticStyle: { "max-width": "500px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.newUser,
                    "label-position": "left",
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.role"), prop: "role" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "Please select role" },
                          model: {
                            value: _vm.newUser.role,
                            callback: function($$v) {
                              _vm.$set(_vm.newUser, "role", $$v)
                            },
                            expression: "newUser.role"
                          }
                        },
                        _vm._l(_vm.nonAdminRoles, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: {
                              label: _vm._f("uppercaseFirst")(item),
                              value: item
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.name"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.newUser.name,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "name", $$v)
                          },
                          expression: "newUser.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("user.email"), prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.newUser.email,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "email", $$v)
                          },
                          expression: "newUser.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("user.password"),
                        prop: "password"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { "show-password": "" },
                        model: {
                          value: _vm.newUser.password,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "password", $$v)
                          },
                          expression: "newUser.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("user.confirmPassword"),
                        prop: "confirmPassword"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { "show-password": "" },
                        model: {
                          value: _vm.newUser.confirmPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.newUser, "confirmPassword", $$v)
                          },
                          expression: "newUser.confirmPassword"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.createUser()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }