//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CostHub from "@/components/yipComponent/CostHub"; // import Pagination from '@/components/Pagination'; // Secondary package based on
// import Resource from '@/api/resource';
// import fileUploader from '@/api/fileupload';
// const articleResource = new Resource('articles');

export default {
  components: {
    CostHub: CostHub
  },
  props: {
    // user: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: '',
    //       email: '',
    //       avatar: '',
    //       roles: [],
    //     };
    //   },
    // },
    lotInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    viewObjectId: {
      type: String,
      default: 0
    },
    editObjectId: {
      type: String,
      default: "0"
    },
    forceLoadData: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      houseId: 0,
      inv_list_params: {
        superlot_id: 0,
        superhouse_id: 0,
        department: "TLM"
      },
      cols_show: ["id", "bill_no", "for_house_no", "expense_date", "due_date", "payment_reference_no", "creator"] // forceLoadData:false,
      // inv_list_params:{}

    };
  },
  watch: {
    "lotInfo.superlot_id": function lotInfoSuperlot_id(val) {
      // console.log('-----lotInfo.superlot_id----')
      // console.log(val)
      // this.inv_list_params.superlot_id=val;
      this.$set(this.inv_list_params, "superlot_id", val);
    },
    forceLoadData: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(newV, oldV) {
        // console.log('***---watch forceLoadData changed in TabCosts---',newV,oldV)
        if (newV == true) {// console.log('---getting Costs list...---')
          // this.fetchTabData()
        }
      }
    }
  },
  mounted: function mounted() {// console.log('**** this.forceLoadData in TabCosts.vue ****')
    // console.log(this.lotInfo)
    // console.log(this.forceLoadData)
  },
  methods: {
    backToList: function backToList() {
      // console.log('----this.viewObjectId---',this.viewObjectId)
      this.$emit("update:editObjectId", 0);
      this.$emit("update:viewObjectId", this.viewObjectId);
    }
  }
};