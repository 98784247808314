import "core-js/modules/es.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  nickname: function nickname(state) {
    return state.user.nickname;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  invoiceServices: function invoiceServices(state) {
    return state.settings.invoiceServices;
  },
  invoiceRefreshStamp: function invoiceRefreshStamp(state) {
    return state.settings.invoiceRefreshStamp;
  },
  costServices: function costServices(state) {
    return state.settings.costServices;
  },
  costRefreshStamp: function costRefreshStamp(state) {
    return state.settings.costRefreshStamp;
  },
  // contactCounts: state => state.settings.contactCounts,
  // contactSimpleAll: state => state.settings.contactSimpleAll,
  // contactSimpleCustomer: state => state.settings.contactSimpleCustomer,
  contactSimpleVendor: function contactSimpleVendor(state) {
    return state.settings.contactSimpleVendor;
  },
  // contactSimpleBillable: state => state.settings.contactSimpleBillable,
  // contactSimpleShipper: state => state.settings.contactSimpleShipper,
  // contactSimpleCnee: state => state.settings.contactSimpleCnee,
  // contactSimpleAgent: state => state.settings.contactSimpleAgent,
  contactSimpleCarrier: function contactSimpleCarrier(state) {
    return state.settings.contactSimpleCarrier;
  },
  // contactSimpleTerminal: state => state.settings.contactSimpleTerminal,
  userFilterableAll: function userFilterableAll(state) {
    return state.settings.userFilterableAll;
  },
  TLMLotListRefreshData: function TLMLotListRefreshData(state) {
    return state.settings.TLMLotListRefreshData;
  },
  AELotListRefreshData: function AELotListRefreshData(state) {
    return state.settings.AELotListRefreshData;
  },
  AILotListRefreshData: function AILotListRefreshData(state) {
    return state.settings.AILotListRefreshData;
  },
  SELotListRefreshData: function SELotListRefreshData(state) {
    return state.settings.SELotListRefreshData;
  },
  SILotListRefreshData: function SILotListRefreshData(state) {
    return state.settings.SILotListRefreshData;
  },
  BKGLotListRefreshData: function BKGLotListRefreshData(state) {
    return state.settings.BKGLotListRefreshData;
  },
  MISLotListRefreshData: function MISLotListRefreshData(state) {
    return state.settings.MISLotListRefreshData;
  },
  DELLotListRefreshData: function DELLotListRefreshData(state) {
    return state.settings.DELLotListRefreshData;
  },
  StageStatusList: function StageStatusList(state) {
    return ['ACTIVE', 'OP_CLOSED', 'ACCOUNTING_CLOSED'];
  }
};
export default getters;