var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          style: {
            width: _vm.input_width + "px",
            "padding-left": _vm.padding_left + "px"
          },
          attrs: {
            size: "medium",
            filterable: "",
            clearable: _vm.is_clearable,
            remote: "",
            "reserve-keyword": "",
            disabled: _vm.disabled,
            placeholder: "Search " + _vm.placeholder,
            "remote-method": function(query) {
              _vm.remoteSearchContact(query, _vm.contact_type)
            },
            loading: _vm.searchContactLoading,
            "loading-text": "Searching...",
            "no-data-text": "Result not found"
          },
          on: {
            change: function($event) {
              return _vm.changeValue($event)
            }
          },
          model: {
            value: _vm.val_tmp,
            callback: function($$v) {
              _vm.val_tmp = $$v
            },
            expression: "val_tmp"
          }
        },
        _vm._l(_vm.remoteContactList, function(item) {
          return _c(
            "el-option",
            { key: item.id, attrs: { label: item.short_name, value: item.id } },
            [_c("span", [_vm._v(_vm._s(item.short_name))])]
          )
        }),
        1
      ),
      _vm.show_detail_icon && _vm.val_tmp > 0 && _vm.current_contact_obj
        ? _c(
            "el-popover",
            {
              attrs: { placement: "right", width: "500", trigger: "click" },
              model: {
                value: _vm.show_contact_card,
                callback: function($$v) {
                  _vm.show_contact_card = $$v
                },
                expression: "show_contact_card"
              }
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { background: "aliceblue" },
                  attrs: { shadow: "hover" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 18 } }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "16px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.current_contact_obj.company))]
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-link",
                                {
                                  staticStyle: { float: "right" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleShowContact()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-office-building",
                                    staticStyle: {
                                      color: "#409eff",
                                      "font-size": "30px",
                                      float: "right",
                                      padding: "3px 0"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: {
                            title: "",
                            column: 1,
                            size: "size",
                            border: ""
                          }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { labelStyle: "width: 78px;" } },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" Address: ")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.current_contact_obj.billing_address
                                  ) +
                                  " "
                              )
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" Tel: ")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.current_contact_obj.phone) +
                                  " "
                              )
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { labelStyle: "width: 78px;" } },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" Role: ")
                              ]),
                              _vm.current_contact_obj.as_customer == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Customer")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_cnee == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("CNEE")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_vendor == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Vendor")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_agent == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Agent")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_carrier == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Carrier")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_shipper == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Shipper")
                                  ])
                                : _vm._e(),
                              _vm.current_contact_obj.as_terminal == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("Terminal")
                                  ])
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("i", {
                staticClass: "el-icon-notebook-1",
                staticStyle: {
                  color: "#409eff",
                  "padding-left": "5px",
                  cursor: "pointer"
                },
                attrs: { slot: "reference" },
                slot: "reference"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }